import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import SubHeader from "../../../../components/global-components/sub-header";
import { TextField } from "@mui/material";
import { useAuth } from "../../../../helpers/context";
import { toast } from "react-toastify";
import { saveLocation, validateProperty } from "../../../../api/propertyApi";
import { useNavigate } from "react-router-dom";
import ADConstraints from "../../../../components/ad-constraints";
import LoadingButton from "../../../../components/global-components/loading-btn";
import SubscriptionPopup from "../../../../components/user-components/subscription-popup";
import { canAddProperty } from "../../../../api/userApi";
import REGAPropertyInfo from "../../../../components/rega-property-info";
import GoogleMapsContainer from "../../../../components/section-components/registration/GoogleMapsContainer";
import { confirmAlert } from "react-confirm-alert";
import RoomIcon from "@mui/icons-material/Room";
import { getDemoSnakbar, preventString } from "../../../../helpers";
import { DEMO_VENDORS } from "../../../../constants";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const AddNew = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [ADNumber, setADNumber] = useState("");
  const [property, setProperty] = useState();
  const { token, UserType, vendor } = useAuth();
  const history = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingLocationButton, setLoadingLocationButton] = useState(false);
  const { user } = props;
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const { withoutNavbar } = props;
  const [errorMsg, setErrorMsg] = useState('');
  const { t, i18n } = useTranslation();
  const [ZoomLevel, setZoomLevel] = useState(14);
  const [Location, setLocation] = useState({
    id: null,
    userIdHashed: "",
    address: "",
    latitude: "24.74772941029266",
    longitude: "46.619290983645406",
    isLocationChanged: "0",
    language: i18n.language === 'ar' ? '0' : '1',
    neighborhood: "",
  });

  function Step1IsValid() {
    const _errors = {};

    if (!ADNumber) _errors.ADNumber = t("ThisFieldIsMandatory");
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleNextClick() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (property.propertyArr?.latitude === '24' && property.propertyArr?.longitude === '24') {
      setCurrentStep(3);
      setCompletedSteps([0, 1, 2]);
    } else {
      let path = "";
      if (withoutNavbar) {
        path =
          "/add-property-step3/" + property.propertyArr.propertyId + "/" + token;
      } else {
        path = "/add-property-step3/" + property.propertyArr.propertyId;
      }
      if (props.isAdmin)
        path = "/admin/property/add-step3/" + property.propertyArr.propertyId;
      history(path);
    }
  }

  const options = {
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h3>{t("ConfirmAddress")}</h3>
          <h5>{t("ThePropertyYouWouldLikeToAddIsLocatedIn")}</h5>
          <Alert severity='info' icon={<RoomIcon />}>
            {Location.neighborhood}
          </Alert>

          <div className='btns-wrapper'>
            <button
              className='primary-btn px-4'
              onClick={() => {
                setLoadingLocationButton(true);
                saveLocation({
                  'userId': token,
                  'propertyId': property?.propertyArr.propertyId,
                  'latitude': Location.latitude,
                  'longitude': Location.longitude,
                }).then(res => {
                  setLoadingLocationButton(false);
                  if (res.resCode === 0) {
                    let path = "";
                    if (withoutNavbar) {
                      path =
                        "/add-property-step3/" + property.propertyArr.propertyId + "/" + token;
                    } else {
                      path = "/add-property-step3/" + property.propertyArr.propertyId;
                    }
                    if (props.isAdmin)
                      path = "/admin/property/add-step3/" + property.propertyArr.propertyId;
                    history(path);
                  } else {
                    toast.error(res.resStr);
                  }
                });
                   
                onClose();
                setCompletedSteps([0, 1, 2, 3]);
              }}>
              {t("Continue")}
            </button>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
                setCompletedSteps([0, 1, 2]);
              }}>
              {t("ChangeAddress")}
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypressEscape: () => { },
    overlayClassName: "overlay-custom-class-name",
  };

  function handleSaveLocation() {
    if (!Location.address) toast.error(t("PleaseSpecifyTheAddressCorrectly"));
    if (ZoomLevel < 17) {
      toast.error(t("PleaseZoomInForPreciseLocation"));
    } else {
      confirmAlert(options);
    }
  }

  const checkProperty = () => {
    if (!Step1IsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      callAddPropertyStep1API();
    }
  }

  function callAddPropertyStep1API() {
    setLoadingButton(true);
    canAddProperty({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((result) => {
      if (result.resCode === 0) {
        if (result.response.result === '1') {
          validateProperty({
            language: i18n.language === 'ar' ? '0' : '1',
            userId: token,
            adLicenseNumber: ADNumber,
            source: "3",
          })
            .then((res) => {
              if (res.resCode !== 0) {
                if (res.resCode === 3) {
                  toast.warning(res.resStr);
                  history(`/edit-property/${res.response.id}`)
                } else {
                  setErrors({ ADNumber: res.resStr });
                }
              }
              else {
                setProperty(res.response);
                handlecontinueClick(2, [0, 1]);
              }
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              setLoadingButton(false);
            });
        } else {
          setOpenSubscriptionModal(true);
          setErrorMsg(result.resStr);
          setLoadingButton(false);
        }
      } else {
        toast.error(result.resStr);
        setLoadingButton(false);
      }
    }
    );
  }
  
  function handleZoomChange(NewZoom) {
    setZoomLevel(NewZoom);
  }

  function handleChange(NewLocation) {
    setLocation({ ...Location, NewLocation });
  }

  function handlecontinueClick(current, completed) {
    setCurrentStep(current);
    setCompletedSteps(completed);
  }

  function handleChangeAddress() { }

  return (
    <>
      <SubHeader
        withSteps
        steps={[t("RealEstateAdvertisingControls"), t("VerifyAD"), t("ReviewInformation"), t("LocateTheProperty"), t("AdditionalData")]}
        pageTitle={t("AddRealEstateAD")}
        OKElement={<></>}
        completedSteps={completedSteps}
        currentStep={currentStep}
      />

      <div className='add-new-property-area mg-bottom-100 mt-4 text-center'>
        {currentStep === 0 && <ADConstraints onOK={() => handlecontinueClick(1, [0])} />}
        {currentStep === 1 && (
          <div>
            <div
              className='custom-card ad-constraints'
              style={{ minHeight: "auto", height: "auto" }}>
              <div className='card-header'>
                <h4>{t("CheckTheRealEstateADLicenseNumber")}</h4>
                <h6>{t("EnterTheRealEstateADLicenseNumber")}</h6>
              </div>
              <div className='card-body justify-content-center  align-items-center'>
              
                <div className="justify-content-center d-flex flex-column align-items-center">
                  <TextField
                    label={user.accountType === "2" ? (user?.commercialRecordNumber?.startsWith('7') ? t("CompanyUnifiedNationalNumber") : t("CommercialRegistrationNo")) : t("IDNumber")}
                    sx={{ width: "300px", mt: "30px", mb: "15px", display: 'inline-flex' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    autoComplete='off'
                    value={user.accountType === "2" ? user.commercialRecordNumber : user.nationalid}
                  />
                  <TextField
                    label={t("RealEstateADLicenseNumber")}
                    sx={{ width: "300px", mt: "30px", mb: "15px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("EnterRealEstateADLicenseNumber")}
                    required={true}
                    autoComplete='off'
                    value={ADNumber}
                    onChange={(e) => {
                      setADNumber(preventString(e.target.value));
                      setErrors({});
                    }}
                  />
                  {errors.ADNumber && (
                    <Alert
                      severity='error'
                      style={{
                        width: "300px",
                        margin: "auto",
                        textAlign: "right",
                      }}>
                      &nbsp;&nbsp;{errors.ADNumber}
                    </Alert>
                  )}
                </div>
               

                {/* <Alert
                  severity='info'
                  className='mt-4 mx-auto'
                  style={{ width: "fit-content" }}>
                  يمكنك الحصول على رقم ترخيص إعلان عبر انشاء إعلان في منصة
                  <a
                    rel='noreferrer'
                    style={{ textDecoration: "underline", fontWeight: 500 }}
                    href='https://eservices.rega.gov.sa/'
                    target='_blank'>
                    {" "}
                    الخدمات الإلكترونية التابعة للهيئة العامة للعقار
                  </a>
                </Alert> */}
              </div>
              <div className='card-footer'>
                <SubscriptionPopup
                  setOpenSubscriptionModal={setOpenSubscriptionModal}
                  openSubscriptionModal={openSubscriptionModal}
                  desc={UserType === '5' ?
                    <>
                      {errorMsg}
                      <br />  {t("ContactYourAccountManagerToUpgradeYourSubscription")}
                    </> :
                    errorMsg
                  }
                  trigger={
                    <LoadingButton
                      classes='primary-btn px-5'
                      handleClick={checkProperty}
                      label={t("Next")}
                      loading={loadingButton}
                    />
                  }
                />
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && <REGAPropertyInfo onOK={handleNextClick} property={property} />}
        {currentStep === 3 && (
          <div className='custom-card'>
            <div className='card-header'>
              <h4>{t("LocateTheProperty")}</h4>
              <h6>{t("LocateThePropertyAccurately")}</h6>
            </div>
            <div className='card-body'>
              <Alert severity='warning' style={{ width: "fit-content" }}>
                &nbsp;&nbsp; {t("PropertyLocationModificationInTheFutureWillNotBePossible")}
              </Alert>

              <div
                style={{
                  height: "400px",
                  width: "100%",
                  position: "relative",
                  marginTop: "16px",
                }}
                className="map-full-height">
                <GoogleMapsContainer
                  lat={Location.latitude}
                  lng={Location.longitude}
                  onChange={handleChange}
                  onZoomChange={handleZoomChange}
                  user={Location}
                />
                <img
                  alt='pin'
                  src={publicUrl + "assets/img/icons/BluePin.svg"}
                  className='addPropPin'
                />
                <input
                  id='address'
                  name='address'
                  className='form-control py-3 map-input'
                  onChange={handleChangeAddress}
                  value={Location.address}
                />
              </div>
            </div>
            <div className='card-footer'>
              <LoadingButton
                classes='primary-btn px-5'
                handleClick={handleSaveLocation}
                label={t("Next")}
                loading={loadingLocationButton}
              />
             
            </div>
          </div>
        )}
        {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      </div>
    </>
  );
}

export default AddNew;
