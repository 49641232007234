import React, {  useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../helpers/context";
import { getUserProile } from "../../api/userApi";
import AppLayout from "../../components/layouts/app-layout";
import { toast } from "react-toastify";
import { ListArrow } from "../../constants/icons";
import { openDeleteModal } from "../../helpers";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteUsageInfo = () => {
  const { token, setToken, setUserType, setUserId, setAccountType } = useAuth();
  let { userId } = useParams();
  const { t, i18n } = useTranslation();

  const getUserData = (id) => {
    getUserProile({
      userId: id ? id : token,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        if (id) {
          setToken(id);
          setAccountType(_userInfo.response.userProfile?.accountType);
          setUserId(_userInfo.response.userProfile?.userId);
          setUserType(_userInfo.response.userProfile?.userType);
        }
      } else {
        toast.error(_userInfo.resStr);
      }
    });
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
      } else {
        getUserData();
      }
    }, // eslint-disable-next-line
    [userId]
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <AppLayout needAuth={!userId} pageTitle={t("DeleteUsageInformation")} withoutNav={userId}>
      <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
        <Box
          sx={{
            marginBottom: "40px",
            textAlign: "center !important",
            fontSize: "28px",
            fontWeight: 600,
            color: "#000",
          }}>
          {t("DeleteUsageInformation")}
        </Box>
        <div
          className={`row align-items-center justify-content-end`}
          style={{ marginTop: '-50px !important' }}
        >
          <div className="col-12 p-0 mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>{t("GeneralControls")}</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    {t("AllRealEstateADsPublishedByYouWillBeDeleted")}
                  </li>
                  <li><ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}} />
                    {t("AllRealEstateADsPreferencesMadeByYouWillBeDeleted")}
                  </li>
                  <li><ListArrow  style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}}/>
                    {t("AllSearchesMadeByYouWillBeDeleted")}
                  </li>
                </ul>
           
                <button
                  className="primary-btn delete-btn d-flex  justify-content-center mt-4 px-5 mr-2"
                  onClick={() => {
                 
                    openDeleteModal(t("DeleteUsageInformation"), t("AreYouSureYouWantToDeleteYourUsageInformation"), () => {
                      //call ep
                      // log out
                      // clear cookies
                      //redirect to home page
                    });
                  
                  }}>
                  {t("DeleteUsageInformation")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default DeleteUsageInfo;
