import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Brush } from "../../../../constants/icons";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const ColorPicker = (props) => {
  const [displayColorPicker, setdisplayColorPicker] = useState(false);
  const [Color, setColor] = useState(props.color || "#fff");

  function handleClick() {
    setdisplayColorPicker(true);
  }

  function handleClose() {
    setdisplayColorPicker(false);
  }

  function handleChange(color) {
    setColor(color.hex);
    props.onChange(color.hex);
  }


  return (
    <div className="color-wrapper" onClick={handleClick}>
      <div style={{
        width: "40px",
        height: "40px",
        borderRadius: "12px",
        background: `${Color}`,
        boxShadow: "0 0 1px 1px rgba(0,0,0,.3)",
      }} />
      <Brush />
      <h5 className="color-text">{props.name}</h5>
      {displayColorPicker ? (
        <ClickAwayListener onClickAway={handleClose}>
          <div style={{ position: "absolute", zIndex: "2" }}>
            <SketchPicker color={Color} onChange={(e) => handleChange(e)} />
          </div>
        </ClickAwayListener>) : null}
    </div>
  );
};

export default ColorPicker;
