import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  InputAdornment,
  TextField,
  Avatar,
  Box,
  Typography,
  LinearProgress,
  TableContainer,
  Paper,
  IconButton
} from "@mui/material";
import { toast } from "react-toastify";
import { Plus, Trash } from "../../../constants/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  addOrEditProjectDetails,
  uploadProjectImage,
  getProjectDetails,
  uploadWlFileBase64,
  editWLModel,
} from "../../../api/userApi";
import EditModels from "./edit-models";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar } from "swiper";
import { Check, Delete } from "@mui/icons-material";
import SubHeader from "../../../components/global-components/sub-header";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, preventString, resolvePersianAndArabicNumbers, validateURL } from "../../../helpers";
import { confirmAlert } from "react-confirm-alert";
import DragAndDropFileUploader from "../../../components/global-components/drag-drop-file-uploader";
import AccessDenied from "../../shared/access-denied";
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";
// import ManageModelADs from "./manage-model-ads";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionsPopper from "../../../components/global-components/actions-popper";

const isNew = window.location.href.indexOf("add") > -1;
const publicUrl = process.env.REACT_APP_URL + "/";

const EditProject = () => {
  const { token, currentPlan, vendor } = useAuth();
  const { id } = useParams();
  let navigate = useNavigate();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [Project, setProject] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingLogoButton, setLoadingLogoButton] = useState(false);
  const [loadingBrochureButton, setLoadingBrochureButton] = useState(false);
  const [uploadedPicMsg, setuploadedPicMsg] = useState("");
  const [sections, setSections] = useState([]);
  const [Path] = useState("Project/" + id + "/Photos/");
  const [timeStamp, setTimeStamp] = useState(new Date());
  const [loadingImageButtons, setLoadingImageButtons] = useState({ number: '0' });
  const [uploadedImages, setUploadedImages] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  // const [manageModelADs, setManageModelADs] = useState({
  //   model: undefined,
  //   projectId: id,
  //   visible: false
  // });
  const [editModel, setEditModel] = useState({
    model: { modelType: "فيلا" },
    isEdit: false,
    visible: false
  });
  const [model, setModel] = useState();
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t, i18n } = useTranslation();

  const uploadedProgress = useMemo(() => {
    if (totalImages === 0 || (totalImages > 0 && (uploadedImages - 1 === totalImages)))
      return 0;
    return uploadedImages * 100 / totalImages > 100 ? 100 : uploadedImages * 100 / totalImages;
  }, [uploadedImages, totalImages]);


  function isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }
  useEffect(
    () => {
      if (isNumber(id)) {
        callGetProjectDetails();
      }
    }, // eslint-disable-next-line
    [id]
  );

  useEffect(() => {
    if (totalImages > 0 && uploadedImages - 1 === totalImages) {
      callGetProjectDetails(true);
      setTotalImages(0);
      setuploadedPicMsg("");
      setUploadedImages(1);

    }
  }, // eslint-disable-next-line
    [uploadedImages]);

  function callGetProjectDetails(imagesAndModelsOnly = false) {
    setUploadedImages(1);
    getProjectDetails({
      projectId: id,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((project) => {
      if (project.resCode === 0) {
        if (imagesAndModelsOnly)
          setProject({ ...Project, units: project.response.units, images: project.response.images });
        else
          setProject(project.response);
        
        let data = [];
        for (let item of project.response.sections)
          data.push(item);
        setSections(data);

        // if (!isNew && projectStatus.length === 3) {
        //   let found = projectStatus.filter(
        //     (i) => i.title === project.response.status
        //   );
        //   if (found.length === 0)
        //     setProjectStatus([
        //       ...projectStatus,
        //       { title: project.response.status },
        //     ]);
        // }
        setuploadedPicMsg("");
      }
    });
  }

  function handleDeleteModel(modelId) {
    editWLModel({
      projectId: id,
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
      modelId: modelId,
      isDelete: "1",
    }).then((_items) => {
      if (_items.resCode === 0) {
        toast.success(t("TheModelHasBeenSuccessfullyDeleted"));
        callGetProjectDetails(true);
      } else toast.error(_items.resStr);
    });
  }

  const handleSave = () => {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      addOrEditProjectDetails({
        projectId: id,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
        description: Project.description,
        area: Project.area,
        totalUnits: Project.totalUnits,
        status: Project.status,
        name: Project.name,
        sections: sections,
        locationDesc: Project.locationDesc,
        logo: Project.logo,
        videoLink: Project.videoLink,
        brochure: Project.brochure
      }).then((property) => {
        if (property.resCode === 0) {
          setLoadingButton(false);
          toast.success(t("TheProjectHasBeenSaved"));
        }
      }).catch(e => { setLoadingButton(false) });
    
      if (Project.publishStatus === '0') {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h3>{t("ConfirmPublication")}</h3>
                <h5 style={{ marginBottom: "15px" }}>{t("WouldYouLikeToPublishTheProjectNow")}</h5>
                <div className='btns-wrapper'>
                  <button
                    className='outlined-btn px-3'
                    onClick={() => {
                      onClose();
                      navigate(`/admin/projects`);
                    }}>
                    {t("No")}
                  </button>
                  <button
                    className='primary-btn px-4'
                    onClick={() => {
                      onClose();
                      addOrEditProjectDetails({
                        projectId: id,
                        userId: token,
                        language: i18n.language === 'ar' ? '0' : '1',
                        description: Project.description,
                        area: Project.area,
                        totalUnits: Project.totalUnits,
                        status: Project.status,
                        name: Project.name,
                        sections: sections,
                        locationDesc: Project.locationDesc,
                        logo: Project.logo,
                        videoLink: Project.videoLink,
                        brochure: Project.brochure,
                        publishStatus: '1'
                      }).then((property) => {
                        if (property.resCode === 0) {
                          toast.success(t("TheProjectPublished"));
                          navigate(`/admin/projects`);
                        }
                      }).catch(e => { });
                    }}>
                    {t("OK")}
                  </button>
                </div>
              </div>
            );
          }
        });
      } else {
        navigate(`/admin/projects`);
      }
    }
  };

  const onDrop = (picture) => {
    DropImage(picture, "photo");
  };

  const DropImage = async (picture, source) => {
    if (DEMO_VENDORS.includes(vendor)) {
      for (let index = 0; index < picture.length; index++) {
        let imag = picture[index];
        let reader = new FileReader();
        reader.readAsDataURL(imag);
        reader.onloadend = async () => {
          setProject((old) => {
            return {
              ...old,
              images: [...old.images, reader.result]
            }
          });
        };
      }
    } else {
      setTotalImages(picture.length);
      const forLoop = async _ => {
        for (let index = 0; index < picture.length; index++) {
          let imag = picture[index];
          let reader = new FileReader();
          reader.readAsDataURL(imag);
          reader.onloadend = async () => {
            setuploadedPicMsg(t("ImagesAreUploading"));
            uploadProjectImage({
              projectId: id,
              pathWithFileName: Path + Date.now() + imag?.name?.substring(imag?.name?.lastIndexOf('.')),
              userId: token,
              base64Data: reader.result,
              type: source,
            }).then((property) => {
              if (property.resCode === 0) {
                setUploadedImages((val) => val + 1);
              } else {
                toast.error(property.resStr);
              }
            }).catch(e => {
              setuploadedPicMsg("");
              // setUploadedProgress(0);
              setTotalImages(0);
              callGetProjectDetails(true);
            });
          };
        }
      }
      await forLoop();
    }
  }

  function handleDeleteImage(target) {
    let photos = "";
    let deleted = target.currentTarget.title;
    let images = Project.images;
    const index = images.indexOf(deleted);
    images.splice(index, 1);

    if (DEMO_VENDORS.includes(vendor)) {
      setProject({
        ...Project,
        images: images
      });
    } else {
      images.forEach((element) => {
        photos = photos + element + ",";
      });
  
      if (photos.length > 1) photos = photos.slice(0, -1);
      if (photos.length < 2) photos = "-1";
  
      addOrEditProjectDetails({
        projectId: id,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
        images: photos,
        sections: sections
      }).then((property) => {
        if (property.resCode === 0) callGetProjectDetails(true);
      });
    }
   
  }

  function uploadSectionImage1(file, id) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      let fileInfo = {
        pathWithFileName: Path + id + "/" + Date.now() + file[0]?.name?.substring(file[0]?.name?.lastIndexOf('.')),
        base64Data: reader.result,
        typeOfFile: file[0].type,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlSectionFile(fileInfo, id);
    };
  }
  function getFormatedPrice(price) {
    if (price) {
      let value = price?.toString()?.replace(/[,]+/g, "");
      return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    } else
      return price;
  }

  function uploadSectionImage(file, type, sectionNum) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileInfo = {
        pathWithFileName: Path + sectionNum + "/" + Date.now() + file?.name?.substring(file?.name?.lastIndexOf('.')),
        base64Data: reader.result,
        typeOfFile: type,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlSectionFile(fileInfo, sectionNum);
    };
  }

  function uploadImage(file, type, sectionNum) {
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: Path + sectionNum + "/" + Date.now() + file?.name?.substring(file?.name?.lastIndexOf('.')),
        base64Data: reader.result,
        typeOfFile: type,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      };
      uploadWlFile(fileInfo, sectionNum);
    };
  }

  function uploadWlFile(fileInfo, sectionNum) {
    if (DEMO_VENDORS.includes(vendor)) {
      if (sectionNum === "3")
        setProject({
          ...Project,
          logo: fileInfo.base64Data,
        });
      else if (sectionNum === "4")
        setProject({
          ...Project,
          brochure: fileInfo.base64Data,
        });
    } else {
      if (sectionNum === "3") setLoadingLogoButton(true);
      else if (sectionNum === "4") setLoadingBrochureButton(true);

      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          toast.success(t("ImageSavedSuccessfully"));
          if (sectionNum === "3") {
            setProject({
              ...Project,
              logo: resp.response.url,
            });
            setLoadingLogoButton(false);
          } else if (sectionNum === "4")
            setProject({
              ...Project,
              brochure: resp.response.url,
            });
          setLoadingBrochureButton(false);
        } else {
          toast.error(resp.resStr);
        }
      });
    }
  }

  function uploadWlSectionFile(fileInfo, sectionNum) {
    if (DEMO_VENDORS.includes(vendor)) {
      setField(sectionNum, 'image', fileInfo.base64Data);
      setTimeStamp(new Date() + timeStamp);
    } else {
      setLoadingImageButtons({ number: sectionNum });
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          toast.success(t("ImageSavedSuccessfully"));
          setField(sectionNum, 'image', resp.response.url);
        } else {
          toast.error(resp.resStr);
        }
        setLoadingImageButtons({ number: '0' });
        setTimeStamp(new Date() + timeStamp);
      });
    }
  }

  function handleSetDefaultImage(target) {
    let photos = "";
    let image = target.currentTarget.title;
    const photosArr = Project.images;
    const index = photosArr.indexOf(image);
    photosArr.splice(index, 1);
    photosArr.unshift(image);

    if (DEMO_VENDORS.includes(vendor)) {
      setProject({
        ...Project,
        images: photosArr
      });
    } else {
      photosArr.forEach((element) => {
        photos = photos + element + ",";
      });
      if (photos.length > 1) photos = photos.slice(0, -1);
      if (photos.length < 2) photos = "-1";

      addOrEditProjectDetails({
        projectId: id,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
        images: photos,
        sections: sections
      }).then((property) => {
        if (property.resCode === 0) callGetProjectDetails(true);
      });
    }
  }

  const addNewSection = () => {
    let section = {
      id: `${sections.length + 1}${parseInt(Math.random() + 1 * 598)}`,
      title: "",
      description: "",
      image: ""
    };
    setSections([...sections, section]);
  };

  const deleteSection = (id) => {
    let section = sections.filter(t => t.id !== id);
    setSections(section);
  };

  const setField = (id, fieldName, newValue) => {
    let temp = sections;
    let section = sections.filter(t => t.id === id)[0];
    
    temp[temp.map((x, i) => [i, x]).filter(
      x => x[1] === section)[0][0]] = { ...section, [fieldName]: newValue };
    setSections(temp);
    setTimeStamp(new Date() + timeStamp);

  };

  const getField = (id, fieldName) => {
    let section = sections.filter(t => t.id === id)[0];
    return section[fieldName] || null;
  };

  return currentPlan?.plan?.id && +currentPlan?.plan?.id === 9 ? (
    <div>
      <SubHeader
        withSteps
        OKElement={<></>}
        steps={[t("RealEstateAdvertisingControls"), t("BasicInformation"), t("Specifications")]}
        completedSteps={[0, 1]}
        currentStep={2}
      />

      <div className='custom-card'>
        <div className='card-header'>
          <h4>{t("Images")}</h4>
          <h6>{t("AddProjectImages")}</h6>
        </div>
        <div className='card-body'>
          <DragAndDropFileUploader
            ImageOnly
            singleFile={false}
            OnOK={onDrop}
            uploading={uploadedPicMsg}
            id="project-imgs"
          />
          {/* {uploadedPicMsg ? (
            <img src={publicUrl + "assets/img/loding.gif"} alt='loading'></img>
          ) : (
            ""
          )} */}
                     
          {uploadedPicMsg && (
            <Box sx={{ width: '100%', marginTop: '30px', position: 'relative', display: 'inline-flex' }}>
              <LinearProgress variant="determinate" value={uploadedProgress} sx={{ width: '100%', height: '20px' }} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" sx={{ fontWeight: 700, color: '#fff' }}>
                  {`${Math.round(uploadedProgress)}%`}
                </Typography>
              </Box>
            </Box>
          )}
          {Project.images && Project.images.length > 0 && (
            <div className='uploaded-images-wrapper'>
              <h4> {t("UploadedImages", { count: Project.images.length })}</h4>
              <Swiper
                spaceBetween={32}
                slidesPerView={5}
                key={i18n.language +'ii'}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  450: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  680: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                scrollbar={{ draggable: true, el: ".swiper-scrollbar" }}
                modules={[Scrollbar, Keyboard]}
                keyboard={{ enabled: true }}
                speed={2600}>
                {Project.images.map((image, index) => (
                  <SwiperSlide key={index} className='image-item'>
                    <span
                      className={`custom-btn ${index === 0 ? "selected" : ""}`}>
                      {index === 0 ? (
                        <span>{t("CoverImage")}</span>
                      ) : (
                        <>
                          <Check />
                          <span onClick={handleSetDefaultImage} title={image}>
                            {t("MakeItCoverPhoto")}
                          </span>
                        </>
                      )}
                    </span>

                    <span
                      title={image}
                      className='del-icon'
                      onClick={handleDeleteImage}>
                      <Trash />
                    </span>
                    <img src={image} alt='' />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='swiper-scrollbar'></div>
            </div>
          )}
        </div>
      </div>

      <div className='custom-card'>
        <div className='card-header'>
          <h4>{t("ProjectDescription")}</h4>
          <h6>{t("WriteNameAndComprehensiveDescriptionOfProject")}</h6>
        </div>
        <div className='card-body'>
          <div className='fields-wrapper'>
            <TextField
              label={t("ProjectName")}
              sx={{ width: "100%" }}
              type='text'
              inputProps={{ maxLength: 50 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={Project.name}
              size='small'
              onChange={(v) => {
                setProject({
                  ...Project, name: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 50 ? v.currentTarget.value?.substring(0, 50) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign' style={{ marginTop: '-15px' }}>
              {Project.name
                ? parseInt(Project.name.length) + "/50"
                : "0/50"}
            </h6>

            <TextField
              label={t("ProjectDescription")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={Project.description}
              size='small'
              multiline
              rows={6}
              onChange={(v) => {
                setProject({
                  ...Project, description: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />

            <h6 className='rightalign' style={{ marginTop: '-15px' }}>
              {Project.description
                ? parseInt(Project.description.length) + "/500"
                : "0/500"}
            </h6>
          </div>
        </div>
      </div>

      <div className='cards-wrapper details-wrapper'>
        <div className='custom-card'>
          <div className='card-header'>
            <h4>{t("ProjectSpecifications")}</h4>
            <h6>{t("SpecifyTheProjectStatusAreaAndNumberOfUnits")}</h6>
          </div>
          <div className='card-body'>
            <div className='fields-wrapper'>
              <TextField
                label={t("ProjectStatus")}
                sx={{ width: "100%" }}
                type='text'
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{
                  shrink: true
                }}
                value={Project.status}
                size='small'
                onChange={(v) => {
                  setProject({
                    ...Project, status: resolvePersianAndArabicNumbers(
                      v.currentTarget.value?.length >= 50 ? v.currentTarget.value?.substring(0, 50) : v.currentTarget.value
                    )
                  });
                }}
              />
              <h6 className='rightalign' style={{ marginTop: '-15px' }}>
                {Project.status
                  ? parseInt(Project.status.length) + "/50"
                  : "0/50"}
              </h6>
              <TextField
                label={t("ProjectArea")}
                sx={{ width: "100%" }}
                size='small'
                inputProps={{ maxLength: 15 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>{t("m2")}</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={Project.area ? getFormatedPrice(Project.area) : Project.area}
                onChange={(v) => {
                  setProject({ ...Project, area: preventString(v.currentTarget.value?.replace(/,/g, '')) });
                }}
              />

              <TextField
                label={t("TotalUnits")}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 15 }}
                value={Project.totalUnits ? getFormatedPrice(Project.totalUnits) : Project.totalUnits}
                size='small'
                onChange={(v) => {
                  setProject({
                    ...Project,
                    totalUnits: preventString(v.currentTarget.value?.replace(/,/g, '')),
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className='custom-card'>
          <div className='card-header'>
            <h4>{t("ProjectIdentity")}</h4>
            <h6>{t("UploadYourProjectLogoAndBrochure")}</h6>
          </div>
          <div className='card-body'>
            <div className='fields-wrapper'>
              <div className='logo-section'>
                {Project.logo ? (
                  <img src={Project.logo} alt='Logo' />
                ) : (
                  <span className="d-flex align-items-center justify-content-center" style={{ fontWeight: 700 }}>LOGO</span>
                )}
                <DragAndDropFileUploader
                  singleFile
                  ImageOnly
                  handleChange={(e) => uploadImage(e.target.files[0], "jpg", "3")}
                  loading={loadingLogoButton}
                  title={!isNew ? t("ChangeLogo") : t("AddLogo")}
                  id="contained-buttosn-file-3"
                  classes="text-align-last-center py-1"
                  buttonMode
                />
              </div>

              <div className='brochure-section'>
                {Project.brochure && validateURL(Project.brochure) ?
                  (
                    <a
                      href={Project.brochure.substring(0, Project.brochure.indexOf('?') > -1 ? Project.brochure.indexOf('?') : Project.brochure.length)}
                      target='_blank'
                      style={{ background: "white", height: '64px', borderRadius: '12px' }}
                      rel='noreferrer'>
                      <img
                        src={publicUrl + "assets/img/icons/Icon_pdf_file.png"}
                        alt='image2' />
                    </a>
                  )
                  :
                  (
                    <img
                      src={publicUrl + "assets/img/icons/Icon_pdf_file.png"}
                      alt='image2' />
                  )}
              
                <DragAndDropFileUploader
                  singleFile
                  PDFOnly
                  handleChange={(e) => uploadImage(e.target.files[0], "pdf", "4")}
                  loading={loadingBrochureButton}
                  title={!isNew ? t("EditBrochure") : t("AddBrochure")}
                  id="contained-button-file-pdf"
                  classes="text-align-last-center py-2"
                  buttonMode
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <div>
            <h4>{t("ResidentialUnitModels")}</h4>
            <h6>{t("YouCanAddEditAndDeleteResidentialUnitModels")}</h6>
          </div>
          <div>
            <button className="custom-btn px-4" onClick={() => setEditModel({
              model: { modelType: "فيلا" },
              isEdit: false,
              visible: true
            })}>
              <Plus /><span>{t("AddModel")}</span>
            </button>
           
          </div>

        </div>
        <div className='card-body position-relative'>
          {Project.units && Project.units.length > 0 && (
            <>
              <TableContainer className="table-container responsive-table" component={Paper}>
                <Table sx={{ minWidth: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align='right'>{t("ModelName")}</TableCell>
                      <TableCell align='right'>{t("ModelImage1")}</TableCell>
                      <TableCell align='right'>{t("ModelType")}</TableCell>
                      <TableCell align='right'>{t("ModelPlan")}</TableCell>
                      <TableCell align='right'>{t("Actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Project.units.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell align='right'>{item.name}</TableCell>
                        <TableCell align='right'>
                          {item.image ? (
                            <Avatar
                              variant='square'
                              sx={{ width: 56, height: 56 }}
                              src={item.image}
                            />
                          ) : (
                            t("NotAvailable")
                          )}
                        </TableCell>

                        <TableCell align='right'>
                          {item.modelType}
                        </TableCell>
                        <TableCell align='right'>
                          {item.floorPlan ? (
                            <a
                              rel='noreferrer'
                              // href={validateURL(item.floorPlan) ? item.floorPlan.substring(0, item.floorPlan.indexOf('?')) : ''}
                              href={item.floorPlan.substring(0, item.floorPlan.indexOf('?'))}
                              target='_blank'
                              download>
                              {t("DownloadPlan")}
                            </a>
                          ) : (
                            t("NotAvailable")
                          )}
                        </TableCell>

                        <TableCell align='right'>
                          <IconButton
                            onClick={(e) => {
                              setOpenPopper(!openPopper);
                              setAnchorEl(anchorEl ? null : e.target);
                              setModel(item);
                            }}
                            sx={{
                              background: '#fff',
                              zIndex: 99,
                              color: 'var(--main-color-one)',
                              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                              '&:hover,&:focus': {
                                color: '#fff',
                                background: 'var(--main-color-one)'
                              }
                            }}>
                            <MoreVertIcon />
                          </IconButton>
                      
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ActionsPopper
                openPopper={openPopper}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setOpenPopper={setOpenPopper}
                items={
                  [
                    {
                      title: t("EditTheModel"),
                      action: () => setEditModel({ model: model, isEdit: true, visible: true })
                    },
                    // {
                    //   title: "إدارة الإعلانات العقاريّة",
                    //   action: () => setManageModelADs({ model: model, projectId: id, visible: true })
                    // },
                    {
                      title: t("DeleteModel"),
                      action: () => {
                        if (DEMO_VENDORS.includes(vendor)) {
                          setProject({
                            ...Project,
                            units: Project.units?.filter(i => i.modelId !== model.id && i.id !== model.id)
                          });
                        } else {
                          handleDeleteModel(model.id);
                        }
                      }
                    }
                  ]
                }
              />
            </>
          )}
        </div>
      </div>

      {/* <ManageModelADs
        dialog={manageModelADs}
        setDialog={setManageModelADs}
      /> */}
      <EditModels
        projectId={id}
        editModel={editModel}
        setEditModel={setEditModel}
        setProject={setProject}
        Project={Project}
        isChange={(e) => callGetProjectDetails(true)}
      />
      <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <div>
            <h4>{t("Features")}</h4>
            <h6>{t("AddTitleImageAndDescriptionForEachFeature")}</h6>
          </div>
          <div>
            <button className="custom-btn px-4" onClick={addNewSection}>
              <Plus /><span>{t("AddFeature")}</span>
            </button>
          </div>
        </div>
        <div className='card-body'>
          {sections.map((item, i) => (
            <div key={i} className='custom-card with-switcher'>
              <div className='card-header'>
                <div>
                  <h4>{`${t("Feature")} ${i + 1}`}</h4>
                  <h6>{t("AddTitleImageAndDescriptionForTheFeature")}</h6>
                </div>
                <div>
                  <button className="custom-btn delete-btn px-4" onClick={() => deleteSection(item.id)}>
                    <Delete /> <span>{`${t("DeleteFeature")} ${i + 1}`}</span></button>
                </div>
              </div>
              <div className='card-body'>
                <div className='fields-wrapper'>
                  <div className='img-wrapper position-relative w-100 d-flex'>
                    {loadingImageButtons.number !== item.id && !getField(item.id, 'image') && (
                      <DragAndDropFileUploader
                        singleFile
                        ImageOnly
                        id={`project-feature-${i + 1}`}
                        OnOK={(file) => uploadSectionImage1(file, item.id)} />
                    )}
                    {loadingImageButtons.number === item.id && (
                      <img
                        className='loading-img mx-auto'
                        src={publicUrl + "assets/img/loding.gif"}
                        alt='loading-model'></img>
                    )}
                    {loadingImageButtons.number !== item.id && getField(item.id, 'image') && (
                      <>
                        <DragAndDropFileUploader
                          singleFile
                          ImageOnly
                          handleChange={(e) => uploadSectionImage(e.target.files[0], "jpg", item.id)}
                          loading={loadingImageButtons.number === item.id}
                          title={t("ChangeImage")}
                          id={`contained-button-file${id + 1}`}
                          style={{
                            width: 'fit-content',
                            position: 'absolute',
                            top: '10px',
                            right: '10px'
                          }}
                          buttonMode
                        />
                        <img src={getField(item.id, 'image')} alt='section1Image' style={{
                          height: '300px',
                          width: '100%',
                          objectFit: 'cover'
                        }} />
                      </>
                    )}
                  </div>
                  <TextField
                    label={t("Title")}
                    sx={{ width: "100%" }}
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 50 }}
                    size='small'
                    onChange={(e) => {
                      let value = resolvePersianAndArabicNumbers(e.target.value?.length >= 50 ? e.target.value?.substring(0, 50) : e.target.value);
                      setField(item.id, 'title', value);
                      return value;
                    }}
                    defaultValue={getField(item.id, 'title')}
                  />
                  <h6 className='rightalign' style={{ marginTop: '-15px' }}>
                    {getField(item.id, 'title')
                      ? parseInt(getField(item.id, 'title')?.length) + "/50"
                      : "0/50"}
                  </h6>
                  
                  <TextField
                    label={t("Description")}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 1000 }}
                    size='small'
                    multiline
                    rows={5}
                    onChange={(e) => {
                      let value = resolvePersianAndArabicNumbers(
                        e.target.value?.length >= 1000 ? e.target.value?.substring(0, 1000) : e.target.value
                      );
                      setField(item.id, 'description', value);
                      return value;
                    }}
                    defaultValue={getField(item.id, 'description')}
                  />
                  <h6 className='rightalign'>
                    {getField(item.id, 'description')
                      ? parseInt(getField(item.id, 'description').length) + "/1000"
                      : "0/1000"}
                  </h6>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>

      <div className='custom-card'>
        <div className='card-header'>
          <h4>{t("ProjectGeographicAddress")}</h4>
          <h6>{t("YouCanAddDescriptionAboutProjectLocation")}</h6>
        </div>
        <div className='card-body'>
          <div className='fields-wrapper'>
            <TextField
              label={t("Description")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={Project.locationDesc}
              size='small'
              multiline
              rows={4}
              onChange={(v) => {
                setProject({
                  ...Project, locationDesc: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign'>
              {Project.locationDesc
                ? parseInt(Project.locationDesc.length) + "/500"
                : "0/500"}
            </h6>
            {/* {Project.latitude &&
              <GoogleMapsContainerForAdmin
                title='اختر موقع المشروع'
                data={{
                  long: Project.longitude,
                  lat: Project.latitude,
                  address: Project.address_ar
                }}
                setLocation={(e) => {
                  setProject({
                    ...Project,
                    address: e.address,
                    latitude: e.latitude,
                    longitude: e.longitude,
                  });
                }}
              />
            } */}
           
          </div>
        </div>
      </div>
      <div className="text-center pb-5 mb-5">
        <LoadingButton
          style={{ minWidth: "250px", justifyContent: "center" }}
          label={isNew ? t("Next") : t("Save")}
          classes='primary-btn px-5 mx-auto mb-5'
          handleClick={handleSave}
          loading={loadingButton}
        />
        {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      </div>
    </div>
  ) : (
    <AccessDenied
      btnLink={'/admin/plans'}
      btnText={t("GoToSubscriptionPage")}
      desc={t("YouMustUpgradeYourCurrentPlanToAccessThisPage")}
    />
  );
}

export default EditProject;
