import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Box, FormControl, FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import { forgotPassword } from "../../api/userApi";
import AppLayout from "../../components/layouts/app-layout";
import LoadingButton from "../../components/global-components/loading-btn";
import { preventString, validatePhone } from "../../helpers";
import { countryCodes } from "../../constants";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

function ForgotPassword() {
    const [errors, setErrors] = useState({});
    const history = useNavigate();
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState({
        countryCode: "+966",
        phone: "",
        language: i18n.language === 'ar' ? '0' : '1',
    });
    const [loadingButton, setLoadingButton] = useState(false);
    
    function formIsValid() {
        const _errors = {};
        if (!user.countryCode) _errors.countryCode = t("ChooseCountryCode");
        if (!user.phone) _errors.phone = t("EnterPhoneNumber");
        else if (!validatePhone(user.phone)) _errors.phone = t("PhoneNumberIsIncorrect");

        setErrors(_errors);
        return Object.keys(_errors).length === 0;
    }
    
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

    function handleSubmit(event) {
        event.preventDefault();

        if (!formIsValid()) return;
        setLoadingButton(true);
        forgotPassword(user).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
                history("/otp-change-password/" + user.countryCode + "/" + user.phone);
                if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
            } else {
                const _errors = {};
                _errors.phone = _userInfo.resStr;
                setErrors(_errors);
            }
            setLoadingButton(false);
        }).catch(e => setLoadingButton(false));
    }

    return (
        <AppLayout pageTitle={t("SetPassword")}>
            <div className="page-wrapper page-width">
                <div className="row page-wrapper-without-top">
                    <div className="col-12 col-lg-6 details-wrapper scrolled">
                        <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px 10% 70px' }}>
                            <form onSubmit={handleSubmit}>
                                <div className='container'>
                                    <h4 className="page-heading">{t("SetPassword")}</h4>
                                    <p>{t("PleaseEnterYourPhoneNumber1")}</p>
                                    <div className="row mx-0 mt-4 phone-wrapper">
                                        <div className='col-12 mb-4 col-lg-3 pl-0 p-0'>
                                            <div className='form-group'>
                                                <div>
                                                    <Select
                                                        id='countryCode'
                                                        name='countryCode'
                                                        onChange={(e) => setUser({ ...user, countryCode: e.target.value })}
                                                        value={user.countryCode || ""}
                                                        className='sortDDL withBg h-35'>
                                                        {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
                                                    </Select>
                                                </div>
                                                {errors.countryCode && (
                                                    <div className='alert alert-danger'>
                                                        {errors.countryCode}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-12 mb-4 col-lg-6 pr-0 p-0'>
                                            <FormControl error={Boolean(errors.phone)} className="col-12">
                                                <TextField
                                                    id='phone'
                                                    InputLabelProps={{ shrink: true }}
                                                    label={t("PhoneNumber")}
                                                    name='phone'
                                                    size="small"
                                                    sx={{ width: '100%', '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                                                    onChange={(e) => setUser({ ...user, phone: preventString(e.target.value) })}
                                                    value={user.phone}
                                                    error={Boolean(errors.phone)}
                                                />
                                                {errors.phone &&
                                                    <FormHelperText component={Box}>
                                                        <Alert severity="error" className="custom-alert">
                                                            {errors.phone}
                                                        </Alert>
                                                    </FormHelperText>}
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='col-12 p-0' >
                                        <LoadingButton
                                            classes="primary-btn d-flex  justify-content-center py-2 px-5"
                                            label={t("Next")}
                                            type="submit"
                                            loading={loadingButton}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
                        <div className="img-container">
                            <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default ForgotPassword;
