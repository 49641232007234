import React from "react";
import { renderRoomIcon } from "../../../../helpers";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

const Preview = (props) => {
  const {
    mainColorOne,
    mainColorTwo,
    headingColor,
    paragraphColor,
    btnColor,
    bodyFontSize,
    bodyFont,
  } = props.colors;

  const { t } = useTranslation();

  const data = [
    { key: "10", value: "8", name: t("ParkingNumber") },
    { key: "11", value: "3", name: t("FloorNumber") },
    { key: "8", value: "4", name: t("Bedrooms") },
    { key: "9", value: "2", name: t("Bathroom") },
  ];
  
  return (
    <div className='preview row' style={{ background: mainColorTwo }}>
      <div id="background">
        <p id="bg-text">{t("ThisSectionIsForPreviewingChangesOnly")}</p>
      </div>
      <div className='col-12'>
        <h4 style={{ color: headingColor, fontFamily: bodyFont }}>{t("PropertySpecifications")}</h4>
        <p style={{ color: paragraphColor, "--body-font-size": bodyFontSize, fontFamily: bodyFont }}>{t("WePresentToYouTheMostImportantFeaturesOfOurProperty")}</p>
        <div className='property-info mt-2'>
          <div className='row p-0 m-0'>
            {data.map((item) => (
              <div className='col-sm-6 box' key={item.key}>
                <div
                  className='icon'
                  style={{ "--main-color-one": mainColorOne, fontFamily: bodyFont }}>
                  {renderRoomIcon(item.key)}
                </div>
                <div className='info'>
                  <h5 style={{ color: paragraphColor, fontFamily: bodyFont }}>
                    {item.name}
                  </h5>
                  <span
                    style={{
                      color: paragraphColor,
                      "--body-font-size": bodyFontSize,
                      fontFamily: bodyFont,
                    }}>
                    {item.value}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='col-12 mt-4'>
        <h4 style={{ color: headingColor, fontFamily: bodyFont }}>
          {t('Price')}
        </h4>
        <p
          style={{
            color: paragraphColor,
            "--body-font-size": bodyFontSize,
            fontFamily: bodyFont,
          }}>
          {t("AddThePropertyPriceAccurately")}
        </p>
        <div className='property-info mt-2'>
          <div className='row p-0 m-0'>
            <div className='col-12 col-md-6 mt-2 mb-3 p-0'>
              <TextField
                type='text'
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="false"
                sx={{ width: "100%" }}
                size='small'
                label={t("TotalPrice")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 btns-wrapper pb-4 flex-wrap'>
        <button
          className='primary-btn px-5'
          style={{
            "--btn-color": btnColor,
            fontFamily: bodyFont,
            "--main-color-one": mainColorOne,
            border: `1px solid ${mainColorOne}`,
          }}>
          {t("PrimaryButton")}
        </button>
        <button
          className='outlined-btn px-5'
          style={{
            "--main-color-one": mainColorOne,
            fontFamily: bodyFont,
            "--btn-color": btnColor,
            border: `1px solid ${mainColorOne}`,
          }}>
          {t("SecondaryButton")}
        </button>
      </div>

      {/* <div className='col-12 btns-wrapper pb-4'>
        <button
          className='primary-btn px-5'
          style={{
            "--btn-color": btnColor,
            fontFamily: bodyFont,
            "--main-color-one": mainColorOne,
            border: `1px solid ${mainColorOne}`,
          }}>
          تأكيد
        </button>
      </div> */}
    </div>
  );
}

export default Preview;
