import Close from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ChangePasswordForm from "../../scenes/amakkn/profile/components/change-password-form";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = ({ openChangePasswordModal, setOpenChangePasswordModal, agent, agentId }) => {
    const { t } = useTranslation();
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={openChangePasswordModal}
            className="custom-dialog"
            sx={{ '& .MuiPaper-root': { margin: 0, width: 'calc(100% - 20px)' } }}
            onClose={() => setOpenChangePasswordModal(false)}>
            <DialogTitle>
                <div className="dialog-head align-items-start">
                    <div>
                        <h2>{t("SetPassword")}</h2>
                    </div>
                    <div className="dialog-actions close-only">
                        <span onClick={() => setOpenChangePasswordModal(false)} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className='row justify-content-start p-0'>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%', padding: '10px 18px', textAlign: 'center' }}>
                            <ChangePasswordForm type="modal" agent={agent} agentId={agentId} setOpenChangePasswordModal={setOpenChangePasswordModal} />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
  
export default ChangePasswordModal;