import React, { useState } from "react";
import GoogleMapsContainer from "./GoogleMapsContainer";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";
import * as userApi from "../../../api/userApi";
import firebase from "firebase/compat/app";
import "firebase/analytics";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../helpers/context";
import AppLayout from "../../layouts/app-layout";
import { useTranslation } from "react-i18next";
// import { CircularProgress } from "@mui/material";

const publicUrl = process.env.REACT_APP_URL + "/";

const CompanyLocationVerfication = (props) => {
  const { token } = useAuth();
  const { t , i18n } = useTranslation();
  // const [ZoomLevel, setZoomLevel] = useState(14);
  // eslint-disable-next-line no-unused-vars
  // const [disable] = React.useState(false);
  // const [loading] = React.useState(false);

  const history = useNavigate();
  let { address } = useParams();
  let { lat } = useParams();
  let { lng } = useParams();
  const [user, setUser] = useState({
    id: null,
    userIdHashed: token,
    address: address === "empty" ? "الرياض" : address,
    latitude: lat ? lat : "24.74772941029266",
    longitude: lng ? lng : "46.619290983645406",
    isLocationChanged: "0",
    language: i18n.language === 'ar' ? '0' : '1',
    neighborhood: "",
  });

  function handleChange(NewUser) {
    setUser({
      ...user,
      NewUser,
    });
  }

  function handleChangeAddress() { }
  function handleZoomChange(NewZoom) {
    // setZoomLevel(NewZoom);
  }
  function handleNext() {
    userApi.verifyCompanyLocation(user).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        const analytics = firebase.analytics();
        analytics.logEvent("company_verification", { platform: "website" });
      } else {
        toast.error(_userInfo.resStr);
      }
    });
    history("/successverfication");
  }

  const options = {
    childrenElement: () => <div />,
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          {/* {loading && (
            <div className='d-flex justify-content-center align-items-center loading-overlay'>
              <CircularProgress />
            </div>
          )} */}
          <h4 style={{ marginBottom: "15px" }}>
            {t("TheNationalAddressRegisteredWithSaudiPostIs")}: <span style={{ color: 'var(--main-color-one)' }}>{address}</span>
          </h4>
          
          <h5 className="mt-5">{t("DoYouWantToChangeTheCompanyLocation")}</h5>
          <div className='btns-wrapper mt-0'>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
                handleNext();
              }}>
              {t("NoIDontWant")}
            </button>
            <button
              className={`primary-btn px-4`}
              onClick={() => {
                user.isLocationChanged = "1";
                onClose();
              }}>
              {t("Yes")}
            </button>
          </div>
          <div>
          </div>
        </div>
      );
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypressEscape: () => { },
    overlayClassName: "overlay-custom-class-name",
  };

  if (user.isLocationChanged === "0")
    if (address !== "empty") confirmAlert(options);

  return (
    <AppLayout needAuth={true} pageTitle={t("ChangeCompanyLocation")}>
      <div
        className='pd-top-100 px-4'
        style={{ overflow: "hidden", textAlign: "center" }}>
        <h4>{t("SelectCompanyLocation")}</h4>
        <div
          className='row justify-content-md-center m-0'
          style={{ height: "400px" }}>
          <div className='col-lg-4 col-sm-12'>
            <GoogleMapsContainer
              lat={lat}
              lng={lng}
              onChange={handleChange}
              onZoomChange={handleZoomChange}
              user={user}
            />
            <img
              alt='pin'
              src={publicUrl + "assets/img/icons/BluePin.svg"}
              className='companylocationPin'></img>
          </div>
        </div>

        <div className='row justify-content-md-center m-0'>
          <div
            className='col-sm-12 p-0'
            style={{ position: "relative", maxWidth: "525px" }}>
            <input
              id='address'
              name='address'
              className='form-control'
              value={user.address}
              onChange={handleChangeAddress}
              style={{
                position: "relative",
                zIndex: "50",
              }}
            />
          </div>
        </div>
        <div className='row justify-content-md-center'>
          <div className='col-sm-12' style={{ position: "relative" }}>
            <form onSubmit={handleNext} style={{ textAlign: "center" }}>
              <input
                id='CVnextToSucc'
                type='submit'
                style={{
                  width: "180px",
                  marginTop: "70px",
                }}
                value={t("Next")}
                className='btn btn-yellow'
              />
            </form>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default CompanyLocationVerfication;
