import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import {
  saveProfile,
  // showOrHideFamilyName,
  uploadProfileImage,
  saveProfilePictureNew,
  getUserPlan,
} from "../../../../api/userApi";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { Alert, Avatar, Box, FormControl, FormHelperText } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Trash } from "../../../../constants/icons";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { useAuth } from "../../../../helpers/context";
import {
  getDemoSnakbar,
  resolvePersianAndArabicNumbers,
  validateEmail,
} from "../../../../helpers";
import ChangePasswordModal from "../../../../components/global-components/change-password-modal";
import DragAndDropFileUploader from "../../../../components/global-components/drag-drop-file-uploader";
import ChangeTypePopup from "../../../../components/user-components/change-type-popup";
import SubHeader from "../../../../components/global-components/sub-header";
import { DEMO_VENDORS } from "../../../../constants";
import { useTranslation } from "react-i18next";

const MyProfilePage = (props) => {
  const { token, vendor } = useAuth();
  const location = useLocation();
  const [loadingButton, setLoadingButton] = useState(false);
  const [user, setUser] = useState(props.user);
  // const [ShowFamilyChecked, setShowFamilyChecked] = useState(
  //   user.showFamilyName === "1" ? true : false
  // );
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const isAdmin = location.pathname.includes("admin");
  const history = useNavigate();
  const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (token)
      getUserPlan({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((result) => {
        if (result.resCode === 0) {
          setCurrentPlan(result.response);
        } else {
          toast.error(result.resStr)
        }
      }).catch(e => { });
  }, [token, i18n]);

  const uploadUserImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        250,
        250,
        "png",
        100,
        0,
        async (uri) => {
          if (DEMO_VENDORS.includes(vendor)) {
            setUser({ ...user, avatar: uri });
          } else {
            await uploadProfileImage({
              pathWithFileName: "User/" + user.userId + "/Profile/" + file.name,
              userId: token,
              base64Data: uri,
            })
              .then((property) => {
                if (property.resCode === 0) saveUserProfile();
              })
              .catch((error) => {
                throw error;
              });
          }
        },
        "base64"
      );
    });

  function deleteUserImage() {
    if (DEMO_VENDORS.includes(vendor)) {
      setUser({ ...user, avatar: '' });
    } else {
      saveProfilePictureNew({
        userId: token,
        profilePicture: "-1",
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((e) => {
        if (e.resCode === 0) saveUserProfile();
      });
    }
  }
  // useEffect(() => {
  //   let show = "0";
  //   if (ShowFamilyChecked) show = "1";
  //   if (user.userType === "3" || user.userType === "2")
  //     showOrHideFamilyName({
  //       userId: user.userId,
  //       language: "0",
  //       show: show,
  //     }).then((_userInfo) => {
  //       if (_userInfo.resCode === 0)
  //         setUser({ ...user, name: _userInfo.response.userProfile.name });
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ShowFamilyChecked]);


  function saveUserProfile() {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      saveProfile({
        ...user,
        licenseNumber: user.licenseNumber,
        commercialRecordNumber: user.commercialRecordNumber,
        phone: user.phone,
        companyWebsiteURL: user.companyWebsiteURL,
        fb: user.fb,
        twitter: user.twitter,
        insta: user.insta,
      })
        .then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            let userProps = _userInfo.response.userProfile;
            userProps.userId = token;
            userProps.aboutMe = userProps.pushToken;
            setUser(userProps);
            toast.success(t("EditedSuccessfully"));
          } else {
            toast.error(_userInfo.resStr);
            if (_userInfo.response) {
              let userProps = _userInfo.response.userProfile;
              userProps.userId = token;
              userProps.aboutMe = userProps.pushToken;
              setUser(userProps);
            }
          }
          setLoadingButton(false);
        })
        .catch((e) => setLoadingButton(false));
    }
  }

  return (
    user && (
      <>
        <SubHeader
          pageTitle={vendor !== "amakkn" ? t("MyProfile") : t("MyProfile2")}
          OKElement={<></>}
        />

        <div className='cards-wrapper details-wrapper align-items-start'>
       
          <div className='custom-card' style={{ minHeight: 'auto' }}>
            <div className='card-header'>
              <h4>{t("BasicInformation")}</h4>
              <h6>{t("YouCanManageYourBasicInformationOnAmakkn")}</h6>
            </div>
            <div className='card-body'>
              <div className="row">

                <div className='col-12 mb-4 my-2 flex-column'>
                  <p className="px-1 mb-0 text-center">
                    {user?.accountType === "2" && user?.userType !== '5' ? 'الشعار' : 'الصورة الشخصيّة'}
                  </p>
                  
                  <div
                    className='my-2 justify-content-center mx-auto d-flex align-items-center'
                    style={{ gap: "10px" }}>
                    <Avatar
                      sx={{
                        width: 80, height: 80,
                        bgcolor: user.avatar ? '#fff' : 'var(--main-color-one)',
                        borderRadius: 2
                      }}
                      src={user.avatar}
                      variant="square"
                      alt=''
                    />

              
                  </div>
                  <div
                    className='d-flex align-items-center justify-content-center'
                    style={{ gap: "10px" }}>
                    <div className='btn-icon rounded-circle'>
                      <DragAndDropFileUploader
                        singleFile
                        ImageOnly
                        icon={<Edit sx={{ fontSize: '20px' }} />}
                        style={{
                          borderRadius: "100%",
                          padding: "6px",
                          width: '28px',
                          height: '28px'
                        }}
                        handleChange={(e) => uploadUserImage(e.target.files[0])}
                        id='file-input'
                        buttonMode
                      />
                    </div>
                    {user.avatar !== "" && (
                      <div
                        className='btn btn-danger btn-icon text-light mx-1 rounded-circle'
                        onClick={(e) => {
                          deleteUserImage();
                        }}>
                        <Trash />
                      </div>
                    )}
                  </div>
                </div>
              
            
                <div
                  className={`col-12 ${user?.userType === '5' ? 'col-md-4' : 'col-md-6'} mb-4 d-flex flex-column`}
                  style={{ gap: "4px" }}>
                  <TextField
                    label={t("Name")}
                    disabled
                    InputLabelProps={{ shrink: true }}
                    type='text'
                    size='small'
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        name: resolvePersianAndArabicNumbers(e.currentTarget.value),
                      });
                    }}
                    value={user.name}
                  />
                  {user?.userType !== '5' && (
                    <>
                      {isAdmin ? (
                        <>
                          <p
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                              textAlign: "right",
                              cursor: "pointer",
                            }}
                            onClick={() => setOpenChangePasswordModal(true)}>
                            {t("ChangePassword")}
                          </p>
                          <ChangePasswordModal
                            openChangePasswordModal={openChangePasswordModal}
                            setOpenChangePasswordModal={setOpenChangePasswordModal}
                          />
                        </>
                      ) : (
                        <Link
                          to='/change-password'
                          style={{
                            fontSize: "13px",
                            textDecoration: "underline",
                            textAlign: "right",
                          }}>
                          {t("ChangePassword")}
                        </Link>
                      )}
                    </>
                  )}
               

                  {/* {(user?.userType === "2" || user?.userType === "3") && (
                  <FormControlLabel
                    sx={{
                      padding: 0,
                      "& .MuiSwitch-root": { marginRight: "-25px" },
                    }}
                    control={
                      <Switch
                        checked={ShowFamilyChecked}
                        onChange={() => {
                          setShowFamilyChecked((prev) => !prev);
                        }}
                      />
                    }
                    label='أظهر اسم العائلة'
                  />
                )}*/}
                </div>
                {user?.userType === '5' && (
                  <>
                    <div
                      className='col-12 col-md-4 mb-4 d-flex flex-column'
                      style={{ gap: "4px" }}>
                      <TextField
                        label={t("PhoneNumber")}
                        type='text'
                        size='small'
                        sx={{ width: "100%" }}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={"0" + user.phone}
                      />
                    </div>
                    <div className='col-12 col-md-4 mb-4'>
                      <FormControl
                        className="col-12"
                      >
                        <TextField
                          label={t("Email")}
                          type='text'
                          InputLabelProps={{ shrink: true }}
                          size='small'
                          sx={{ width: "100%",'& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                          disabled
                          value={user.email}
                        />
                      </FormControl>
                    </div>
                  </>
                )}
           
                
                {user.accountType === "2" && user?.userType !== "5" && (
                  <>
                    <div className='col-12 col-md-6 mb-4'>
                      <TextField
                        label={user?.commercialRecordNumber?.startsWith('7') ? t("CompanyUnifiedNationalNumber") : t("CommercialRegistrationNo")}
                        type='text'
                        InputLabelProps={{ shrink: true }}
                        disabled
                        size='small'
                        sx={{ width: "100%" }}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            commercialRecordNumber: resolvePersianAndArabicNumbers(
                              e.currentTarget.value
                            ),
                          });
                        }}
                        // error={user.commercialRecordNumber && !validateNumber(user.commercialRecordNumber)}
                        value={user.commercialRecordNumber}
                      />
                    </div>

                    <div className='col-12 col-md-6 mb-4'>
                      <TextField
                        label={t("CompanyManager")}
                        type='text'
                        disabled
                        InputLabelProps={{ shrink: true }}
                        size='small'
                        sx={{ width: "100%" }}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            managerName: resolvePersianAndArabicNumbers(
                              e.currentTarget.value
                            ),
                          });
                        }}
                        value={user.managerName}
                      />
                    </div>

                    <div className='col-12 col-md-6 mb-4'>
                      <TextField
                        label={t("Address")}
                        type='text'
                        InputLabelProps={{ shrink: true }}
                        disabled
                        size='small'
                        sx={{ width: "100%" }}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            address: resolvePersianAndArabicNumbers(
                              e.currentTarget.value
                            ),
                          });
                        }}
                        value={user.address}
                      />
                    </div>
                  </>
                )}
         
                {/* {user.userType === "3" && (
                <div className='col-12 col-md-6 mb-4'>
                  <TextField
                    label='رخصة فال للوساطة والتسويق'
                    type='text'
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100%" }}
                    // error={user.licenseNumber && !validateNumber(user.licenseNumber)}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        licenseNumber: resolvePersianAndArabicNumbers(
                          e.currentTarget.value
                        ),
                        licenseType: "1",
                      });
                    }}
                    value={user.licenseNumber}
                  />
                </div>
              )} */}
                {user?.userType !== "5" && (
                  <div className='col-12 col-md-6 mb-4'>
                    <TextField
                      label={t("AccountType")}
                      type='text'
                      size='small'
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "100%" }}
                      value={user.accountType === '1' ? t("IndividualsAccount") : t("BusinessAccount")}
                    />
                    {user.accountType === '1' && <ChangeTypePopup
                      openChangeTypeModal={openChangeTypeModal}
                      setOpenChangeTypeModal={setOpenChangeTypeModal}
                      desc={t("YouCanUpgradeToBusinessAccountAndEnjoyAdditionalFeatures")}
                      title={t("UpgradeToBusinessAccount")}
                      trigger={
                        <p
                          onClick={() => {
                            if (DEMO_VENDORS.includes(vendor)) {
                              setOpenDemoSnackBar(true);
                            } else {
                              setOpenChangeTypeModal(true);
                            }
                          }}
                          style={{
                            fontSize: "13px",
                            marginTop: '4px',
                            textDecoration: "underline",
                            textAlign: "right",
                            cursor: 'pointer',
                            color: '#000'
                          }}>
                          {t("UpgradeToBusinessAccount")}
                        </p>
                      }
                    />
                    }
           
                  </div>
                )}
              </div>
            </div>
          </div>
          {user?.userType !== "5" && (
            <div className='cards-wrapper details-wrapper flex-column w-100' style={{ gap: 5 }}>
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className='card-header'>
                  <h4>{t("ContactInformation")}</h4>
                  <h6>{t("YouCanManageYourContactInformationOnAmakkn")}</h6>
                </div>
                <div className='card-body'>
                  <div className="row">
                    <div
                      className='col-12 col-md-6 mb-4 d-flex flex-column'
                      style={{ gap: "4px" }}>
                      <TextField
                        label={t("PhoneNumber")}
                        type='text'
                        size='small'
                        sx={{ width: "100%", '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={"0" + user.phone}
                      />
                      <Link
                        to={isAdmin ? "/admin/change-phone" : "/change-phone"}
                        style={{
                          fontSize: "13px",
                          textDecoration: "underline",
                          textAlign: "right",
                        }}>
                      {t('ChangePhoneNumber')}
                      </Link>
                    </div>
                    {currentPlan?.canAddNumber === '1' &&
                      (<div
                        className='col-12 col-md-6 mb-4 d-flex flex-column'
                        style={{ gap: "4px" }}>
                        <TextField
                          label={t("UnifiedContactNumber")}
                          type='text'
                          size='small'
                          sx={{ width: "100%", '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                          InputLabelProps={{ shrink: true }}
                          value={user.unifiedNumber}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              unifiedNumber: resolvePersianAndArabicNumbers(e.currentTarget.value),
                            });
                          }}
                        />
                      </div>)
                    }
                    <div className='col-12 col-md-6 mb-4'>
                      <FormControl
                        error={user.email && !validateEmail(user.email)}
                        className="col-12"
                      >
                        <TextField
                          label={t("Email")}
                          type='text'
                          InputLabelProps={{ shrink: true }}
                          size='small'
                          sx={{ width: "100%", '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              email: resolvePersianAndArabicNumbers(e.currentTarget.value),
                            });
                          }}
                          error={user.email && !validateEmail(user.email)}
                          value={user.email}
                        />
                        {user.email && !validateEmail(user.email) &&
                          <FormHelperText component={Box}>
                            <Alert severity="error" className="custom-alert">{t("PleaseEnterValidEmail")}</Alert>
                          </FormHelperText>
                        }
                      </FormControl>
                    </div>
              
                    {user.accountType === "2" && (
                      <div className='col-12 col-md-6 mb-4'>
                        <TextField
                          label={t("Website")}
                          type='text'
                          size='small'
                          disabled
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "100%", '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              companyWebsiteURL: resolvePersianAndArabicNumbers(
                                e.currentTarget.value
                              ),
                            });
                          }}
                          value={user.companyWebsiteURL}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className='card-header'>
                  <h4>{t("DescriptionOptional")}</h4>
                  <h6>{t("YouCanEnterDescriptionAboutYourBusinessOnAmakkn")}</h6>
                </div>
                <div className='card-body'>
                  <div className="row">
      
                    {user.accountType === "2" && (
                      <div className='col-12  mb-4'>
                        <TextField
                          label={t("AboutCompany")}
                          size='small'
                          sx={{ width: "100%" }}
                          multiline
                          rows={3}
                          InputLabelProps={{ shrink: true }}
                          type='text'
                          inputProps={{ maxLength: 500 }}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              aboutCompany: resolvePersianAndArabicNumbers(
                                e.currentTarget.value?.length >= 500 ? e.currentTarget.value?.substring(0, 500) : e.currentTarget.value
                              ),
                            });
                          }}
                          value={user.aboutCompany}
                        />
                        <h6 className='rightalign mt-2'>
                          {parseInt(user.aboutCompany.length) + "/500"}
                        </h6>
                      </div>
                    )}

                    {user.userType === "3" && (
                      <div className='col-12 mb-4'>
                        <TextField
                          label={t("About")}
                          size='small'
                          sx={{ width: "100%" }}
                          multiline
                          type='text'
                          rows={3}
                          inputProps={{ maxLength: 500 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              aboutMe: resolvePersianAndArabicNumbers(
                                e.currentTarget.value?.length >= 500 ? e.currentTarget.value?.substring(0, 500) : e.currentTarget.value
                              ),
                            });
                            
                          }}
                          value={user.aboutMe}
                        />
                        <h6 className='rightalign mt-2'>
                          {parseInt(user.aboutMe.length) + "/500"}
                        </h6>
                      </div>
                    )}
              
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        

        {user?.userType !== "5" && (
          <div className='custom-card' style={{ minHeight: 'auto' }}>
            <div className="card-header">
              <h4>{t("SocialMediaAccounts")}</h4>
              <h6>{t("YouCanEnterYourFacebookXAndInstagramAccounts")}</h6>
            </div>
            <div className="card-body">
              <div className="row" style={{ flexWrap: 'wrap' }}>
                <div className='col-12 col-md-4 col-lg-3 mb-4'>
                  <TextField
                    label={t("FacebookAccount")}
                    variant='outlined'
                    type='text'
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    autoComplete='off'
                    sx={{ width: "100%", '& .MuiInputBase-root':{direction:'ltr'}, '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={{ '& p':{textAlign:'right',direction:'ltr'} }} position='start'>
                          facebook.com/
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        fb: resolvePersianAndArabicNumbers(e.currentTarget.value),
                      });
                    }}
                    value={user.fb}
                  />
                </div>
                <div className='col-12 col-md-4 col-lg-3 mb-4'>
                  <TextField
                    label={t("XAccount")}
                    variant='outlined'
                    type='text'
                    size='small'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100%", '& .MuiInputBase-root':{direction:'ltr'}, '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={{ '& p':{textAlign:'right',direction:'ltr'} }} position={'start'}>x.com/</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        twitter: resolvePersianAndArabicNumbers(
                          e.currentTarget.value
                        ),
                      });
                    }}
                    value={user.twitter}
                  />
                </div>
        
                <div className='col-12 col-md-4 col-lg-3 mb-4'>
                  <TextField
                    label={t("InstagramAccount")}
                    variant='outlined'
                    type='text'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    autoComplete='off'
                    sx={{ width: "100%", '& .MuiInputBase-root':{direction:'ltr'}, '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={{ '& p':{textAlign:'right',direction:'ltr'} }} position='start'>
                          instagram.com/
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        insta: resolvePersianAndArabicNumbers(e.currentTarget.value),
                      });
                    }}
                    value={user.insta}
                  />
                </div>
              </div>
              
            </div>
          </div>
        )}

        {/* {user.userType === "3" && (
            <div className='col-12 mb-4'>
              <FormControl fullWidth>
                <InputLabel id='demo-multiple-name-label'>
                  نوع الترخيص
                </InputLabel>
                <Select
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  value={user.licenseType}
                  input={<OutlinedInput size="small"
                    sx={{ width: '100%' }} label='نوع الترخيص' />}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      licenseType: e.target.value,
                    });
                  }}>
                  <MenuItem value={"1"}>
                    ترخيص الهيئة العامة للعقار
                  </MenuItem>
                  <MenuItem value={"2"}>وثيقة عمل حر</MenuItem>
                </Select>
              </FormControl>
            </div>
          )} */}

        {vendor === "amakkn" && (
          <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
            <div className="card-header flex-column flex-sm-row" style={{ borderRadius: '16px' }}>
              <div>
                <h4>{t("DeleteAmakknAccount")}</h4>
                <h6>{t("YouCanDeleteYourAccountOnAmakkn")}</h6>
              </div>
              <div>
                <button
                  className="primary-btn delete-btn d-flex my-0 justify-content-center px-3"
                  onClick={() => history('/delete-my-account')}>
                  {t("DeleteMyAccount")}
                </button>
                {/* <button
                    className="primary-btn delete-btn d-flex  justify-content-center mt-2 px-3"
                    onClick={() => history('/delete-usage-information')}>
                    حذف معلومات الاستخدام
                  </button> */}
              </div>
            </div>
          </div>
        )}
        <LoadingButton
          classes='primary-btn px-5 m-auto'
          style={{ minWidth: '250px', justifyContent: 'center' }}
          handleClick={saveUserProfile}
          label={t("Save")}
          loading={loadingButton}
        />
       
        {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      </>
    )
  );
}

export default MyProfilePage;
