import i18next from "i18next";
import { t } from "i18next";
import Swal from "sweetalert2/dist/sweetalert2.js";

const baseUrl = window.location.origin.includes('amakkncompany.com') ? 'https://devsa2.amakkn.com' : process.env.REACT_APP_API_URL;

export async function handleResponse(response, props) {

  if (response.ok) {
    let res = response.json();
    if (response.url.includes('validateProperty')) {
      res.then((result) => {
        if (result.resCode !== 0 && result.resCode !== 3) {
          fetch(baseUrl + '/Feedback/addServerError/', {
            method: "POST",
            headers: { Accept: "application/json", "content-type": "application/json" },
            body: JSON.stringify({
              status: response.status,
              url: response.url,
              payload: JSON.stringify(props),
              error_message: JSON.stringify(result),
              language: i18next.language === 'ar' ? '0' : '1'
            })
          });
        }
      });
    }
    return res;
  }
  
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 500) {
    const errorMessage = await response.text();
    fetch(baseUrl + '/Feedback/addServerError/', {
      method: "POST",
      headers: { Accept: "application/json", "content-type": "application/json" },
      body: JSON.stringify({
        status: response.status,
        url: response.url,
        payload: JSON.stringify(props),
        error_message: errorMessage,
        language: i18next.language === 'ar' ? '0' : '1'
      })
    });
  }
  if (!response.ok && !response.url.includes('qr') && !response.url.includes('getRelatedProperties'))
    Swal.fire({
      title: t("AnErrorOccurred"),
      text: t("PleaseTryAgain"),
      icon: "error",
      confirmButtonText: t("OK2"),
    });
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  Swal.fire({
    title: t("AnErrorOccurred"),
    text: t("PleaseTryAgain"),
    icon: "error",
    confirmButtonText: t("OK2"),
  });
  console.error("API call failed. " + error);
  throw error;
}
