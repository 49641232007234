import React from 'react'
import { List, MapOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MapListToggle = ({ setMobileInfowindow, setResultMapToggle, resultMapToggle, setShowSearchBox, setHideNavbar }) => {
    const { t } = useTranslation();

    return (
        <button
            className='primary-btn py-2 px-4 no-opacity'
            style={{
                position: "fixed",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 9999
            }}
            onClick={() => {
                setMobileInfowindow({ showingInfoWindow: false });
                setResultMapToggle(!resultMapToggle);
                setShowSearchBox(true);
                setHideNavbar?.(false);
            }}>
            
            <span style={{ whiteSpace: "nowrap" }}>
                {resultMapToggle ? t("ShowList") : t("ShowMap")}
            </span>
            {resultMapToggle ? <List /> : <MapOutlined />}
        </button>
    );
}

export default MapListToggle;
