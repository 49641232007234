import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import AppLayout from "../../../components/layouts/app-layout";
import { Alert, Box, FormControl, FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, preventString, validatePhone } from "../../../helpers";
import { DEMO_VENDORS, countryCodes } from "../../../constants";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

function ChangeAgentPhoneForm(props) {
  const { t } = useTranslation();

  return (
    <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px 10% 70px' }}>
      <form onSubmit={props.onSubmit}>
        <div className='container'>
          <h4 className="page-heading">{t('ChangePhoneNumber')}</h4>
          <div className="row mx-0 mt-4 phone-wrapper">
            <div className={'col-5 mb-4 col-lg-3 pl-0 p-0'}>
              <div className='form-group'>
                <div>
                  <Select
                    id='countryCode'
                    name='countryCode'
                    onChange={props.onChange}
                    value={props.user.countryCode || ""}
                    className='sortDDL withBg h-35'>
                    {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
                  </Select>
                </div>
                {props.errors.countryCode && (
                  <div className='alert alert-danger'>
                    {props.errors.countryCode}
                  </div>
                )}
              </div>
            </div>
            <div className={'col-7 mb-4 col-lg-6 pr-0 p-0'}>
              <FormControl
                error={props.errors.newPhone && Boolean(props.errors.newPhone)}
                className="col-12"
              >
                <TextField
                  id='newPhone'
                  label={t("PhoneNumber")}
                  name='newPhone'
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '100%' }}
                  onChange={props.onChange}
                  value={props.user.newPhone}
                  error={props.errors.newPhone && Boolean(props.errors.newPhone)}
                />
                {props.errors.newPhone && Boolean(props.errors.newPhone) &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {props.errors.newPhone}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
        
          </div>
          <div className='col-12 p-0' >
            <LoadingButton
              classes="primary-btn d-flex justify-content-center py-2 px-5"
              label={t("Save")}
              type="submit"
              loading={props.loading}
            />
          </div>
          
        </div>
      </form>
    </div>
  );
}

ChangeAgentPhoneForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

function ChangeAgentPhone() {
  const { token, vendor } = useAuth();
  const [errors, setErrors] = useState({});
  const { agentId } = useParams();
  const history = useNavigate();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState({
    userId: vendor === 'amakkn' ? token : agentId,
    countryCode: "+966",
    newPhone: "",
    language: i18n.language === 'ar' ? '0' : '1',
  });

  useEffect(() => {
    if (vendor) {
      setUser({ ...user, userId: vendor === 'amakkn' ? token : agentId });
    }
  },// eslint-disable-next-line
    [vendor, token, agentId]);

  function formIsValid() {
    const _errors = {};

    if (!user.countryCode) _errors.countryCode = t("ChooseCountryCode");
    if (!user.newPhone) _errors.newPhone = t("EnterPhoneNumber");
    else if (!validatePhone(user.newPhone)) _errors.newPhone = t("PhoneNumberIsIncorrect");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);
  
  function handleSubmit(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      userApi.changeMobileNumberGenerateCode({ ...user, newPhone: user.newPhone }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          if (vendor === 'amakkn') {
            history("/otp-change-phone/" + user.countryCode + "/" + user.newPhone);
          } else {
            history("/admin/otp-change-phone/" + agentId + "/" + user.countryCode + "/" + user.newPhone);
          }
          if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
        } else {
          const _errors = {};
          _errors.newPhone = _userInfo.resStr;
          setErrors(_errors);
        }
      }).finally(() => setLoadingButton(false));
    }
  }

  function handleChange({ target }) {
    let value = target.value;
    if (target.name === 'newPhone') value = preventString(value);
    setUser({
      ...user,
      [target.name]: value,
    });
  }

  return (
    <AppLayout needAuth pageTitle={t('ChangePhoneNumber')} withoutNav={vendor !== 'amakkn'}>
      <div className={`${vendor !== 'amakkn' ? 'admin' : ''} page-wrapper page-width`}>
        <div className="row page-wrapper-without-top">
          <div className="col-12 col-lg-6 details-wrapper scrolled">
            <ChangeAgentPhoneForm
              errors={errors}
              user={user}
              onChange={handleChange}
              onSubmit={handleSubmit}
              loading={loadingButton}
            />
          </div>
          <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="img-container">
              <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
            </div>
          </div>
        </div>
        {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
      </div>
    </AppLayout>
  );
}

export default ChangeAgentPhone;
