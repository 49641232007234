import React, { Component } from "react";
// import NavBar from "../NavBar/NavBar";

import ChatList from "./ChatList/ChatList";
import ChatHistory from "./ChatHistory/ChatHistory";
import { ThemeColor } from "../../Config/Config";
import AppLayout from "../layouts/app-layout";
import { t } from "i18next";
// Chat master component
class CSChats extends Component {
  state = {
    mainVisible: false,
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    isRTL: Boolean(localStorage.getItem("rtl")),
  };

  // Load selected theme
  componentDidMount() {
    let classList = document.body.classList;
    if (classList.length === 0 && this.state.themeColor === ThemeColor.Dark) {
      document.body.classList.add("dark-skin");
    } else if (classList.length && this.state.themeColor === ThemeColor.Light) {
      document.body.classList.remove("dark-skin");
    }
    if (this.state.isRTL) document.body.setAttribute("dir", "rtl");
    else document.body.removeAttribute("dir");
  }

  handleChatClick = () => {
    this.setState({ mainVisible: true });
  };

  handleBackToList = () => {
    this.setState({ mainVisible: false });
  };

  render() {
    return (
      <AppLayout needAuth pageTitle={t("ContactTechnicalSupport")}>
        <div style={{ height: 'calc(100vh - 70px)', marginTop: '70px', position: 'fixed', width: '100%' }}>
          <div className='chats-tab-open h-100'>
            <div className="main-layout h-100" style={{ padding:'0 calc(var(--px-meduim) / 2)' }}>

              <ChatList handleChatClick={this.handleChatClick} />

              <ChatHistory
                mainVisible={this.state.mainVisible}
                BackToListClicked={this.handleBackToList}
              />
              <div className='backdrop'></div>
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
}
export default CSChats;
