import TextField from "@mui/material/TextField";
import React, { useState} from "react";
import { resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";
import T04WhyUS from "../pro/template-04/why-us";

const WhyUsSection = (props) => {
  const [Data, setData] = useState(props.data);
  const { templateId } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={`whyus-section ${props.isEdit ? 'admin' : ''}`}>
      <div className='row'>
        <div className='col-12'>
          {props.isEdit ? (
            <>
              <TextField
                label={t("Tagline")}
                variant='outlined'
                fullWidth
                multiline
                rows={3}
                inputProps={{ maxLength: 500 }}
                InputLabelProps={{ shrink: true }}
                className='mt-4 text-start'
                value={Data.desc}
                onChange={(v) => {
                  setData({
                    ...Data, desc: resolvePersianAndArabicNumbers(
                      v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                    )
                  });
                }}
              />
              <h6 className='rightalign mt-2 mb-0'>
                {Data.desc
                  ? parseInt(Data.desc.length) + "/500"
                  : "0/500"}
              </h6>
              <div className="row mt-5 px-2 px-sm-5 pb-2 justify-content-center">
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                 
                  <TextField
                    label={`${t("TitleOfTheFeature")} 1`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={Data.title1}
                    onChange={(v) => {
                      setData({ ...Data, title1: resolvePersianAndArabicNumbers(v.currentTarget.value) });
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 1`}
                    variant='outlined'
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                    className='mt-4 multiline'
                    value={Data.content1}
                    onChange={(v) => {
                      setData({
                        ...Data, content1: resolvePersianAndArabicNumbers(
                          v.currentTarget.value?.length >= 200 ? v.currentTarget.value?.substring(0, 200) : v.currentTarget.value
                        )
                      });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content1
                      ? parseInt(Data.content1.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                  <TextField
                    label={`${t("TitleOfTheFeature")} 2`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={Data.title2}
                    onChange={(v) => {
                      setData({ ...Data, title2: resolvePersianAndArabicNumbers(v.currentTarget.value) });
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 2`}
                    variant='outlined'
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                    className='mt-4 multiline'
                    value={Data.content2}
                    onChange={(v) => {
                      setData({
                        ...Data, content2: resolvePersianAndArabicNumbers(
                          v.currentTarget.value?.length >= 200 ? v.currentTarget.value?.substring(0, 200) : v.currentTarget.value
                        )
                      });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content2
                      ? parseInt(Data.content2.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                  
                  <TextField
                    label={`${t("TitleOfTheFeature")} 3`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={Data.title3}
                    onChange={(v) => {
                      setData({ ...Data, title3: resolvePersianAndArabicNumbers(v.currentTarget.value) });
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 3`}
                    variant='outlined'
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={4}
                    className='mt-4 multiline'
                    value={Data.content3}
                    onChange={(v) => {
                      setData({
                        ...Data, content3: resolvePersianAndArabicNumbers(
                          v.currentTarget.value?.length >= 200 ? v.currentTarget.value?.substring(0, 200) : v.currentTarget.value
                        )
                      });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content3
                      ? parseInt(Data.content3.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
              </div>
              <div className='mt-3 '>
                <LoadingButton
                  label={t("SaveChanges")}
                  classes="primary-btn px-3 m-auto"
                  loading={props.loading}
                  handleClick={() => props.saveDetails({ data: Data })}
                />
              </div>
            </>
          ) : CLASSIC_TEMPLATES.includes(+templateId) ? <T04WhyUS data={Data} /> : (
            <> {
              Data.title1?.trim() === "" && Data.title2?.trim() === "" && Data.title3?.trim() === "" &&
                Data.content1?.trim() === "" && Data.content2?.trim() === "" && Data.content3?.trim() === "" ?
                <></> : (
                  <>
                    <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
                      <span className="section-prefix">{t("OurAdvantages")}</span>
                      <h2 className='sec-title'>{t("WhatMakesUsDifferentFromOthers")}</h2>
                      <p className="sec-desc">{Data.desc}</p>
                    </div>
                    <div className={`row features-row mt-5`}>
                      {(Data.title1?.trim() !== "" || Data.content1?.trim() !== "") && (
                        <div className="feature col-12 col-lg-4 col-xl-3">
                          <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.01' : '1'}</div>
                          <h5 className="feature-title">{Data.title1}</h5>
                          <p className="feature-desc">{Data.content1}</p>
                        </div>
                      )}
                      {(Data.title2?.trim() !== "" || Data.content2?.trim() !== "") && (
                        <div className="feature col-12 col-lg-4 col-xl-3">
                          <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.02' : '2'}</div>
                          <h5 className="feature-title">{Data.title2}</h5>
                          <p className="feature-desc">{Data.content2}</p>
                        </div>
                      )}
                      {(Data.title3?.trim() !== "" || Data.content3?.trim() !== "") && (
                        <div className="feature col-12 col-lg-4 col-xl-3">
                          <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.03' : '3'}</div>
                          <h5 className="feature-title">{Data.title3}</h5>
                          <p className="feature-desc">{Data.content3}</p>
                        </div>
                      )}
                    </div>
                  </>
                )
            }
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default WhyUsSection;
