import React, { useState, useEffect } from "react";
import { getVerifiedCompaniesNew } from "../../api/propertyApi";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AppLayout from "../../components/layouts/app-layout";
import { Box, Pagination } from "@mui/material";
import NoData from "../../components/global-components/no-data";
import LoadingData from "../../components/global-components/loading-data";
import { Buildings, User } from "../../constants/icons";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const VerifiedCompanies = (props) => {
  const { token } = useAuth();
  const [Companies, setCompanies] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [page, setPage] = React.useState(1);
  const pageSize = 16;
  const [totalCount, setTotalCount] = React.useState(0);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    if (token) {
      setLoadingData(true);
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      getVerifiedCompaniesNew({
        page: page,
        pageSize: pageSize + "",
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_response) => {
        if (_response.resCode === 0) {
          setLoadingData(false);
          setCompanies(_response.response.companies);
          setTotalCount(_response.response.totalCount);
        }
        setLoadingData(false);
      }).catch(err => setLoadingData(false));
    }
  }, [token, page, i18n]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <AppLayout needAuth pageTitle="الشركات الموثقة">
      {token && (
        <div className='page-width pd-top-90 mg-bottom-100'>
          <Box
            sx={{
              marginBottom: "40px",
              textAlign: "center !important",
              fontSize: "28px",
              fontWeight: 600,
              color: "#000",
            }}>
            الشركات الموثّقة
            {totalCount > 0 && (
               <Box
               sx={{
                textAlign: "center !important",
                 fontSize: "16px",
                 fontWeight: 400,
                 color: "#000",
               }}>
               يوجد {totalCount} شركة موثّقة 
             </Box>
            )}
          </Box>
          {loadingData ? <LoadingData /> : (
            Companies.length > 0 ? (
              <div className="d-flex flex-column justify-content-start align-items-center mb-5" style={{gap:'40px'}}>
                                  <div className='row company-boxes justify-content-end'>

                {Companies.map((item, i) => (
                  <div key={i} className='col-12 col-md-6 col-lg-4 col-xl-3 mb-4'>
                    <div className='company-box'>
                      <Link
                        className="d-block h-100 position-relative"
                        to={`/company-profile/${item.userId}`}>
                        <>
                          <div className="company-logo">
                            {item.avatar ? (
                              <Avatar alt={item.name} variant="circular" src={item.avatar} />
                            ) : (
                              <Avatar variant="circular">{item.name.substring(0, 1)}</Avatar>
                            )}
                          </div>
                          <h4 className='company-name'>{item.name}</h4>
                          <div className="company-statistics">
                            <span>
                             الإعلانات العقاريّة:
                                {item.propertyCount}
                                <Buildings/>
                            </span>
                            <span>
                              {t("Agents")}:
                                {item.agentCount}
                                <User/>
                            </span>
                          </div>
                          </>
                      </Link>
                    </div>
                  </div>
                ))}
                  </div>
                <Pagination sx={{ '& .MuiPagination-ul': { flexDirection: 'row-reverse' } }} count={parseInt(Math.ceil(totalCount/pageSize))} page={page} onChange={handlePageChange}  color="primary" />
              </div>
            ) :
              <NoData msg={"لا يوجد شركات موثقة"} />
          )}
        </div>
      )}
    </AppLayout>
  );
};

export default VerifiedCompanies;
