import React, { useEffect, useState } from "react";
import { InfoWindow, Map, Marker } from "google-maps-react";
import { Rating, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import { mStyles } from "../helpers";
import { useTranslation } from "react-i18next";
const mapStyles = {
  //  width: "100%",
  height: "400px",
  position: "relative",
  scrolling: "no",
};


const publicUrl = process.env.REACT_APP_URL + "/";

let googleMap = React.createRef();

function _mapLoaded(mapProps, map) {
  map.setOptions({
    styles: mStyles,
  });
  googleMap = map;
}

const MapContainer = (props) => {

  const [lat] = useState(props.lat);
  const [lng] = useState(props.lng);
  const height = props.height || 0;
  const zoom = props.zoom ? props.zoom : 15;
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [markers, setMarkers] = useState([]);
  const [infowindow, setInfowindow] = useState({
    activeMarker: {},
    visible: false,
    data: {},
    mobile: false
  });

  let temp = [];
  const { t } = useTranslation();

  const drawExploreNeighbourhood = (map, type) => {
    let service = new window.google.maps.places.PlacesService(map);
    let pyrmont = new window.google.maps.LatLng(lat, lng);
    // let infowindow = new window.google.maps.InfoWindow();
    let request = {
      location: pyrmont,
      radius: "1500",
      type: [type],
    };
    service.nearbySearch(request, callback);
  
    function callback(results, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        temp = [];
        for (let i = 0; i < results.length; i++) {
          // if()
          temp.push(createMarker(results[i]));
        }
        setMarkers(temp);
      }
    }
  
    function createMarker(place) {
      if (!place.geometry || !place.geometry.location) return;
      return {
        title: place.name,
        icon: publicUrl + "assets/img/icons/" + type + ".png",
        position: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
        data: place
      };
    }
  }
  // const clearMarkers=()=> {
  //   // for (let i = 0; i < markers.length; i++) {
  //   //   markers[i].setMap(null);
  //   // }
  //   setMarkers([]);
  // }

  const handleMarkerMouseout = () => {
    setInfowindow({
      activeMarker: null,
      visible: false,
      data: {}
    });
  };
  const handleMarkerMouseover = (props, marker, e) => {
    if (props.data !== infowindow.data) {
      setInfowindow({
        activeMarker: marker,
        visible: true,
        data: props.data
      });
    }

  };
  const handleMarkerClick = (props, marker, e) => {
    if (props.dialog && !isLargeScreen)
      setInfowindow({
        activeMarker: null,
        visible: false,
        data: props.data,
        mobile: true
      });
  };

  
  useEffect(() => {
    if (props.ExploreNeighbourhood) {
      if (googleMap) {
        //  if (markers.length > 0) clearMarkers();
        if (props.ExploreNeighbourhood.hospital)
          drawExploreNeighbourhood(googleMap, "hospital");
        if (props.ExploreNeighbourhood.mosque)
          drawExploreNeighbourhood(googleMap, "mosque");
        if (props.ExploreNeighbourhood.school)
          drawExploreNeighbourhood(googleMap, "school");
        if (props.ExploreNeighbourhood.shopping_mall)
          drawExploreNeighbourhood(googleMap, "shopping_mall");
        // if (props.ExploreNeighbourhood.bus_station)
        //   drawExploreNeighbourhood(googleMap, "bus_station");
        // if (props.ExploreNeighbourhood.train_station)
        //   drawExploreNeighbourhood(googleMap, "train_station");
      }
    }
  },// eslint-disable-next-line
    [props.ExploreNeighbourhood]);
  
  const markerSVG = {
    svg: [
      '<svg stroke-width="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">',
      '<path fill="{fillColor}" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>',
      "</svg>",
    ].join(""),
    anchor: { x: 19, y: 50.7 },
    size: { width: 40, height: 50 },
  };
  
  let getMarkerSVG = () =>
    `data:image/svg+xml,${encodeURIComponent(
      markerSVG.svg.replace("{fillColor}", window.getComputedStyle(document.body, null).getPropertyValue("--main-color-one"))
    )}`;
  
  return (
    <>
      <Map
        google={window.google}
        zoom={zoom}
        scrollwheel={false}
        streetViewControl={false}
        style={{ ...mapStyles, height: height || mapStyles.height }}
        minZoom={12}
        maxZoom={22}
        onMouseout={handleMarkerMouseout}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
        initialCenter={{
          lat: lat,
          lng: lng,
        }}
      // className={props.withOverlay ? "map2" : ""}
      >
        <Marker
          position={{ lat: lat, lng: lng, }}
          name="Current position"
          // icon={{
          //   url: publicUrl + "assets/img/icons/BluePin2.png",
          // }}
          icon={{
            url: getMarkerSVG(),
            anchor: markerSVG.anchor,
            size: markerSVG.size,
            scaledSize: markerSVG.size,
          }}
        />
      
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            name={marker.title}
            data={marker.data}
            icon={{
              url: marker.icon
            }}
            dialog={props.dialog}
            onMouseover={handleMarkerMouseover}
            onMouseout={handleMarkerMouseout}
            onClick={handleMarkerClick}
          />
        ))}


        {props.ExploreNeighbourhood && (
          <InfoWindow
            marker={infowindow.activeMarker}
            visible={infowindow.visible}
            disableAutoPan={true}
            maxWidth={200}
            zIndex={null}
            boxClass={"poi-box"}
            boxStyle={{
              background: "#fff",
              opacity: "1",
              "box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.13)",
              "text-align": "center",
              "border-radius": "10px",
            }}
            infoBoxClearance={new window.google.maps.Size(1, 1)}
            pane={"floatPane"}
            enableEventPropagation={false}>
            <div>
              {infowindow.data?.photos?.length > 0 && (
                <div
                  style={{ width: '100%', height: '140px' }}
                >

                  <img src={infowindow.data.photos[0].getUrl()}
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    alt={infowindow.data.name} />
                </div>

              )}
              <div className="py-2 px-3">
                <h6>   {infowindow.data.name}</h6>
                {infowindow.data.rating && (
                  <>
                    <div className="d-flex align-items-center">
                      {/* <Rating size="small" defaultValue={infowindow.data.rating} value={infowindow.data.rating} precision={0.5} readOnly /> */}
                      <span style={{ position: 'relative', top: 1 }}>{infowindow.data.rating} <span style={{ fontSize: 12 }}>({`${infowindow.data.user_ratings_total} ${t("Reviews")}`})</span></span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </Map>
      {props.ExploreNeighbourhood && infowindow.mobile && (
        <div className='mobile-info-window version2'>
          <Close
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              left: "6px",
            }}
            onClick={() => {
              setInfowindow({
                activeMarker: null,
                visible: false,
                data: {},
                mobile: false
              });
            }}
          />
          <div>
            {infowindow.data?.photos?.length > 0 && (
              <div style={{ width: '100%', height: '150px' }}>
                <img src={infowindow.data.photos[0].getUrl()}
                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  alt={infowindow.data.name} />
              </div>
            )}
            <div className="py-2 px-3">
              <h6>{infowindow.data.name}</h6>
              {infowindow.data.rating && (
                <div className="d-flex align-items-center">
                  <Rating size="small" defaultValue={infowindow.data.rating} precision={0.5} readOnly />
                  <span style={{ position: 'relative', top: 1 }}>{infowindow.data.rating} <span style={{ fontSize: 12 }}>({`${infowindow.data.user_ratings_total} ${t("Reviews")}`})</span></span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MapContainer;
