import { Paper, Table, TableContainer,TableRow,TableCell, TableHead, TableBody, TableFooter } from "@mui/material";
import React from "react";
import { GreenCheck, RedClose } from "../constants/icons";
import { plansFeatures } from "../constants";
import { useTranslation } from "react-i18next";

const PlansTable = ({ data }) => {
    const { t } = useTranslation();

    const features = [
        t("FreeWebsite"),
        t("WebsiteWithIndependentDomain"),
        t("RealEstateADsMap"),
        t("ProjectsAndInterests"),
        t("CRM"),
        t("Blog"),
        t("XAccountAppearance"),
        t("Agents"),
        t("3Templates")
    ];
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }} >
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                            "& th": {
                                fontSize: "17px",
                                fontWeight: 600,
                                textAlign: 'center'
                            }
                        }}
                    >
                        <TableCell sx={{ minWidth: 160 }}>{t("Features")}</TableCell>
                        {data?.map(p => ['5', '9', '11'].includes(p.id) && <TableCell sx={{ minWidth: 180 }} key={p.name}>{p.name}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("NumberOfPublishedRealEstateADsAllowedToBeAdded")}</TableCell>
                        <TableCell>{data[4]?.allowedPublishedProperties}</TableCell>
                        <TableCell>{data[8]?.allowedPublishedProperties}</TableCell>
                        <TableCell>{data[10]?.allowedPublishedProperties}</TableCell>
                    </TableRow>

                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("NumberOfAgentsAllowedToBeAdded")}</TableCell>
                        <TableCell>{data[4]?.allowedAgents}</TableCell>
                        <TableCell>{data[8]?.allowedAgents}</TableCell>
                        <TableCell>{data[10]?.allowedAgents}</TableCell>
                    </TableRow>

                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("NumberOfRealEstateADsAllowedToBePromoted")}</TableCell>
                        <TableCell>{data[4]?.permanentUpgradeToNumberOfAds}</TableCell>
                        <TableCell>{data[8]?.permanentUpgradeToNumberOfAds}</TableCell>
                        <TableCell>{data[10]?.permanentUpgradeToNumberOfAds}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("TechnicalSupportPriority")}</TableCell>
                        <TableCell>{data[4]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("AbilityToAddTheCompanyLogoToTheADImages")}</TableCell>
                        <TableCell>{data[4]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("AbilityToAddTheUnifiedContactNumber")} </TableCell>
                        <TableCell>{data[4]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("AbilityToAddTheLocationToTheMapOnTheCompanyPage")}</TableCell>
                        <TableCell>{data[4]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("AbilityToAddVideosToTheRealEstateAD")}</TableCell>
                        <TableCell>{data[4]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("AbilityToAddAdditionalADsFor20SARPerAD")}</TableCell>
                        <TableCell>{data[4]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">{t("PrintableAndShareableImageOfRealEstateListingDetails")}</TableCell>
                        <TableCell>{data[4]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                   
                    {features?.map((row, i) => {
                        return (
                            <TableRow
                                key={i}
                                sx={{
                                    '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                                    '& td:last-child, & th:last-child': { borderLeft: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {i === 8 ? t("TemplatesCount") : row}
                                </TableCell>
                                <TableCell><RedClose /></TableCell>
                                <TableCell>{i === 8 ? 3 : plansFeatures[7]?.[i].available === "1" ? <GreenCheck green /> : <RedClose />}</TableCell>
                                <TableCell>{i === 8 ? 10 : plansFeatures[9]?.[i].available === "1" ? <GreenCheck green /> : <RedClose />}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow
                        sx={{
                            '& td, & th': {
                                paddingTop: '20px',
                                paddingBottom: '35px',
                                borderBottom: 0,
                                textAlign: 'center',
                                borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
                            },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell></TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default PlansTable;
