import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Alert,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { toast } from "react-toastify";
import {
  getBargain,
  saveBargain,
  getCustomers,
  getBargainProperties,
  getCustomer,
  getSimiliarCustomers,
} from "../../../api/userApi";
import { useEffect } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, preventString, renderInterestingType, renderPropertyType, trimPhone } from "../../../helpers";
import { CITIES, DEMO_VENDORS, DISTRICTS, REGIONS } from "../../../constants";
import NoData from "../../../components/global-components/no-data";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import LoadingData from "../../../components/global-components/loading-data";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import { Close, Check } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      fontSize: 15,
      fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
      backgroundColor: theme.palette.action.hover,
  }
}));

const pageSize = 8;

const ChooseBuyerDialog = ({ open, onClose, deal, setDeal }) => {
  const { token } = useAuth();
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (open) {
      setLoadingData(true);
      getCustomers({
        userId: token,
        page: 1,
        pageSize: pageSize,
        search,
        type: 'باحث,وسيط',
        interestingType: '',
        propertyType: [],
        priceFrom: '',
        area: '',
        city: '',
        neighbourhood: '',
        priceTo: '',
        owner: undefined,
        updatedAt: ''
      }).then((res) => {
        if (res.resCode === 0) {
          setData(res.response.array);
          setTotalCount(res.response.totalCount);
        } else {
          toast.error(res.resStr);
        }
      }).finally(() => setLoadingData(false));
    }
  }, [open, page, search, token]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      className="custom-dialog model"
      onClose={onClose}>
      <DialogTitle>
        <div className="dialog-head">
          <div>
            <h2>{t("BuyerTenantSelection")}</h2>
          </div>
          <div className="dialog-actions">
            <span onClick={onClose}>
              <Close />
            </span>
          </div>
        </div>

      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: '50px' }}>
          <div
            style={{ width: "100%", display: "flex", gap: 15, alignItems: 'center', marginBottom: 15 }}>
            <TextField
              id='standard-search'
              style={{ width: "100%", background: '#fff' }}
              placeholder={t("SearchByNameOrPhoneNumber")}
              type='search'
              size='small'
              value={search}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
            />
             
          </div>
          <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">{t("Name")}</TableCell>
                  <TableCell align="right">{t("PhoneNumber")}</TableCell>
                  <TableCell align="right">{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingData ? <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                ><TableCell colSpan={3}><LoadingData /></TableCell></TableRow> :
                  data?.length > 0 ? data.map((row) => (
                    <TableRow>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right"><a href={`tel:${trimPhone(row.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(row.phone)}</a></TableCell>
                      <TableCell align="right">
                        <button
                          style={{ height: '35px' }}
                          className={`btn py-0 px-3 ${row?.id === deal?.buyerId ? 'primary-btn' : 'outlined-btn'}`} onClick={() => {
                            setDeal({ ...deal, buyer: row, buyerId: row?.id });
                            onClose();
                          }}>
                          {row?.id === deal?.buyerId ? <Check /> : t("Check1")}
                        </button>
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    ><TableCell colSpan={3}><NoData msg={t("NoBuyersTenantsFound")} /></TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {totalCount > pageSize && (
            <Pagination
              sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
              count={parseInt(Math.ceil(totalCount / pageSize))}
              page={page}
              onChange={handlePageChange}
              color="primary" />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
  
}

const ChooseSellerDialog = ({ open, onClose, deal, setDeal, label, forRent }) => {
  const { token } = useAuth();
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(true);
  const [noMatchedCustomers, setNoMatchedCustomers] = useState(false);
  const { customerId } = useParams();

  useEffect(() => {
    if (open) {
      setLoadingData(true);
      setNoMatchedCustomers(false);
      getSimiliarCustomers({
        page: 1,
        pageSize: 100000,
        search: search,
        userId: token,
        type: 'مالك,وسيط'
      }).then((res) => {
        if (res.resCode === 0) {
          let items = res.response.array?.filter(i => +i.id === +customerId)?.[0]?.interests || [];
          let temp = [];
          for (let i of items) {
            for (let j of i.similar) {
              if (!temp.map(o => o.id).includes(j.id))
                temp.push(j);
            }
          }
          setData(temp);
          setTotalCount(0);
          if (temp.length === 0 || items.length === 0) {
            setNoMatchedCustomers(true);
            getCustomers({
              userId: token,
              page: 1,
              pageSize: pageSize,
              search,
              type: 'مالك,وسيط',
              interestingType: '',
              propertyType: [],
              priceFrom: '',
              area: '',
              city: '',
              neighbourhood: '',
              priceTo: '',
              owner: undefined,
              updatedAt: ''
            }).then((res) => {
              if (res.resCode === 0) {
                setData(res.response.array);
                setTotalCount(res.response.totalCount);
              } else {
                toast.error(res.resStr);
              }
            }).finally(() => setLoadingData(false));
          } else {
            setLoadingData(false)
          }
        } else {
          toast.error(res.resStr);
          setLoadingData(false)
        }
      }).catch(() => setLoadingData(false));
   
    }
  }, // eslint-disable-next-line
    [open, page, search, token]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      className="custom-dialog model"
      onClose={onClose}>
      <DialogTitle>
        <div className="dialog-head">
          <div>
            <h2> {label || t("SellerLessorSelection")}</h2>
          </div>
          <div className="dialog-actions">
            <span onClick={onClose}>
              <Close />
            </span>
          </div>
        </div>

      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 15, marginBottom: '50px' }}>
          <TextField
            id='standard-search'
            style={{ width: "100%", background: '#fff' }}
            placeholder={t("SearchByNameOrPhoneNumber")}
            type='search'
            size='small'
            value={search}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
          />
          {noMatchedCustomers && (
            <Alert severity="warning" className="w-100"
              style={{
                margin: '0 auto 20px',
              }}
              sx={{
                "& .MuiAlert-icon": {
                  marginInlineStart: 0,
                  marginInlineEnd: '5px'
                },
                '& .MuiAlert-message': {
                  padding: '7px 0 2px'
                }
              }}>
              {forRent ? t("NoLessorsWereFoundMatchingTheSelectedInterest") : t("NoSellersMatchingTheSelectdInterestWereFound")} <br />
              {forRent ? t("BelowYouWillFindListOfAllLessors") : t("BelowYouWillFindListOfAllSellers")}
            </Alert>
          )}
             
          {/* <button className="btn primary-btn px-2 d-flex justify-content-center" style={{width:'200px', height:'40px'}} onClick={()=>setForAllCustomers(!forAllCustomers)}>{forAllCustomers ? 'عرض العملاء المتطابقين فقط' : 'عرض جميع العملاء'}</button> */}
          <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="right">{t("Name")}</TableCell>
                  <TableCell align="right">{t("PhoneNumber")}</TableCell>
                  <TableCell align="right">{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingData ? <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                ><TableCell colSpan={3}><LoadingData /></TableCell></TableRow> :
                  data?.length > 0 ? data.map((row) => (
                    <TableRow>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right"><a href={`tel:${trimPhone(row.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(row.phone)}</a></TableCell>
                      <TableCell align="right">
                        <button
                          style={{ height: '35px' }}
                          className={`btn py-0 px-3 ${row?.id === deal?.sellerId ? 'primary-btn' : 'outlined-btn'}`} onClick={() => {
                            setDeal({ ...deal, seller: row, sellerId: row?.id });
                            onClose();
                          }}>
                          {row?.id === deal?.sellerId ? <Check /> : t("Check1")}
                        </button>
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    ><TableCell colSpan={3}><NoData msg={forRent ? t("NoLessorsFound") : t("NoSellersFound")} /></TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          {totalCount > pageSize && (
            <Pagination
              sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
              count={parseInt(Math.ceil(totalCount / pageSize))}
              page={page}
              onChange={handlePageChange}
              color="primary" />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
  
}

const AddOrEditDeal = () => {
  const { token, vendor } = useAuth();
  const { id, customerId, interestId, propertyId } = useParams();
  const isNew = window.location.href.indexOf("add") > -1;
  const [deal, setDeal] = useState({
    buyerId: customerId || "",
    buyer: undefined,
    interestId: interestId || '',
    value: "",
    commission: "",
    region: "",
    city: "",
    neighbourhoods: [],
    budget_start: "",
    budget_end: "",
    property: "",
    interest: undefined,
    sellerId: '',
    seller: undefined
  });
  const [relatedTo, setRelatedTo] = useState('');
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = React.useState({});
  const history = useNavigate();
  const [loadingProperties, setLoadingProperties] = React.useState(false);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const { t, i18n } = useTranslation();
  const [buyersDialog, setBuyersDialog] = useState(false);
  const [sellersDialog, setSellersDialog] = useState(false);

  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR: city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR:district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);

  useEffect(() => {
    if (customerId && interestId)
      getCustomer({
        "vendorName": vendor,
        "language": i18n.language === 'ar' ? '0' : '1',
        "customerId": customerId
      }).then((res) => {
        if (res.resCode === 0) {
          setDeal({
            ...deal,
            interest: res.response?.interests?.filter(i => +i.id === +interestId)?.[0] || undefined,
            interestId,
            buyerId: customerId,
            property: propertyId || undefined
          });
        } else {
          toast.error(res.resStr);
        }
      });
  }, // eslint-disable-next-line
    [interestId, customerId]);
  
  useEffect(
    () => {
      if (id)
        getData();
      else
        setDeal({
          buyerId: "",
          buyer: undefined,
          interestId: "",
          interest: undefined,
          sellerId: '',
          value: "",
          commission: "",
          region: "",
          city: "",
          neighbourhoods: [],
          budget_start: "",
          budget_end: "",
          property: ""
        });
    }, // eslint-disable-next-line
    [id, vendor]
  );

  const getData = () => {
    getBargain({
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
      bargainId: id
    }).then((res) => {
      if (res.resCode === 0) {
        setDeal({
          ...res.response,
          buyerId: res.response.buyer,
          buyer: res.response.buyerObject,
          interestId: '',
          value: res.response?.value?.endsWith('.000') ? parseInt(res.response.value) : res.response.value,
          commission: res.response?.commission?.endsWith('.000') ? parseInt(res.response.commission) : res.response.commission,
          property: res.response.propertyId,
          interest: {
            region: res.response.region,
            city: res.response.city,
            neighbourhoods: res.response.neighbourhoods,
            priceFrom: res.response.priceFrom,
            priceTo: res.response.priceTo,
            areaFrom: res.response.areaFrom,
            areaTo: res.response.areaTo,
            interestingType: res.response.interestingType,
            propertyType: res.response.propertyType,
          },
          sellerId: res.response.seller,
          seller: res.response.sellerObject,
          budget_start: '',
          budget_end: ''
        });
        setSelectedProperty(+res.response.propertyId)
      } else {
        toast.error(res.resStr);
      }
    });
  }

  function formIsValid() {
    const _errors = {};
    if (!deal.buyerId) _errors.buyerId = t("PleaseSelectBuyerTenant");

    if (!deal.value) _errors.value = t("PleaseEnterTheDealValue");

    if (!deal.commission) _errors.commission = t("PleaseEnterTheCommission");
      
    if (!deal.interestId && isNew) _errors.interestId = t("PleaseSelectInterest");
    if (relatedTo === "property" && !deal.property) _errors.property = t("PleaseSelectRealEstateAD");
    
    if (relatedTo === "seller" && !deal.sellerId) _errors.sellerId = t("PleaseSelectSeller");
    
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);
  
  function getFormatedPrice(price) {
    if (price) {
      let value = price?.toString()?.replace(/[,]+/g, "");
      return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    } else
      return price;
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (deal.interestId) {
      setLoadingProperties(true);
      getBargainProperties({
        userId: token,
        page: page,
        pageSize: pageSize,
        interestId: deal.interestId,
        language: i18n.language === 'ar' ? '0' : '1'
      })
        .then((property) => {
          if (property.resCode === 0) {
            setProperties(property.response.propertyArray);
            setTotalCount(property.response.totalCount);
            if (isNew) setSelectedProperty();
          } else {
            toast.error(property.resStr);
          }
        }).finally(() => setLoadingProperties(false));
    }
  }, // eslint-disable-next-line
    [token, isNew, deal.interestId, page]);
  

  const handleSave = () => {
    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);

      saveBargain({
        language: i18n.language === 'ar' ? '0' : '1',
        userId: token,
        bargainId: isNew ? undefined : id,
        status: isNew ? '0' : deal.status,
        buyerId: deal.buyerId,
        sellerId: deal.sellerId || undefined,
        value: +deal.value?.toString()?.replace(/,/g, ''),
        commission: +deal.commission?.toString()?.replace(/,/g, ''),
        budget_start: deal.budget_start,
        budget_end: deal.budget_end,
        propertyId: deal.property || undefined,
        interest: { ...deal.interest, neighbourhoods: deal.interest.neighbourhood || deal.neighbourhoods, region: deal.interest.area || deal.region }
      }).then(res => {
        if (res.resCode === 0) {
          toast.success(isNew ? t("DealAddedSuccessfully") : t("DealEditedSuccessfully"));
          history('/admin/deals');
        } else {
          toast.error(res.resStr);
        }
      }).finally(() => setLoadingButton(false));
    }
  }

  return (
    <div>
      <SubHeader OKElement={<></>} />
      <ChooseBuyerDialog
        deal={deal}
        setDeal={setDeal}
        open={buyersDialog}
        onClose={() => setBuyersDialog(false)}
      />

      <ChooseSellerDialog
        deal={deal}
        setDeal={setDeal}
        label={deal?.interest?.interestingType === "استئجار" ? t("SelectLessor") : t("SelectSeller")}
        forRent={deal?.interest?.interestingType === "استئجار"}
        open={sellersDialog}
        onClose={() => setSellersDialog(false)}
      />

      {isNew && !customerId && (
        <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
          <div className='card-header'>
            <h4>{t("BuyerTenantSelection")}</h4>
            <h6>{t("YouMustSelectTheBuyerTenantAssociatedWithDeal")}</h6>
          </div>
          <div className='card-body'>
            <div className="col-12 col-md-6 col-lg-4">
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(errors.buyerId)}>
                <TextField
                  label={t("SelectBuyerTenant")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <button
                        position='end'
                        className="btn primary-btn py-0 ml-1"
                        style={{ fontSize: '13px', height: '30px' }}
                        onClick={() => setBuyersDialog(true)}
                      >
                        {t("Check1")}</button>
                    ),
                  }}
                  size="small"
                  value={deal.buyer?.name}
                />
                {errors.buyerId && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.buyerId}
                    </Alert>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            {deal.buyer && (
              <div className="col-12 mt-4">
                <h4 className="mb-3">{t("SelectInterest")}</h4>
                {deal?.buyer?.interests && deal?.buyer?.interests.length > 0 ? (
                  <TableContainer className="table-container responsive-table"
                    component={Paper}>
                    <Table sx={{ minWidth: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align='right'>{t("TypeOfInterest")}</StyledTableCell>
                          <StyledTableCell align='right'>{t("PropertyType")}</StyledTableCell>
                          <StyledTableCell align='right'>{t("PriceRange")} ({t("SAR")})</StyledTableCell>
                          <StyledTableCell align='right'>{t("Area")} {t("m2")}</StyledTableCell>
                          <StyledTableCell align='right'>{t("Region")}</StyledTableCell>
                          <StyledTableCell align='right'>{t("City")}</StyledTableCell>
                          <StyledTableCell align='right'>{t("Neighborhoods")}</StyledTableCell>
                          <StyledTableCell align='right'>{t("Actions")}</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {deal?.buyer?.interests.map((item) => (
                          <StyledTableRow key={item?.id}>
                            <StyledTableCell align='right'>{renderInterestingType(item?.interestingType)}</StyledTableCell>
                            <StyledTableCell align='right'>{renderPropertyType(item?.propertyType)}</StyledTableCell>
                            <StyledTableCell align='right'>
                              {Intl.NumberFormat("en").format(item?.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item?.priceTo)}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {item?.interestingType === "بيع" || item?.interestingType === "تأجير" ? (item?.areaFrom ? Intl.NumberFormat("en").format(item?.areaFrom) : t("NotAvailable")) :
                                (item?.areaFrom ? Intl.NumberFormat("en").format(item?.areaFrom) : t("NotAvailable")) + ' - ' + (item?.areaTo ? Intl.NumberFormat("en").format(item?.areaTo) : t("NotAvailable"))}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {regionsOptions.filter(
                                (i) => +i.id === +item?.area
                              )?.[0]?.label || null}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {citiesOptions.filter(
                                (i) => +i.id === +item?.city
                              )?.[0]?.label || null}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {districtsOptions.filter(
                                (i) => item?.neighbourhood?.includes(+i.id)
                              )?.map((i, index) => i.label + (index === item?.neighbourhood?.length - 1 ? '' : ', ')) || null}
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              <div className="actions-wrapper">
                                <button
                                  onClick={() => {
                                    if (deal.interestId) {
                                      setDeal({ ...deal, interestId: '', interest: undefined });
                                    } else {
                                      setDeal({ ...deal, interestId: item.id, interest: item });
                                    }
                                  }}
                                  className="custom-btn btn-blue" style={{ width: '120px' }}>
                                  {deal.interestId !== item.id ? t("Select") : t("DeSelect")}
                                </button>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                ) : (<p className="p-2">{t("NoInterestsFound")}</p>)}
              </div>
            )}
          </div>
        </div>
      )}

      {isNew && deal.interestId && !propertyId && (
        <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
          <div className='card-header'>
            <h4>{deal?.interest?.interestingType === "استئجار" ? t("SelectLessorOrRealEstateAD") : t("SelectSellerOrRealEstateAD")}</h4>
            <h6>{deal?.interest?.interestingType === "استئجار" ? t("YouMustSelectLessorOrRealEstateADAssociatedWithTheDeal") : t("YouMustSelectSellerOrRealEstateADAssociatedWithTheDeal")}</h6>
          </div>
          <div className='card-body'>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                <FormControl
                  error={Boolean(errors.relatedTo)}
                  className="custom-select">
                  <InputLabel htmlFor='relatedTo' required>{t("RelatedTo")}</InputLabel>
                  <Select
                    value={relatedTo}
                    required
                    sx={{ width: "100%" }}
                    error={Boolean(errors.relatedTo)}
                    onChange={(event) => {
                      setRelatedTo(event.target.value);
                      setDeal({
                        ...deal,
                        value: '',
                        commission: ''
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("RelatedTo")}
                      id="type"
                    />}
                    label={t("RelatedTo")}
                    inputProps={{
                      name: "relatedTo",
                      id: "relatedTo",
                    }}>
                    <MenuItem value='seller'>{deal?.interest?.interestingType === "استئجار" ? t("Lessor") : t("Seller1")}</MenuItem>
                    <MenuItem value='property'>{t("RealEstateAD1")}</MenuItem>
                  </Select>
                  {errors.relatedTo &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {errors.relatedTo}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>
    
              {relatedTo === "seller" && (
                <div className="col-12 col-md-6 col-lg-4">
                  <FormControl
                    sx={{ width: "100%" }}
                    error={Boolean(errors.sellerId)}>
                    <TextField
                      label={deal?.interest?.interestingType === "استئجار" ? t("SelectLessor") : t("SelectSeller")}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <button
                            position='end'
                            className="btn primary-btn py-0 ml-1"
                            style={{ fontSize: '13px', height: '30px' }}
                            onClick={() => setSellersDialog(true)}
                          >
                            {t('Select')}</button>
                        ),
                      }}
                      size="small"
                      value={deal.seller?.name}
                    />
                    {errors.sellerId && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.sellerId}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}

              {relatedTo === "property" && (
                <div className="col-12 mb-4 mb-lg-3 row mx-0">
                  <div className="col-12 ">
                    <h4 className="mb-3">{t("ChooseRealEstateAD")}</h4>
                  </div>
                  {loadingProperties ? <LoadingData /> : properties?.length > 0 ? properties.map((item, i) => (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3" key={i}>
                      <PropertyCardForAdmin
                        item={item}
                        hideActions
                        disableLink
                        customSelect
                        selectedItem={selectedProperty}
                        setSelectedItem={setSelectedProperty}
                        onOK={() => {
                          setDeal({
                            ...deal,
                            property: +item.propertyId,
                            value: preventString(item.defaultPrice),
                            commission: parseInt(+item.defaultPrice * 0.025)
                          });
                        }}
                      />
                     
                    </div>
                  )) : <NoData msg={t("ThereAreNoADsThatMatchBuyerSelectedInterest")} />}
                  <div className="col-12 py-2">
                    {totalCount > pageSize && (
                      <Pagination
                        sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
                        count={parseInt(Math.ceil(totalCount / pageSize))}
                        page={page}
                        onChange={handlePageChange}
                        color="primary" />
                    )}
                  </div>
                  <div className="col-12">
                    {errors.property && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.property}
                        </Alert>
                      </FormHelperText>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    
        
      <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>{t("PriceDetails")}</h4>
          <h6>{t("EnterDealValueAndCommissionDue")}</h6>
        </div>
        <div className='card-body'>
          <div className="row">
            <div className="col-12 col-md-6 mb-4 col-lg-4">
              <FormControl
                error={Boolean(errors.value)}
                className="col-12"
              >
                <TextField
                  label={t("DealValue")}
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={deal.value ? getFormatedPrice(deal.value) : deal.value}
                  size='small'
                  onChange={(v) => {
                    setDeal({
                      ...deal,
                      value: preventString(v.currentTarget.value),
                      commission: parseInt(+v.currentTarget.value?.replace(/,/g, '') * 0.025)
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.value)}
                />
                {errors.value &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.value}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mb-4 col-lg-4">
              <FormControl
                error={Boolean(errors.commission)}
                className="col-12"
              >
                <TextField
                  label={t("Commission")}
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                    ),
                  }}
                  required
                  value={deal.commission ? getFormatedPrice(deal.commission) : deal.commission}
                  size='small'
                  onChange={(v) => {
                    setDeal({
                      ...deal,
                      commission: v.currentTarget.value ? parseInt(preventString(v.currentTarget.value?.toString()?.replace(/,/g, ''))) : preventString(v.currentTarget.value)
                    });
                  }}
                  error={Boolean(errors.commission)}
                />
                {errors.commission &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.commission}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
          </div>
        </div>
      </div>
           
      
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <div className="text-center pb-5 mb-5">
        <LoadingButton
          style={{ minWidth: "250px", justifyContent: "center" }}
          label={t("Save")}
          classes='primary-btn px-5 mx-auto mb-5'
          handleClick={handleSave}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default AddOrEditDeal;
