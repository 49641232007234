import React, { useEffect } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import Projects from "./components/projects";
import Properties from "./components/properties";
import TopStatistics from "./components/top-statistics";
import { connectWithWhatsApp } from "../../../api/userApi";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const { AccountType, UserType, currentPlan, token } = useAuth();
  const { i18n } = useTranslation();
  
  useEffect(() => {
    connectWithWhatsApp({ userId: token, language: i18n.language === 'ar' ? '0' : '1' });
  }, [token, i18n]);

  return (
    <>
      <SubHeader OKElement={<></>} />
      {UserType !== '5' && <TopStatistics />}
      {AccountType === '2' && UserType !== '5' && [8, 9].includes(+currentPlan?.plan?.id) && (<Projects />)}
      <Properties />
    </>
  );
}

export default DashboardPage;
