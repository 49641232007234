import React from 'react'

export const FeatureCard = ({ icon, title, description, animationName, center }) => {
    return (
        <div className="col-12 col-md-6 col-lg-4 feature-card mb-5" data-aos={animationName || "fade-up"} data-aos-duration="1500">
            <div className="icon">
                {icon}
            </div>
            <div className={`title ${center ? 'text-center' : ''}`}>{title}</div>
            <div className={`description ${center ? 'text-center' : ''}`}>{description}</div>
        </div>
    );
}