import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { linkIAMUser } from "../../api/userApi";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../../helpers/context";
import AppLayout from "../layouts/app-layout";
import { useTranslation } from "react-i18next";

const Callback = (props) => {
  const history = useNavigate();

  const { token } = useAuth();
  let userid;
  let { userId } = useParams();
  if (userId) userid = userId;

  // function isNumeric(str) {
  //   if (typeof str != "string") return false; // we only process strings!
  //   return (
  //     !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  //     !isNaN(parseFloat(str))
  //   ); // ...and ensure strings of whitespace fail
  // }

  const [msg, setMsg] = useState("wait");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (token)
      linkIAMUser({
        iamUserId: userid,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          if (_userInfo.response.company) {
            history(
              "/companylocation/" +
              (_userInfo.response.latitude
                ? _userInfo.response.latitude
                : "24.74772941029266") +
              "/" +
              (_userInfo.response.longitude
                ? _userInfo.response.longitude
                : "46.619290983645406") +
              "/" +
              (_userInfo.response.address
                ? _userInfo.response.address
                : "empty")
            );
          } else {
            setMsg("Done");
            history("/successverfication");
          }
        } else {
          toast.error(_userInfo.resStr);
          setMsg(_userInfo.resStr);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AppLayout needAuth pageTitle={t("AccountVerification")}>
      {token &&
        (msg === "Done" ? (
          <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
            <h4>{t("AuthenticationSuccessfully")}</h4>
          </div>
        ) : (
          <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
            {msg === "wait" ? <CircularProgress /> : <h4>{msg}</h4>}
          </div>
        ))}
    </AppLayout>
  );
}

export default Callback;
