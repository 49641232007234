import React from "react";
import AppLayout from "../../../components/layouts/app-layout";
import LoginPopup from "../../../components/user-components/login-popup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const UnAuthorized = () => {
  const { token, setToken, setUserId, setUserType } = useAuth();
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const { t } = useTranslation();

  if (token && searchParams.get("returnURL")) {
    history(searchParams.get("returnURL"));
    return <></>;
  }
  
  return (
    <AppLayout pageTitle={t("YouCannotAccessThisPage")}>
      <div className='error-page version2'>
        {/* <h2>401</h2> */}
        <p>{t("ToViewThisPageYouMustLogInUsingYourAmakknAccount")}</p>
        <LoginPopup
          setToken={setToken}
          setUserId={setUserId}
          setUserType={setUserType}
          noIcon
          settext={t("Login")}
          btnTyp='2'
          path={searchParams.get("returnURL")}
        />
        <img
          src='/assets/img/cloud.svg'
          alt='cloud'
          className='cloud cloud-1'
        />
        <img
          src='/assets/img/cloud.svg'
          alt='cloud'
          className='cloud cloud-2'
        />
        <img
          src='/assets/img/cloud.svg'
          alt='cloud'
          className='cloud cloud-3'
        />
        <img
          src='/assets/img/cloud.svg'
          alt='cloud'
          className='cloud cloud-4'
        />
        <img src='/assets/img/404.svg' alt='404' className='image-404' />
      </div>
    </AppLayout>
  );
}

export default UnAuthorized;
