import React from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import {
  getUserProile,
  getWLProfile,
  saveWLProfile,
} from "../api/userApi";
import { ArrowBack } from "@mui/icons-material";
import {
  parseUsername,
  preventString,
  resolvePersianAndArabicNumbers,
  validateEmail,
  validateFacebookURL,
  validateInstagramURL,
  validatePhone,
  validateTwitterURL,
  getDemoSnakbar,
  trimPhone,
  resolvePhonePrefix
} from "../helpers";
import LoadingButton from "./global-components/loading-btn";
import { useAuth } from "../helpers/context";
import { Alert, Box, Button, FormControl, FormHelperText } from "@mui/material";
// import Autocomplete from "react-google-autocomplete";
import GoogleMapsContainer from "./section-components/registration/GoogleMapsContainer";
import { DEMO_VENDORS } from "../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const ContactInfo = forwardRef((props, ref) => {
  const [WlUser, setWlUser] = useState({});
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const { token, vendor } = useAuth();
  const [TokenTouse] = useState(token ? token : props.token);
  const { withoutDefaultData } = props;
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState('');
  const [isDataReady, setIsDataReady] = useState(false);
  const history = useNavigate();
  const [location, setLocation] = useState({
    address: "",
    latitude: "",
    longitude: ""
  });
  const { t, i18n } = useTranslation();
  const isAdmin = !window.location.href.includes("minasati-step2");

  useImperativeHandle(ref, () => ({
    onSave() {
      onSave();
    },
  }));

  function formIsValid() {
    const _errors = {};
    if (!WlUser.briefDescription)
      _errors.briefDescription = t("EnterWebsiteName");
    // if (!WlUser.address) _errors.address = "الرجاء ادخال العنوان";

    if (!WlUser.phone) _errors.phone = t("EnterPhoneNumber");
    else if (!validatePhone(WlUser.phone))
      _errors.phone = t("PhoneNumberIsIncorrect");
    if (!WlUser.whatsapp) _errors.whatsapp = t("EnterPhoneNumber");
    else if (!validatePhone(WlUser.whatsapp))
      _errors.whatsapp = t("PhoneNumberIsIncorrect");

    if (!WlUser.email) _errors.email = t("PleaseEnterAnEmail");
    else if (!validateEmail(WlUser.email))
      _errors.email = t("PleaseEnterValidEmail");

    if (WlUser.instagram !== "" && !validateInstagramURL(WlUser.instagram))
      _errors.instagram = t("PleaseEnterValidUsername");
    if (WlUser.facebook !== "" && !validateFacebookURL(WlUser.facebook))
      _errors.facebook =t("PleaseEnterValidUsername");
    if (WlUser.twitter !== "" && !validateTwitterURL(WlUser.twitter))
      _errors.twitter = t("PleaseEnterValidUsername");

    if (_errors.email) {
      toast.error(_errors.email);
    }
    if (_errors.phone) {
      toast.error(_errors.phone);
    }
    if (_errors.whatsapp) {
      toast.error(_errors.whatsapp);
    }
    if (_errors.briefDescription) {
      toast.error(_errors.briefDescription);
    }
    if (_errors.twitter) {
      toast.error(_errors.twitter);
    }
    else if (_errors.facebook) {
      toast.error(_errors.facebook);
    }
    else if (_errors.instagram) {
      toast.error(_errors.instagram);
    }
    
    
    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

  const onSave = () => {
    if (!formIsValid())
      return;

    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      if (props.setLoading) props.setLoading(true);
      saveWLProfile({
        ...WlUser,
        userId: TokenTouse,
        phone: WlUser.phone,
        whatsapp: resolvePhonePrefix(WlUser.whatsapp),
        address: address,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((resp) => {
        if (resp.resCode === 0) {
          if (props.goNext) {
            props.goNext();
          } else {
            getData();
            toast.success(t("EditedSuccessfully"));
          }
        } else {
          toast.error(resp.resStr);
        }
      }).finally(() => {
        if (props.setLoading) props.setLoading(false);
      });
    }
 
  };

  const handleReSendEmail = () => {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);

    } else {
      saveWLProfile({
        ...WlUser,
        userId: TokenTouse,
        email: "test@test.com",
        address: address,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((resp) => {
        if (resp.resCode === 0) {
          saveWLProfile({
            ...WlUser,
            userId: TokenTouse,
            address: address,
            language: i18n.language === 'ar' ? '0' : '1',
          }).then((resp) => {
            if (resp.resCode === 0) {
              getData();
              toast.success(t("ANewActivationMessageHasBeenSent"));
            }
          });
        }
      });
    }
  };

  useEffect(() => { }, [location]);
  
  const getData = () => {
    getWLProfile({
      userName: vendor,
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        let addressInfo = _userInfo.response.wlUser.address;
        setWlUser({
          ..._userInfo.response.wlUser,
          phone: trimPhone(_userInfo.response.wlUser.phone),
          whatsapp: trimPhone(_userInfo.response.wlUser.whatsapp),

        });
        if (vendor === "amakkn") {
          getUserProile({
            userId: token,
            language: i18n.language === 'ar' ? '0' : '1',
          }).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
              setAddress(_userInfo.response.userProfile.companyAddress);
              setLocation({
                address: _userInfo.response.userProfile.companyAddress,
                latitude: _userInfo.response.userProfile.companyLatitude,
                longitude: _userInfo.response.userProfile.companyLongitude
              });
            }
          }).finally(() => setIsDataReady(true));
        } else {
          setAddress(addressInfo);
          if (addressInfo?.indexOf('@') > -1) {
            setLocation({
              address: addressInfo?.substring(0, addressInfo?.indexOf('@')),
              latitude: addressInfo?.substring(addressInfo?.indexOf('@') + 1, addressInfo?.lastIndexOf('@')),
              longitude: addressInfo?.substring(addressInfo?.lastIndexOf('@') + 1)
            });
          }
          setIsDataReady(true);
        }
      }
    });
  };

  useEffect(() => {
    if (vendor && token)
      getData();
  }, // eslint-disable-next-line
    [vendor, token]
  );
 
  return (
    <div className='row'>
      <div className='col-12 col-md-6 mb-4'>
        <FormControl
          error={Boolean(errors.briefDescription)}
          className="col-12"
        >
          <TextField
            type='text'
            InputLabelProps={{shrink: true}}
            required
            sx={{ width: "100%" }}
            size='small'
            label={t("WebsiteName")}
            onChange={(e) => {
              setWlUser({
                ...WlUser,
                briefDescription: resolvePersianAndArabicNumbers(
                  e.currentTarget.value
                ),
              });
            }}
            value={withoutDefaultData ? undefined : WlUser.briefDescription}
            error={Boolean(errors.briefDescription)}
          />
          {errors.briefDescription &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.briefDescription}
              </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>
      <div className='col-12 col-md-6 mb-4'>
        <FormControl
          error={Boolean(errors.phone)}
          className="col-12"
        >
          <TextField
            type='text'
            InputLabelProps={{shrink: true}}
            sx={{ width: "100%" }}
            size='small'
            required
            label={t("PhoneNumber")}
            onChange={(e) => {
              setWlUser({
                ...WlUser,
                phone: preventString(e.currentTarget.value),
              });
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position='start'>
            //       966
            //     </InputAdornment>
            //   ),
            // }}
            value={withoutDefaultData ? undefined : WlUser.phone}
            error={Boolean(errors.phone)}
          />
          {errors.phone &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.phone}
              </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>
      <div className='col-12 col-md-6 mb-4'>
        <FormControl
          error={Boolean(errors.whatsapp)}
          className="col-12"
        >
          <TextField
            type='text'
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "100%" }}
            size='small'
            required
            label={t("WhatsAppNumber")}
            onChange={(e) => {
              setWlUser({
                ...WlUser,
                whatsapp: preventString(e.currentTarget.value),
              });
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position='start'>
            //       966
            //     </InputAdornment>
            //   ),
            // }}
            error={Boolean(errors.whatsapp)}
            value={withoutDefaultData ? undefined : WlUser.whatsapp}
          />
          {errors.whatsapp ?
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.whatsapp}
              </Alert>
            </FormHelperText> :
            isAdmin && (
              <Alert
                sx={{ "& .MuiAlert-message": { width: "100%" } }}
                severity={WlUser.isAuthWhatsapp === "0" ? 'warning' : 'success'}
                className='custom-alert2 custom-alert w-100 align-items-center'
                >
                <div className='w-100 d-flex justify-content-between align-items-md-center flex-column flex-md-row'>
                  <span>
                    {WlUser.isAuthWhatsapp === "0" ? t("NotLinkedWithWhatsApp") : t("SuccessfullyLinkedWithWhatsApp")}
                  </span>
                  {WlUser.isAuthWhatsapp === "0" && (
                    <Button
                      style={{
                        width: "fit-content",
                        borderColor: "#222",
                        color: "#222",
                      }}
                      className='mt-2 mt-md-0'
                      variant='outlined'
                      onClick={() => history('/admin/link-with-whatsapp')}>
                      {t("LinkNow")}
                    </Button>
                  )}
                </div>
              </Alert>
            )
          }
        </FormControl>
      </div>
      <div className='col-12 col-md-6 mb-4 d-flex flex-column align-items-end'>
        <FormControl
          error={Boolean(errors.email)}
          className="col-12"
        >
          <TextField
            type='text'
            InputLabelProps={{shrink: true}}
            sx={{ width: "100%",'& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
            required
            size='small'
            label={t("Email")}
            onChange={(e) => {
              setWlUser({
                ...WlUser,
                email: resolvePersianAndArabicNumbers(e.currentTarget.value),
              });
            }}
            error={Boolean(errors.email)}
            value={withoutDefaultData ? undefined : WlUser.email}
          />
          {errors.email ?
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.email}
              </Alert>
            </FormHelperText>
            : isAdmin && WlUser.isVerified === "0" && (
              <Alert
                sx={{ "& .MuiAlert-message": { width: "100%" } }}
                severity='error'
                className='custom-alert w-100 align-items-center'
               >
                <div className='w-100 d-flex justify-content-between align-items-md-center flex-column flex-md-row'>
                  <span>{t("PleaseCheckYourMailboxToConfirmYourEmail")} </span>
                  <Button
                    style={{
                      width: "fit-content",
                      borderColor: "#222",
                      color: "#222",
                    }}
                    className='mt-2 mt-md-0'
                    variant='outlined'
                    onClick={handleReSendEmail}>
                    {t("Resend")}
                  </Button>
                </div>
              </Alert>
            )
          }
        </FormControl>
      </div>
      {/* <div className='col-12 mb-4'> */}
      {/* <TextField
          type='text'
          // required
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: "100%" }}
          size='small'
          label={t("Address")}
          onChange={(e) => {
            setWlUser({
              ...WlUser,
              address: resolvePersianAndArabicNumbers(e.currentTarget.value),
            });
          }}
          error={errors.address}
          value={withoutDefaultData ? undefined : WlUser.address}
        /> */}
      {/* <div className='searchBox-input p-0 order-sm-0'>
          <div
            className='rld-single-input left-icon'
            >
            <Autocomplete
              placeholder={"أدخل العنوان للبحث"}
              onPlaceSelected={(place) => setAddress(place.formatted_address)}
              style={{ fontSize: "15px" }}
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "SA" },
              }}
              defaultValue={withoutDefaultData ? undefined : address}
            />
          </div>
        </div> */}
      {/* <Autocomplete
          freeSolo
          sx={{ width: "100%",direction:'rtl'}}
          size='small'
          value={withoutDefaultData ? undefined : WlUser.address || ""}
          onInputChange={(e, newValue) => {
            setWlUser({
              ...WlUser,
              address: resolvePersianAndArabicNumbers(newValue),
            });
            if (newValue?.length >= 4) {
              getNationalAddress({ userId: token, string: newValue })
                .then((res) => {
                  if (res?.resCode === 0) {
                    const addresses = res?.response?.Addresses;
                    if (addresses?.length > 0) {
                      let temp = [];
                      addresses.map((item) =>
                        temp.push(
                          `${item.Address1 || ''}${item.Address2 || ''}`
                        )
                      );
                      setAddressOptions(temp);
                    }
                  }
                 
                })
                .catch(() => {});
            }
          }}
          inputValue={withoutDefaultData ? undefined : WlUser.address}
          options={addressOptions.map((option) => option)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Address")}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.address}
            />
          )}
        /> */}
      {/* </div> */}


      <div
        className={`col-12 mb-4 mt-5 ${props.centerText ? 'centerText' : ''}`}>
        <h5>{t("SocialMediaAccounts")}</h5>
        <p>{t("YouCanEnterYourFacebookXAndInstagramAccounts")}</p>
      </div>
      <div className='col-12 col-md-4 mb-4 mb-md-0'>
        <FormControl
          error={Boolean(errors.facebook)}
          className="col-12"
        >
          <TextField
                    sx={{ width: "100%", '& .MuiInputBase-root':{direction:'ltr'}, '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                    label={t("FacebookAccount")}
            size='small'
            type='text'
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ '& p':{textAlign:'right',direction:'ltr'} }} position='start'>facebook.com/</InputAdornment>
              ),
            }}
            onChange={(e) => {
              setWlUser({
                ...WlUser,
                facebook: parseUsername(e.currentTarget.value),
              });
            }}
            error={Boolean(errors.facebook)}
            value={WlUser.facebook}
          />
          {errors.facebook &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.facebook}
              </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>
      <div className='col-12 col-md-4 mb-4 mb-md-0'>
        <FormControl
          error={Boolean(errors.twitter)}
          className="col-12"
        >
          <TextField
            type='text'
            size='small'
            label={t("XAccount")}
            InputLabelProps={{ shrink: true }}
            sx={{ width: "100%", '& .MuiInputBase-root':{direction:'ltr'}, '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ '& p':{textAlign:'right',direction:'ltr'} }} position='start'>x.com/</InputAdornment>
              ),
            }}
            onChange={(e) => {
              setWlUser({
                ...WlUser,
                twitter: parseUsername(e.currentTarget.value),
              });
            }}
            error={Boolean(errors.twitter)}
            value={WlUser.twitter}
          />
          {errors.twitter &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.twitter}
              </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>
      <div className='col-12 col-md-4 mb-4 mb-md-0'>
        <FormControl
          error={Boolean(errors.instagram)}
          className="col-12"
        >
          <TextField
            type='text'
            sx={{ width: "100%", '& .MuiInputBase-root':{direction:'ltr'}, '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
            size='small'
            label={t("InstagramAccount")}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ '& p':{textAlign:'right',direction:'ltr'} }} position='start'>instagram.com/</InputAdornment>
              ),
            }}
            onChange={(e) =>
              setWlUser({
                ...WlUser,
                instagram: parseUsername(e.currentTarget.value),
              })
            }
            error={Boolean(errors.instagram)}
            value={WlUser.instagram}
          />
          {errors.instagram &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.instagram}
              </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>

      <div className="mb-5"></div>
      <div
        className={`col-12 mb-0 pb-0 mt-5 ${props.centerText ? 'centerText' : ''}`}>
        <h5>{t("Address")}</h5>
        <p>{t("YouCanDetermineYourGeographicLocation")}</p>
      </div>
      <div className='p-2 col-12 mb-3'>
        <div style={{ display: 'flex', width: '100%' }}>
          <div className="select-map" style={{ width: '100%', padding: '0', textAlign: 'center', height: '400px', position: 'relative' }}>
            {isDataReady && (<>
              <GoogleMapsContainer
                lat={location.latitude || '24.716199523004914'}
                lng={location.longitude || '46.671776478222675'}
                onChange={(NewLocation) => {

                  // setWlUser({
                  //   ...WlUser,
                  //   address: resolvePersianAndArabicNumbers(`${NewLocation.address}@${NewLocation.latitude}@${NewLocation.longitude}`),
                  // });
                  setLocation({
                    latitude: NewLocation.latitude,
                    longitude: NewLocation.longitude,
                    address: NewLocation.address,
                  });
                  setAddress(`${NewLocation.address}@${NewLocation.latitude}@${NewLocation.longitude}`);

                }}
                user={location}
                zoomLevel={12}
              />
              <img
                alt='pin'
                src={publicUrl + "assets/img/icons/BluePin.svg"}
                className='addPropPin'
              />
              <input
                id='address'
                name='address'
                className='form-control py-3 map-input'
                value={location.address}
              />
            </>)}
            
          </div>
        </div>
      </div>
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      {props.goNext && (
        <div className='col-12 mt-5 text-center next-back-btns'>
          <LoadingButton
            classes='btn primary-btn'
            handleClick={onSave}
            label={
              props.loadingButton ? t("Next") : (
                <>
                  {t("Next")} <ArrowBack />
                </>
              )
            }
            loading={props.loadingButton}
          />
        </div>
      )}
    </div>
  );
});

export default ContactInfo;
