import React, { useState, useEffect } from "react";
import { getUserProile } from "../../../api/userApi";
import { toast } from "react-toastify";
import MyProfilePage from "./components/my-profile";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const MyProfile = () => {
  const { token, vendor } = useAuth();
  const { t, i18n } = useTranslation();
  const [user] = useState({
    userId: token,
    language: i18n.language === 'ar' ? '0' : '1',
  });
  const [userInformation, setuserInformation] = useState();

  const [msg, setMsg] = useState("wait");

  useEffect(() => {
    if (token)
      getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          let userProp = _userInfo.response.userProfile;
          userProp.userId = token;
          userProp.aboutMe = userProp.pushToken;
          userProp.phoneWithCountryCode = "0" + userProp.phone;
          setuserInformation(userProp);
          setMsg("Done");
        } else {
          toast.error(_userInfo.resStr);
          setMsg(_userInfo.resStr);
        }
      });
  }, [token, user]);

  return (
    <AppLayout needAuth pageTitle={vendor !== 'amakkn' ? t("MyProfile") : t("MyProfile2")} withoutNav={vendor !== 'amakkn'}>
      {token && (msg === "Done" ? (
        <div className='add-new-property-area px-4 pb-4 profile-page' style={{marginTop:vendor !== 'amakkn' ? 0 :'75px'}}>
          <MyProfilePage user={userInformation} token={token} />
        </div>
      ) : (
        <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
          {msg === 'wait' ? <CircularProgress /> : <h4>{msg}</h4>}
        </div>
      ))}
    </AppLayout>
  );
};

export default MyProfile;
