import React, { useState, useEffect} from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const GoToTop = () => {
    const history = useNavigate();
    const { token, vendor } = useAuth();
    const [showGoTop, setshowGoTop] = useState(false);
    const { t } = useTranslation();

    const handleVisibleButton = () => {
        const position = window.pageYOffset;
        if (position > 400) return setshowGoTop(true);
        else return setshowGoTop(false);
    };
    
    useEffect(() => {
        window.addEventListener("scroll", handleVisibleButton);
    }, []);
    
    return (
        <>
            {token && vendor === "amakkn" &&
                <div className={`cs-btn ${showGoTop ? 'active' : ''}`}>
                    <span onClick={() => history('/CSChats')}>
                        <Tooltip title={t("ContactTechnicalSupport")} placement="right">
                            <SupportAgentIcon sx={{ color: "#fff", fontSize: "35px", marginTop: "5px" }} />
                        </Tooltip>
                    </span>
                </div>
            }
            <div className={`back-to-top ${showGoTop ? 'active' : ''}`}>
                <span className='back-top' onClick={() => window.scroll({ top: 0, left: 0, behavior: "smooth" })}>
                    <ExpandLessIcon sx={{ color: "#fff", fontSize: "35px", marginTop: "5px" }} />
                </span>
            </div>
        </>
    );
}

export default GoToTop;
