import React from "react";
import { useTranslation } from "react-i18next";
import { removeZeroFromNumberIfExists } from "../../../helpers";
import { Instagram, WhatsApp } from "@mui/icons-material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SwiperCore, { Autoplay, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { SnapChat, Twitter } from "../../../constants/icons";

SwiperCore.use([Autoplay]);

const T04OurTeam = ({ data, team }) => {
  const { t, i18n } = useTranslation();

  const renderTeamMember = (item, i) => {
    return (
      <div className="team-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay={300*i}>
        <div className="team-box-item shine-animate-item">
          <div className="team-box-thumb shine-animate">
            <img src={item.icon} alt={item.firstName} />
          </div>
          <div>
            <h4 className="title">{item.firstName}</h4>
            <span>{item.lastName}</span>
            <div className="team-box-social">
              <ul className="list-wrap">
                {item.whatsapp &&
                  <li>
                    <a target="_blank" rel="noreferrer" href={`https://wa.me/${removeZeroFromNumberIfExists(item.whatsapp)}`}><WhatsApp /></a>
                  </li>
                }
                <li>
                  <a target="_blank" rel="noreferrer" className="no-color" href={`tel:${removeZeroFromNumberIfExists(item.phoneNumber)}`}><LocalPhoneIcon /></a>
                </li>

                {item.snapchat && <li>
                  <a href={`https://www.snapchat.com/${item.snapchat}`} rel="noreferrer" target="_blank"><SnapChat /></a>
                </li>
                }

                {item.twitter && <li>
                  <a href={`https://www.twitter.com/${item.twitter}`} rel="noreferrer" target="_blank"><Twitter medium /></a>
                </li>
                }

                {item.instagram && <li>
                  <a href={`https://www.instagram.com/${item.instagram}`} rel="noreferrer" target="_blank"><Instagram /></a>
                </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
    
  return (
    <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
      <div className="row mx-0 w-100">
        <div className="col-12 col-lg-5 d-flex flex-column">
          <span className="section-prefix tag" data-aos="fade-left" data-aos-duration="1500">{t("Team")}</span>
          <h2 className='sec-title text-start' data-aos="fade-up" data-aos-duration="1500">{t("WePresentToYouOurDistinguishedTeam")}</h2>
        </div>
        <div className={`col-12 col-lg-7 ${data.title ? 'border-lg-start' : ''}`} data-aos="fade-right" data-aos-duration="1500">
          <p className="sec-desc text-start">{data.title}</p>
        </div>
      </div>
         
      {team.length > 3 ? (
        <Swiper
          className={`team-slider mt-4  p-0 template-3__team-boxes`}
          spaceBetween={40}
          key={i18n.language + 'ii'}
          slidesPerView={team.length >= 3 ? 3 : team.length}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
          modules={[Keyboard]}
          keyboard={{ enabled: true }}
          loop={true}
          speed={2400}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            575: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 20,
            }, 1100: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {team.map((item, i) => (
            <SwiperSlide key={i} >
              {renderTeamMember(item, i)}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className='col-12 team-boxes'>
          {team.map((item, i) => renderTeamMember(item, i))}
        </div>
      )}
      <div>
      </div>
    </div>
  );
}

export default T04OurTeam;
