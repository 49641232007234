import {IconButton, Pagination, Paper, Collapse, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography,Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { CITIES, DISTRICTS } from "../../../constants";
import { connectWithWhatsApp, getSimiliarCustomers, getUserProile, getWLProfile } from "../../../api/userApi";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from "moment";
import CustomerDetails from "./customer-details";
import { ListArrow } from "../../../constants/icons";
import SendWhatsAppMsg from "../../../components/send-whatsapp-msg";
import ActionsPopper from "../../../components/global-components/actions-popper";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from "react-i18next";
// import LoadingButton from "../../../components/global-components/loading-btn";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { renderInterestingType, renderPropertyType, trimPhone } from "../../../helpers";

const pageSize = 10;
const similarPageSize = 10;


const Row = ({ expendedRow, districtsOptions, setExpendedRow, row, page, setRow, setItem, setAnchorEl, anchorEl, setOpenPopper, openPopper, setOpenCustomerDialog, setClient, companyName }) => {

  const { AccountType } = useAuth();
  const [similarPage, setSimailarPage] = useState([
    { key: 0, page: 1 }
  ]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let temp = [];
    row.interests?.map(i => temp.push({ key: i.id, page: 1 }));
    setSimailarPage(temp);
  }, [row, page]);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, background: row.id === expendedRow ? '#fafafa' : 'none' }}>
        <TableCell align="right" className="small-col">
          <IconButton
            aria-label="expand row"
            size="small"
            className="custom-btn"
            sx={{ background: row.id === expendedRow ? 'var(--main-color-one)' : '#fff', borderRadius: '8px' }}
            onClick={() => setExpendedRow((old) => old === row.id ? 0 : row.id)}
          >
            {row.id === expendedRow ? <KeyboardArrowUpIcon sx={{ color: '#fff' }} /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          <span style={{ textDecoration: 'underline', color: 'var(--main-color-one)', cursor: 'pointer' }} onClick={() => {
            setClient(row);
            setOpenCustomerDialog(true);
          }}>{row.name}</span>
        </TableCell>
        <TableCell align="right"><a href={`tel:${trimPhone(row.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(row.phone)}</a></TableCell>
        <TableCell align="right">{row.createdAt ? moment(row.createdAt).locale('en').format("DD-MM-YYYY") : t("Undefined")}</TableCell>

        {AccountType === '2' && <TableCell align="right">
          {row.owner === companyName ? t("MainAccount") : row.owner}
        </TableCell>}
        <TableCell align="right">{row.interests?.length}</TableCell>
      </TableRow>
      <TableRow sx={{ background: row.id === expendedRow ? '#fafafa' : 'none' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={row.id === expendedRow} timeout="auto" unmountOnExit>
            {row.interests?.length > 0 && row.interests?.map((item, index) => (
              <Box sx={{ margin: 1 }} key={item.id}>
                <Typography variant="h6" gutterBottom component="div" style={{ margin: '15px 0' }}>
                  <ListArrow style={i18n.language === "en" ? { transform: 'rotate(180deg)' } : {}} fill='black' /> {`${renderInterestingType(item.interestingType)} ${renderPropertyType(item.propertyType)} - ${districtsOptions?.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label} `}
                  <span style={{ fontSize: '14px' }}>{`(${t("Area")}: ${item?.areaFrom ? Intl.NumberFormat("en").format(item.areaFrom) + t("M2") : t("NotAvailable")} - ${t("Price")}: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ${t("SAR")})`}</span>
                </Typography>
                {item?.similar?.length > 0 ? (
                  <div style={{ display: 'flex', maxHeight: '400px', width: '100%', flexDirection: 'column', marginBottom: 30 }}>
                    <div className="d-flex align-items-center mb-3" style={{ gap: '5px' }}>
                      <p style={{ fontSize: '15px', fontWeight: '600' }}>{`${t("InterestedCount")}: ${item?.similar?.length ? Intl.NumberFormat("en").format(item?.similar?.length) : t("NotAvailable")}`}</p>
                      {/* {typeFilter !== 'باحث' && <LoadingButton
                        classes="primary-btn btn whatsapp-button px-3 mb-3"
                        style={{ width: 'fit-content',height:'40px'  }}
                        handleClick={() =>
                          setWhatsAppDialog({
                            message: `السلام عليكم،\n\nمتوفّر لدينا ${item.propertyType} ${item.interestingType === 'تأجير' || item.interestingType === "استئجار" ? t("ForRent") : t("ForSale")} قد تكون مناسبة لكم.\nالموقع: ${citiesOptions.filter((i) => +i.id === +item?.city)?.[0]?.label || null}, ${districtsOptions.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label}\nالسعر: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ريال\n${item?.areaFrom ? `${t("Area")}: ${Intl.NumberFormat("en").format(item.areaFrom)}${t("M2")} ${item?.areaTo !== item?.areaFrom ? `- ${Intl.NumberFormat("en").format(item.areaTo)}${t("M2")}` : ''}` : ''}\n\n${siteName}`,
                            numbers: item?.similar?.map(item => item.phone),
                            visible: true,
                            multiple: true,
                            customers: item?.similar
                          })
                        }
                        label={t("ContactViaWhatsApp")}
                        icon={<WhatsAppIcon />}
                      />} */}
                    </div>
                    
                    <TableContainer className="table-container" component={Paper}>
                      <Table sx={{ minWidth: '100%' }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">{t("Name")}</TableCell>
                            <TableCell align="right">{t("PhoneNumber")}</TableCell>
                            <TableCell align="right">{t("Seriousness")}</TableCell>
                            <TableCell align="right">{t("AdditionDate")}</TableCell>
                            <TableCell align="right" style={{ maxWidth: '100px' }}>{t("Notes")}</TableCell>
                            <TableCell align="right">{t("Actions")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.similar?.length > 0 && item?.similar?.slice(0).splice((similarPage?.filter(p => p.key === item.id)?.[0]?.page * similarPageSize) - similarPageSize, similarPageSize)?.map((row1) => (
                            <TableRow
                              key={row1.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="right" component="th" scope="row">
                                <span style={{ textDecoration: 'underline', color: 'var(--main-color-one)', cursor: 'pointer' }} onClick={() => {
                                  setClient(row1);
                                  setOpenCustomerDialog(true);
                                }}> {`${row1.name} - ${row1.type}`}</span>
                            
                              </TableCell>
                              <TableCell align="right"><a href={`tel:${trimPhone(row1.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline', direction: 'ltr', display: 'inline-block' }}>{trimPhone(row1.phone)}</a></TableCell>
                              <TableCell align="right">{row1.seriousness || t("Undefined")}</TableCell>
                              <TableCell align="right">{row1.createdAt ? moment(row1.createdAt).locale('en').format("DD-MM-YYYY") : t("Undefined")}</TableCell>
                              <TableCell align="right">
                                <p
                                  title={row1.notes ? row1.notes.length > 70 ? row1.notes : '' : ''}
                                  className='text-more'
                                  style={{ whiteSpace: "pre-line" }}>
                                  {row1.notes
                                    ? row1.notes.length > 70
                                      ? row1.notes.substr(0, 70) + "... "
                                      : row1.notes : t("Undefined")}
                                </p>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={(e) => {
                                    setOpenPopper(!openPopper);
                                    setAnchorEl(anchorEl ? null : e.target);
                                    setRow(row1);
                                    setItem(item);
                                  }}
                                  sx={{
                                    background: '#fff',
                                    zIndex: 99,
                                    color: 'var(--main-color-one)',
                                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                    '&:hover,&:focus': {
                                      color: '#fff',
                                      background: 'var(--main-color-one)'
                                    }
                                  }}>
                                  <MoreVertIcon />
                                </IconButton>
                             
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (<p>{t("NoCustomersMatchThisInterest")}</p>)}
               
                {item?.similar?.length > similarPageSize && (
                  <Pagination
                    sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
                    count={parseInt(Math.ceil(item?.similar?.length / similarPageSize))}
                    page={similarPage?.filter(p => p.key === item.id)?.[0]?.page}
                    onChange={(event, value) => {
                      let temp = [];
                      similarPage?.map(p => p.key === item.id ? temp.push({ key: item.id, page: value }) : temp.push(p));
                      setSimailarPage(temp);
                    }}
                    color="primary" />
                )}
                {row.interests?.length === index + 1 ? null :
                  <div style={{ width: '100%', height: 1, backgroundColor: '#ededed', marginTop: 15, marginBottom: 20 }} />
                }
              </Box>
            ))}
          
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const MatchedCustomersPage = () => {
  const [data, setData] = useState([]);
  const [whatsAppDialog, setWhatsAppDialog] = useState({
    visible: false,
    message: '',
    numbers: [],
    multiple: false,
    customers: []
  });
  const [loadingData, setLoadingData] = useState(true);
  const { token, AccountType, UserType, vendor } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [client, setClient] = useState({});
  const [companyName, setCompanyName] = React.useState("");
  const [typeFilter, setTypeFilter] = React.useState("مالك");
  const [expendedRow, setExpendedRow] = useState(0);
  const [row, setRow] = useState();
  const [item, setItem] = useState();
  const { t, i18n } = useTranslation();
  const [openPopper2, setOpenPopper2] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [siteName, setSiteName] = useState('');

  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  useEffect(() => {
    connectWithWhatsApp({ userId: token, language: i18n.language === 'ar' ? '0' : '1' });
  }, [token, i18n]);

  useEffect(() => {
    if (token && AccountType === "2" && UserType !== "5") {
      getUserProile({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(user => {
        if (user.resCode === 0) {
          setCompanyName(user.response.userProfile.companyName);
        }
      });
    }
    getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setSiteName(window.location.href.includes('amakkncompany.com') ? 'مكتب أحمد للعقارات (مثال)' : _userInfo.response.wlUser.briefDescription);
      }
    });
      
  },// eslint-disable-next-line
    [token, AccountType, i18n]);
  
  const getData = async () => {
    setLoadingData(true);
    getSimiliarCustomers({
      page: page,
      search: search?.startsWith('05') ? search?.substring(1) : search,
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
      type: typeFilter
    }).then((res) => {
      if (res.resCode === 0) {
        setData(res.response.array);
        setTotalCount(res.response.totalCount);
      } else {
        toast.error(res.resStr);
      }
    }).finally(() => setLoadingData(false));
  };

  useEffect(() => {
    getData();
  },// eslint-disable-next-line
    [token, page, search, typeFilter, i18n]);
  
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };
 

  return (
    <>
      <SubHeader OKElement={<></>} />
     
      <div style={{ display: 'flex', minHeight: '500px', width: '100%', flexDirection: 'column', marginBottom: '50px' }}>
        <div
          style={{ width: "100%", display: "flex", gap: 15, marginBottom: 15, alignItems: 'center' }}>
          <TextField
            id='standard-search'
            style={{ width: "80%", background: '#fff' }}
            placeholder={t("SearchByNameOrPhoneNumber")}
            type='search'
            size='small'
            value={search}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
          />
          <button className={`outlined-btn action-btn`} style={{ width: '20%', minWidth: 'fit-content' }} onClick={() => setTypeFilter(old => old === "مالك" ? 'باحث' : 'مالك')}>
            {typeFilter === 'باحث' ? t("ViewOwners") : t("ViewResearchers")}
          </button>
        </div>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="right" className="small-col" />
                  <TableCell align="right">{t("Name")}</TableCell>
                  <TableCell align="right">{t("PhoneNumber")}</TableCell>
                  <TableCell align="right">{t("AdditionDate")}</TableCell>
                  {AccountType === '2' && <TableCell align="right">{t("AddedBy")}</TableCell>}
                  <TableCell align="right">{t("InterestsCount")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {loadingData ?
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell colSpan={6}><Loadingdata /></TableCell>
                  </TableRow> : data.length > 0 ?
                    data.map((row) => (
                      <Row
                        key={row.id}
                        siteName={siteName}
                        row={row}
                        typeFilter={typeFilter}
                        expendedRow={expendedRow}
                        setExpendedRow={setExpendedRow}
                        page={page}
                        companyName={companyName}
                        setOpenCustomerDialog={setOpenCustomerDialog}
                        setRow={setRow}
                        setItem={setItem}
                        setOpenPopper={setOpenPopper2}
                        openPopper={openPopper2}
                        setAnchorEl={setAnchorEl2}
                        setWhatsAppDialog={setWhatsAppDialog}
                        anchorEl={anchorEl2}
                        districtsOptions={districtsOptions}
                        setClient={setClient} />
                    )) :
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    ><TableCell colSpan={6}><NoData msg={t("NoCustomersFound")} /></TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <SendWhatsAppMsg
            dialog={whatsAppDialog}
            setDialog={setWhatsAppDialog}
          />
        </div>
            
        {totalCount > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px', justifyContent: 'start' } }}
            count={parseInt(Math.ceil(totalCount / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </div>
          
      <CustomerDetails
        openCustomerDialog={openCustomerDialog}
        setOpenCustomerDialog={setOpenCustomerDialog}
        setClient={setClient}
        companyName={companyName}
        client={client} />
          

      <ActionsPopper
        openPopper={openPopper2}
        anchorEl={anchorEl2}
        setAnchorEl={setAnchorEl2}
        setOpenPopper={setOpenPopper2}
        items={
          [
            typeFilter !== 'باحث' && {
              title: t("ContactViaWhatsApp"),
              action: () => setWhatsAppDialog({
                numbers: [row.phone],
                message: `السيد ${row.name}،\nالسلام عليكم،\n\nمتوفّر لدينا ${item.propertyType} ${item.interestingType === 'تأجير' || item.interestingType === "استئجار" ? t("ForRent") : t("ForSale")} قد تكون مناسبة لكم.\nالموقع: ${citiesOptions?.filter((i) => +i.id === +item?.city)?.[0]?.label || null}, ${districtsOptions?.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label}\nالسعر: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ريال\n${item?.areaFrom ? `${t("Area")}: ${Intl.NumberFormat("en").format(item.areaFrom)}${t("M2")} ${item?.areaTo !== item?.areaFrom ? `- ${Intl.NumberFormat("en").format(item.areaTo)}${t("M2")}` : ''}` : ''}\n\n${siteName}`,
                visible: true,
                multiple: false,
                customers: []
              })
            },
            // {
            //   title: "اتصال مباشر",
            //   action: () => window.open(`tel:${trimPhone(row.phone)}`, '_blank')
            // },
            row?.email && {
              title: t("ContactViaEmail"),
              action: () => window.open(`mailto:${row.email}?subject=${companyName}&body=السيد ${row.name}،\nالسلام عليكم،\n\nمتوفّر لدينا ${item.propertyType} ${item.interestingType === 'تأجير' || item.interestingType === "استئجار" ? t("ForRent") : t("ForSale")} قد تكون مناسبة لكم.\nالموقع: ${citiesOptions?.filter((i) => +i.id === +item?.city)?.[0]?.label || null}, ${districtsOptions?.filter((i) => +i.id === +item.neighbourhood[0])?.[0]?.label}\nالسعر: ${Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)} ريال\n${item?.areaFrom ? `${t("Area")}: ${Intl.NumberFormat("en").format(item.areaFrom)}${t("M2")} ${item?.areaTo !== item?.areaFrom ? `- ${Intl.NumberFormat("en").format(item.areaTo)}${t("M2")}` : ''}` : ''}\n\n${siteName}`, '_blank')
            }
          ]
        }
      />
    </>
  );
}

export default MatchedCustomersPage;
