import React, { useEffect, useState } from "react";
import PasswordForm from "./PasswordForm";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";
 
const AgentSetPassword = () => {
  const [errors, setErrors] = useState({});
  const { vendor } = useAuth();
  const history = useNavigate();
  const [disable, setDisable] = React.useState(false);
  let { countryCode } = useParams();
  let { phone } = useParams();
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState({
    id: null,
    password: "",
    confirmpassword: "",
    countryCode: countryCode,
    phone: phone,
    language: i18n.language === 'ar' ? '0' : '1',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleChange({ target }) {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!user.password) _errors.password = t("EnterThePassword");
    if (!user.confirmpassword)
      _errors.confirmpassword = t("ReEnterThePassword");

    if (user.password !== user.confirmpassword)
      _errors.confirmpassword = t("PasswordsDoNotMatch");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

  function handleSubmit(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    setDisable(true);
    setIsSubmitting(true);
    userApi.setPasswordForUser(user).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
       
        if (vendor === 'amakkn') {
          history("/agents/");
        } else {
          history("/admin/agents/");
        }
     
        toast.success(_userInfo.resStr);
      } else {
        const _errors = {};
        _errors.password = _userInfo.resStr;
        setErrors(_errors);
        setDisable(false);
      }
    }).finally(() => setIsSubmitting(false));
  }

  return (
    <AppLayout pageTitle={t("SetPassword")} withoutNav={vendor !== 'amakkn'}>
      <div className={`${vendor !== 'amakkn' ? 'admin' : ''} page-wrapper page-width`}>
        <div className="row page-wrapper-without-top">
          <div className="col-12 col-lg-6 details-wrapper scrolled">
            <PasswordForm
              errors={errors}
              user={user}
              onChange={handleChange}
              onSubmit={handleSubmit}
              disable={disable}
              isSubmitting={isSubmitting}
            />
          </div>
          <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="img-container">
              <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default AgentSetPassword;
