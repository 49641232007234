import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Alert, Autocomplete, FormHelperText, InputAdornment, OutlinedInput, TextField } from "@mui/material";
import { Pen, Plus } from "../../../constants/icons";
import LoadingButton from "../../../components/global-components/loading-btn";
import { CITIES, DISTRICTS, REGIONS } from "../../../constants";
import { useEffect, useMemo } from "react";
import { preventString } from "../../../helpers";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function EditInterestings(props) {
  const [open, setOpen] = React.useState(false);
  const [Model, setModel] = React.useState(props.isEdit ? props.model : {
    'id': '',
    "neighbourhoodIds": [],
    "priceFrom": '',
    "priceTo": '',
    "areaFrom": '',
    "areaTo": '',
    "interestingType": "",
    "propertyType": "",
    "area": "",
    "city": ""
  });
  const [errors, setErrors] = React.useState({});
  const { client } = props;
  const { t, i18n } = useTranslation();

  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR: city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR:district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const handleClickOpen = () => {
    if (client && client.type === "")
      toast.error(t("PleaseSelectTheCustomerTypeFirst"));
    else
      setOpen(true);
  };

  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);
  
  function formIsValid() {
    const _errors = {};
    if (Model?.interestingType === '') {
      _errors.interestingType = t("PleaseSelectTheInterestType");
    }
    if (Model?.propertyType === '') {
      _errors.propertyType = t("PleaseSelectThePropertyType");
    }
    if (Model?.area === '') {
      _errors.area = t("PleaseSelectTheRegion");
    }
    if (Model?.city === '') {
      _errors.city = t("PleaseSelectTheCity");
    }
    if (Model?.neighbourhoodIds?.length === 0) {
      _errors.neighbourhoodIds = t("PleaseSelectTheNeighborhoods");
    }
    if (Model?.priceTo !== "" && Model?.priceFrom !== "") {
      if (+Model?.priceFrom?.toString().replace(/[,]+/g, "") > +Model?.priceTo?.toString().replace(/[,]+/g, ""))
        _errors.price = t("PleaseCheckPrices");
    } else {
      _errors.price = t("PleaseSpecifyPrices");
    }

    if (Model?.areaFrom !== "" && Model?.areaTo !== "") {
      if (+Model?.areaFrom?.toString().replace(/[,]+/g, "") > +Model?.areaTo?.toString().replace(/[,]+/g, ""))
        _errors.areas = t("PleaseCheckArea");
    } else {
      _errors.areas = t("PleaseSpecifyArea");
    }

   
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function getFormatedPrice(price) {
    let value = price?.toString()?.replace(/[,]+/g, "");
    return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  useEffect(() => {
    if (open) {
      setModel(props.isEdit ? props.model : {
        'id': '',
        "neighbourhoodIds": [],
        "priceFrom": '',
        "priceTo": '',
        "areaFrom": '',
        "areaTo": '',
        "interestingType": "",
        "propertyType": "",
        "area": "",
        "city": ""
      })
    }
  },// eslint-disable-next-line
    [open]);
  
  const handleClose = () => {
    setOpen(false);
    setModel({
      'id': '',
      "neighbourhoodIds": [],
      "priceFrom": '',
      "priceTo": '',
      "areaFrom": '',
      "areaTo": '',
      "interestingType": "",
      "propertyType": "",
      "area": "",
      "city": ""
    });
    setErrors({});
  };

  const handleSave = () => {
    if (!formIsValid()) return;
    props.setClient({
      ...props.client,
      interestingList: props.isEdit ?
        [...props.client.interestingList?.filter(i => +i.id !== +Model.id), {
          ...Model,
          priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
          priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
          areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
          areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
        }]
        :
        [...props.client.interestingList, {
          ...Model,
          id: Math.floor(Math.random() * 10000) + props.client.interestingList?.length,
          priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
          priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
          areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
          areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
        }]
    });
    props.onOK?.(props.isEdit ?
      [...props.client.interestingList?.filter(i => +i.id !== +Model.id), {
        ...Model,
        priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
        priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
        areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
        areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
      }]
      :
      [...props.client.interestingList, {
        ...Model,
        id: Math.floor(Math.random() * 10000) + props.client.interestingList?.length,
        priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
        priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
        areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
        areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
      }], true);
    handleClose();

  };



  return (
    <React.Fragment>
      <button
        className={`custom-btn ${props.isEdit ? 'custom-btn2' : ""}`}
        onClick={handleClickOpen}>
        {props.isEdit ? <Pen /> : <><Plus /><span>{t("AddInterest")}</span></>}
      </button>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        className="custom-dialog model"
        onClose={handleClose}>
        <DialogTitle>
          <div className="dialog-head close-only">
            <div>
              <h2>{props.isEdit ? t("EditInterest") : t("AddInterest")}</h2>
              {/* <p>{props.isEdit ? "يمكنك تعديل تفاصيل الاهتمام" : "قم بادخال تفاصيل الاهتمام"}</p> */}
            </div>
            <div className="dialog-actions">
              <LoadingButton label={t("Save")} classes="px-3 primary-btn" loading={false} handleClick={handleSave} />
              <button className="px-3 outlined-btn" onClick={handleClose}>
                {t("Cancel")}
              </button>
            </div>
          </div>
          
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component='form'
          >
            <div className="row mx-0 mt-4 w-100 flex-column mb-5">
              <div className="col-12 mb-4">
                <FormControl className="custom-select" error={Boolean(errors.interestingType)}>
                  <InputLabel htmlFor='interestingType' required>{t("InterestType")}</InputLabel>
                  <Select
                    autoFocus
                    value={Model.interestingType}
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      setModel({
                        ...Model,
                        interestingType: event.target.value,
                        neighbourhoodIds: [],
                        neighbourhood: [],
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("InterestType")}
                      id="interestingType"
                    />}
                    label={t("InterestType")}
                    inputProps={{
                      name: "interestingType",
                      id: "interestingType",
                    }}>
                    {client?.type === 'مالك' || client?.type === 'وسيط' ? <MenuItem value='بيع'>{t("Sale")}</MenuItem> : null}
                    {client?.type === 'مالك' || client?.type === 'وسيط' ? <MenuItem value='تأجير'>{t("Renting")}</MenuItem> : null}
                    {client?.type === 'باحث' || client?.type === 'وسيط' ? <MenuItem value='شراء'>{t("Buying")}</MenuItem> : null}
                    {client?.type === 'باحث' || client?.type === 'وسيط' ? <MenuItem value='استئجار'>{t("Rent")}</MenuItem> : null}
                  </Select>
                </FormControl>
                {errors.interestingType && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.interestingType}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
              <div className="col-12 mb-4">
                <FormControl className="custom-select" error={Boolean(errors.propertyType)}>
                  <InputLabel htmlFor='propertyType' required>{t("PropertyType")}</InputLabel>
                  <Select
                    value={Model.propertyType}
                    sx={{ width: "100%" }}

                    onChange={(event) => {
                      setModel({
                        ...Model,
                        propertyType: event.target.value,
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("PropertyType")}
                      id="propertyType"
                    />}
                    label={t("PropertyType")}
                    inputProps={{
                      name: "propertyType",
                      id: "propertyType",
                    }}>
                    <MenuItem value='أرض'>{t("Land")}</MenuItem>
                    <MenuItem value='دور'>{t("Floor")}</MenuItem>
                    <MenuItem value='شقة'>{t("Apartment")}</MenuItem>
                    <MenuItem value='فيلا'>{t("Villa")}</MenuItem>
                    <MenuItem value='استوديو'>{t("Studio")}</MenuItem>
                    <MenuItem value='غرفة'>{t("Room")}</MenuItem>
                    <MenuItem value='استراحة'>{t("Resort")}</MenuItem>
                    <MenuItem value='معرض'>{t("Showroom")}</MenuItem>
                    <MenuItem value='مكتب'>{t("Office")}</MenuItem>
                    <MenuItem value='مستودع'>{t("Warehouse")}</MenuItem>
                    <MenuItem value='مزرعة'>{t("Farm")}</MenuItem>
                    <MenuItem value='عمارة'>{t("Building")}</MenuItem>

                  </Select>
                </FormControl>
                {errors.propertyType && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.propertyType}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
              <div className="col-12 mb-4">
                {!Model.interestingType || Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? (
                  <TextField
                    label={t("Area")}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                      ),
                    }}
                    required
                    error={Boolean(errors.areas)}
                    value={getFormatedPrice((Model.areaFrom))}
                    size='small'
                    onChange={(v) => {
                      setModel({
                        ...Model,
                        areaFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                        areaTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                      });
                    }}
                  />
                ) :
                  (<>
                    <TextField
                      label={t("AreaFrom")}
                      sx={{ width: "50%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={Boolean(errors.areas)}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                        ),
                      }}
                      value={getFormatedPrice((Model.areaFrom))}
                      size='small'
                      onChange={(v) => {
                        setModel({
                          ...Model,
                          areaFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                        });
                      }}
                    />
                    <TextField
                      label={t("AreaTo")}
                      sx={{ width: "50%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={Boolean(errors.areas)}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                        ),
                      }}
                      value={getFormatedPrice(Model.areaTo)}
                      size='small'
                      onChange={(v) => {
                        setModel({
                          ...Model,
                          areaTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                        });
                      }}
                    />
                  </>)}
                {errors.areas && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.areas}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
              <div className="col-12 mb-4">
                <TextField
                  label={t("PriceFrom")}
                  sx={{ width: "50%" }}
                  required
                  error={Boolean(errors.price)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                    ),
                  }}
                  value={getFormatedPrice((Model.priceFrom))}
                  size='small'
                  onChange={(v) => {
                    setModel({
                      ...Model,
                      priceFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                    });
                  }}
                />
                <TextField
                  label={t("PriceTo")}
                  required
                  error={Boolean(errors.price)}
                  sx={{ width: "50%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                    ),
                  }}
                  value={getFormatedPrice(Model.priceTo)}
                  size='small'
                  onChange={(v) => {
                    setModel({
                      ...Model,
                      priceTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                    });
                  }}
                />
                {errors.price && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.price}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
             
              <div className="col-12 mb-4">
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.area)}>
                  <Autocomplete
                    // disablePortal
                    size='small'
                    required
                    slotProps={{
                      popper: {
                        sx: {
                          direction: 'rtl',
                          zIndex: 9999999999999
                        }
                      }
                    }}
                    options={regionsOptions}
                    value={
                      regionsOptions.filter(
                        (i) => +i.id === +Model?.area
                      )?.[0] || null
                    }
                    sx={{ width: "100%" }}
                    onChange={(v, newValue) => {
                      setModel({
                        ...Model,
                        area: newValue?.id || "",
                        neighbourhoodIds: [],
                        neighbourhood: [],
                        city: ''
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(errors.area)}
                        variant='outlined'
                        label={t("Region")}
                      />
                    )}
                  />
                  {errors.area && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.area}
                      </Alert>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-12 mb-4">
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.city)}>
                  <Autocomplete
                    // disablePortal
                    size='small'
                    slotProps={{
                      popper: {
                        sx: {
                          direction: 'rtl',
                          zIndex: 9999999999999
                        }
                      }
                    }}
                    required
                    disabled={!Model?.area}
                    options={citiesOptions.filter(
                      (i) => +i.regionId === +Model?.area
                    )}
                    value={
                      citiesOptions.filter(
                        (i) => +i.id === +Model?.city
                      )?.[0] || null
                    }
                    sx={{ width: "100%" }}
                    noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                    onChange={(event, newValue) => {
                      setModel({
                        ...Model,
                        city: newValue?.id || "",
                        neighbourhoodIds: [],
                        neighbourhood: []
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(errors.city)}
                        variant='outlined'
                        label={t("City")}
                      />
                    )}
                  />
                  {errors.city && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.city}
                      </Alert>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-12 mb-4">
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.neighbourhoodIds)}>
                  <Autocomplete
                    // disablePortal
                    size='small'
                    slotProps={{
                      popper: {
                        sx: {
                          direction: 'rtl',
                          zIndex: 9999999999999
                        }
                      }
                    }}
                    required
                    multiple={Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? false : true}
                    disabled={!Model?.city}
                    options={districtsOptions.filter(
                      (i) =>
                        +i.cityId === +Model?.city &&
                        +i.regionId === +Model?.area
                    )}
                    value={Model.interestingType === "بيع" || Model.interestingType === "تأجير" ?
                      (districtsOptions.filter(
                        (i) => Model?.neighbourhoodIds?.includes(+i.id) || Model?.neighbourhood?.includes(+i.id)
                      )?.[0] || undefined) :
                      (districtsOptions.filter(
                        (i) => Model?.neighbourhoodIds?.includes(+i.id) || Model?.neighbourhood?.includes(+i.id)
                      ) || [])
                    }
                    sx={{ width: "100%" }}
                    noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                    onChange={(event, newValue) => {
                      const ids = Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? (newValue ? [newValue?.id] : []) : newValue?.map(i => i.id);
                      setModel({
                        ...Model,
                        neighbourhoodIds: ids,
                        neighbourhood: ids
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(errors.neighbourhoodIds)}
                        variant='outlined'
                        label={Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? t("Neighborhood") : t("Neighborhoods")}
                      />
                    )}
                  />
                  {errors.neighbourhoodIds && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.neighbourhoodIds}
                      </Alert>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
