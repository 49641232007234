import { AccessTime } from '@mui/icons-material';
import React from 'react'
import { Link } from 'react-router-dom';
import { DARK_TEMPLATES, MODERN_TEMPLATES } from '../../../../constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import { useAuth } from '../../../../helpers/context';
import { useTranslation } from 'react-i18next';

export default function PostCard({ item }) {
    const { templateId } = useAuth();
    const { t, i18n } = useTranslation();

    return MODERN_TEMPLATES.includes(+templateId) ? (
        <div className="post-card col-12 col-lg-5">
            <img src={item.image} alt={item.subject} />
            <div className="post-card-content h-100">
                <span className="post-card-title" style={{ color: 'var(--main-color-one)' }}>  {item.subject}</span>
                <span className="post-card-des"> {item.shortDescription}</span>
            </div>
            <span className="post-card-date">{moment(item.createdAt).format('DD-MMMM-YYYY')}</span>
            <Link to={`/news/${item.id}`}><ArrowBackIcon sx={i18n.language !== "ar" ? {transform:'rotate(180deg)'} : {}} /> {t("ReadMore")}</Link>
        </div>
    ) : (
        <div
            key={item.id}
                className={`col-sm-6 ${DARK_TEMPLATES.includes(+templateId)? 'col-lg-5':'col-lg-4'}`}
            style={{ cursor: "pointer" }}>
            <div className='single-project single-leading-feature'>
                <div className='thumb'>
                    <img
                        src={item.image}
                        alt={item.subject}
                    />
                </div>
                <div className='details details2 news-details'>
                    <h4 className='title property-name' style={{ minHeight: 48 }}>
                        {item.subject}
                    </h4>
                    <h5 className='news-desc'>
                        {item.shortDescription}
                    </h5>
                    <div style={{ display: 'flex', gap: '4px', bottom: -7, position: 'relative', alignItems: 'center',  justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <span style={{ width: '15px' }}><AccessTime sx={{ width: '20px', color: 'var(--main-color-one)', top: 2, position: 'relative' }} /></span>
                            <h5 className='price '>
                                {moment(item.createdAt).format('DD-MMMM-YYYY')}
                            </h5>
                        </div>
                        <Link to={`/news/${item.id}`}><ArrowBackIcon sx={i18n.language !== "ar" ? {transform:'rotate(180deg)'} : {}} /> {t("ReadMore")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
