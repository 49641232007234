import React from 'react'

export const PageSection = ({ fromWL, children, preTitle, title, description, withBG, withoutContainer, withMargin }) => {
    return (
        <div className={`row align-items-center justify-content-center section ${fromWL ? 'pt-0' : ''} ${withBG ? 'blue-background' : ''} ${withMargin ? 'projects-section mb-5' : ''}`}>
            <div className={withoutContainer ? '' : "container"}>
                {!fromWL && <div className={`col-12 text-center ${withMargin ? 'mb-5' : ''}`}>
                    <h5 className="section-prelabel">{preTitle}</h5>
                    <h3 className='text-center'>{title}</h3>
                    <p className='text-center'>{description}</p>
                </div>}
                {children}
            </div>
        </div>
    )
}