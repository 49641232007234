import React from 'react'
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function TaskCard({ setOpenTaskDialog, companyName, setTask, item, openPopper, setOpenPopper, anchorEl, setAnchorEl }) {

  const { t } = useTranslation();
  
  return (
    <div className="col-12 mb-3 component" >
      <Card className="deal-inner-card"
        onClick={(e) => {
          if (e.target.tagName !== 'A' && e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
            setTask(item);
            setOpenTaskDialog(true);
          }
      }}
      >
        <CardHeader
          sx={{pb:0}}
          action={
            <IconButton
              sx={{ padding: 0 }}
              onClick={(e) => {
                setOpenPopper(!openPopper);
                setAnchorEl(anchorEl ? null : e.target);
                setTask(item);
              }}>
              <MoreVertIcon />
            </IconButton>
          }
          title={item.summary}
          // onClick={(e) => {
          //   if (e?.target?.classList?.value?.indexOf('MuiCardHeader-title') > -1) {
          //     setTask(item);
          //     setOpenTaskDialog(true);
          //   }
          // }}
          subheader={item.description?.length > 180 ? item.description?.substring(0,180) + '...': item.description}
        />
          
        <CardContent sx={{mt:0}}>
          <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
            <b>{t("AssignedTo")}: </b> {item.assignedTo === companyName ? t("MainAccount") : item.assignedTo}
          </Typography>
          {item.comments?.length > 0 && (
            <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
              <b>{item.comments?.[0]?.createdBy === companyName ? t("MainAccount") : item.comments?.[0]?.createdBy}:</b> {item.comments?.[0]?.content ? (
                item.comments?.[0]?.content?.length > 56 ? (item.comments?.[0]?.content?.substring(0, 56) + '...') : item.comments?.[0]?.content
              ) : t("Nothing")}
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
            <b>{t("Updated")} : </b> {moment(new Date(item.updatedAt)).add(3, 'hours').fromNow()}
          </Typography>
        </CardContent>
         
      </Card>
    </div>
  );
}