import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { initiateChatChannel } from "../../api/userApi";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LoginIcon from '@mui/icons-material/Login';
import * as userApi from "../../api/userApi";
import md5 from "md5";
import { getToken } from "../../Config/Firebase";
import { MosquesBG } from "../../constants/backgrounds";
import LoadingButton from "../global-components/loading-btn";
import { Dialog, DialogContent, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { preventString, validatePassword, validatePhone } from "../../helpers";
import { Close, Visibility, VisibilityOff} from "@mui/icons-material";
import { countryCodes } from "../../constants";
import { useAuth } from "../../helpers/context";
import { Report } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function LoginPopup(props) {
  const [FireBaseToken, setFireBaseToken] = useState();
  const [ToChat] = useState(props.ToChat ? true : false);
  const [userId2] = useState(props.userId2 ? props.userId2 : "");
  const [propertyId] = useState(props.propertyId ? props.propertyId : "");
  const history = useNavigate();
  const [errors, setErrors] = useState({});
  const text = props.settext ? props.settext : "";
  const [loadingButton, setLoadingButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setAccountType, setUserType, vendor } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getToken(setFireBaseToken);
  }, []);
  
  const closeTooltip = () => {
    setOpenModal(false);
  };

  const [credentials, setcredentials] = useState({
    password: "",
    phone: "",
    countryCode: "+966"
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const [btnTyp] = useState(props.btnTyp ? "1" : "0");
  const [btnTyp] = useState(props.btnTyp === "3" ? "3" : props.btnTyp === "2" ? "2" : props.btnTyp ? "1" : "0");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formIsValid()) return;
    setLoadingButton(true);
    userApi.loginUser({ ...credentials, phone: credentials.phone, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        const userId =
          _userInfo.response.user.createdAt + _userInfo.response.user.userId;
        let userHashed = md5(userId);
        if (vendor === 'amakkn') {
          i18next.changeLanguage(_userInfo.response.user.language === "1" ? "en" : "ar");
          i18n.language = _userInfo.response.user.language === "1" ? "en" : "ar";
          i18n.options.lng = _userInfo.response.user.language === "1" ? "en" : "ar";
          localStorage?.setItem('i18nextLng', _userInfo.response.user.language === "1" ? "en" : "ar");
          document.documentElement.setAttribute('lang', _userInfo.response.user.language === "1" ? "en" : "ar");
          userApi.setLanguageForUser({ language: _userInfo.response.user.language, userId: userHashed });
        }
        
        props.setToken(userHashed);
        props.setUserId(_userInfo.response.user.userId);
        setUserType(_userInfo.response.user.userType);
        setAccountType(_userInfo.response.user.accountType);
        if (FireBaseToken)
          userApi.setPushTokenForUserNew({
            userId: userHashed,
            pushToken: FireBaseToken,
            platform: "website",
            language: i18n.language === 'ar' ? '0' : '1',
            identifier: "-1",
          });

        toast.success(t("WelcomeBack", { name: _userInfo.response.user.name }));
        if (ToChat) {
          initiateChatChannel({
            userId1: userHashed,
            userId2: userId2,
            propertyId: propertyId,
          }).then((_response) => {
            let path = "/Chats/" + _response.response.chat.channelId;
            history(path);
          });
        }
        setLoadingButton(false);
        if (props.path) {
          let path = props.path;
          if (props.state)
            history(path, { state: props.state });
          else
            history(path);
        }
        props?.onOK?.();
        closeTooltip();
      } else {
        const _errors = {};
        _errors.password = _userInfo.resStr;
        setErrors(_errors);
        setLoadingButton(false);
        if (_userInfo.resCode === 20) {
          history("/setpassword/" + credentials.countryCode + "/" + preventString(credentials.phone), { state: { from: 'create-account' } });
        }
        if (_userInfo.resCode === 19) {
          history("/otp/" + credentials.countryCode + "/" + preventString(credentials.phone));
        }
      }
    });
  };

  function formIsValid() {
    const _errors = {};

    if (!credentials.password) _errors.password = t("EnterThePassword");
    else if (credentials.password && !validatePassword(credentials.password)) _errors.password = t("PasswordMustNotBeLessThan6Characters");

    if (!credentials.phone) _errors.phone = t("EnterPhoneNumber1");
    else if (credentials.phone && !validatePhone(credentials.phone)) _errors.phone = t("PhoneNumberIsIncorrect");

    if (!credentials.countryCode) _errors.phone = t("ChooseCountryCode");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);

  return (
    <>
      {props.children ? (<div className={props.block ? "w-100" : ""} onClick={() => setOpenModal(true)}>
        {props.children}
      </div>) :
        btnTyp === "3" ? props.reportBtn ? (
          <button aria-label={props.text} className='btn btn-danger rounded-btn mt-3' onClick={() => setOpenModal(true)}>
            <Report />{props.text}
          </button>
        ) : (
          <button aria-label={props.text} className={`btn fav-btn ${props.extraClasses ? props.extraClasses : ''}`} onClick={() => setOpenModal(true)}>
            {props.text}{props.icon}
          </button>
        ) :
          btnTyp === "2" ? (!props.noIcon ? (<div style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center'
          }} >
            <span aria-label={text} onClick={() => setOpenModal(true)} className='btn btn-yellow btn-rounded d-none d-lg-flex'>
              {text}
            </span>
            <span aria-label='log in' onClick={() => setOpenModal(true)} className='mobile-log-btn d-lg-none mobile-invisible'>
              <LoginIcon />
            </span>
          </div>
          ) : (<div style={{
            display: 'flex',
            alignItems: 'center'
          }} >
            <button aria-label={text} onClick={() => setOpenModal(true)} className='btn btn-yellow btn-rounded d-flex'>
              {text}
            </button>
        
          </div>)
          ) : btnTyp === "1" ? (
            <button aria-label={text} onClick={() => setOpenModal(true)} className={`btn primary-btn ${props.fullWidth ? 'd-flex justify-content-center w-100' : ''}`}>
              {text}
            </button>
          ) : (
            <div
              className='btn menu-item'
              style={{
                padding: "0 5px",
                backgroundColor: "transparent",
                width: "100%",
                color: "#666666",
                marginRight: "10px",
              }}
              aria-label={'button'}
              onClick={() => setOpenModal(true)}
            >
                  
              <span className='right '>
                {/* <i className={props.icon ? props.icon : "fa fa-sign-in"} /> */}
                {props.icon ? typeof props.icon === "string" ? <i className={props.icon} /> : <props.icon /> : <i className="fa fa-sign-in" />}
              </span>
              {text}
            </div>
          )
      }
      <Dialog
        onClose={() => setOpenModal(false)}
        className="custom-dialog no-padding full-screen2"
        open={openModal}
      >
        <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
          <div className='modal2' onKeyDown={(event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              handleSubmit(event);
            }
          }}>
            <IconButton className='close' onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
            <div className='login-modal' >
              <div className='col-10 col-sm-9'>
                <form
                // onSubmit={handleSubmit}
                >
                  <h4 className="text-center">{t("Login")}</h4>
                  <p className="text-center">{t("WelcomeToAmakkn")}</p>

                  <div className='row p-0 m-0 phone-wrapper'>
                    <div className='col-5 p-0'>
                      <div
                        className='rld-single-input'
                        style={{ paddingRight: "10px" }}>
                        <Select
                          id='countryCode'
                          name='countryCode'
                          onChange={(e) =>
                            setcredentials({
                              ...credentials,
                              countryCode: e.target.value,
                            })
                          }
                          value={credentials.countryCode || ""}
                          className='sortDDL withBg'>
                          {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
                        </Select>
                      </div>
                    </div>
                    <div
                      className='col-7 pr-0'>
                      <div className='rld-single-input'>
                        <TextField
                          className="without-label"
                          placeholder={t("EnterPhoneNumber1")}
                          onChange={(e) =>
                            setcredentials({
                              ...credentials,
                              phone: preventString(e.target.value),
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: '100%', '& input': { direction: 'ltr', textAlign: 'left',textAlignLast:'left' } }}
                          value={credentials.phone}
                          error={errors.phone ? true : false}
                          helperText={errors.phone}
                        />
                      </div>
                    </div>
                    {/* <div className="col-12 p-0">
                      {errors.phone && (
                        <div className='alert alert-danger'>{errors.phone}</div>
                      )}
                    </div> */}
                  </div>
                  <div className='col-12 p-0 mt-4'>
                    <div className='rld-single-input text-start'>
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        className="without-label"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: '100%', '& input': { border: 'none', '&:hover': { border: 'none' }, '&:focus': { border: 'none' } } }}
                        value={credentials.password}
                        error={errors.password ? true : false}
                        helperText={errors.password}
                        placeholder={t("EnterThePassword")}
                        onChange={(e) =>
                          setcredentials({
                            ...credentials,
                            password: e.target.value,
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    {/* {errors.password && (
                      <div className='alert alert-danger'>
                        {errors.password}
                      </div>
                    )} */}
                  </div>
                  <div className={`col-12 p-0 text-start ${errors.password ? 'mt-4' : 'mt-2'}`}>
                    <Link to="/forgot-password">{t("ForgotYourPassword")}</Link>
                  </div>
                  <LoadingButton
                    classes='mt-4 btn primary-btn px-3 w-100 justify-content-center'
                    label={t("Login")}
                    loading={loadingButton}
                    handleClick={handleSubmit}
                  />
                </form>
              </div>
              <div className='col-10 col-sm-9 login-modal-footer'>
                <div className="prefix"><span>{t("OR")}</span></div>
                <h4 className="text-center">{t("DontHaveAnAccount")}</h4>
                <Link
                  id='register'
                  className='btn outlined-btn px-3 justify-content-center'
                  to={"/registration"}>
                  {t("SignUp")}
                </Link>
              </div>
            
            </div>
            <div className="col-12 bottom-image">
              <MosquesBG />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

LoginPopup.propTypes = {
  setToken: PropTypes.func.isRequired,
};
