import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "firebase/compat/analytics";
import { toast } from "react-toastify";
import { getMenuItems, getWLProfile } from "../../api/userApi";
import { onMessageListener } from "../../Config/Firebase";
import { useTranslation } from "react-i18next";
import { Tel, WLAdministration, WLBlog, WLCall, WLHome, WLInfo, WLProjects, WLProperties } from "../../constants/icons";
import { useAuth } from "../../helpers/context";
import { AppBar, Box, Container, MenuItem, Toolbar,IconButton, Button, Drawer } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { CLASSIC_TEMPLATES, DARK_TEMPLATES, DEMO_VENDORS } from "../../constants";
import { ThemeProvider } from "@mui/styles";
import { Close } from "@mui/icons-material";

export function LinkTo(props) {
  const { templateId } = useAuth();
  return (
    <Link
      to={props.linkTo}
      className={`btn menu-item ${props.activationLinks &&
        props.activationLinks.includes(window.location.pathname)
        ? 'active' : ""} ${props.component ? 'default-color':''} ` }>
      {DARK_TEMPLATES.includes(+templateId) ? null : <span className="right ">
        {typeof props.icon === "string" ? <i className={props.icon} /> : props.component ? props.icon : <props.icon />}
      </span>}
      <span>
        {props.name}
      </span>
    </Link>
  );
}

export function LinkToMenu(props) {
  const { token } = useAuth();
  return (
    <Link
      to={props.linkTo}
      className={props.activationLinks && props.activationLinks.filter(i => window.location.href.indexOf(i) > -1).length > 0 ? `btn menu-item ${token ? 'v2' :props.withoutPadding ? '' : 'v2'} active` : `btn ${token ? 'v2' :props.withoutPadding ? '' : 'v2'} menu-item`}
    >
      <span className="right ">
      {typeof props.icon ==="string" ?<i className={props.icon} />:<props.icon/>}
      </span>
      <span>
        {props.name}
      </span>
    </Link>
  );
}

export default function Navbar({ regaNavMode }) {
  const { t, i18n } = useTranslation();
  const { token, logo, vendor, setPlanEndDate, MenuData, setMenuData, ShowMenuPhone, setShowMenuPhone, iconPackage, setIconPackage, templateId } = useAuth();
  const [templateID, setTemplateID] = useState('1');
  const [userInfo, setuserInfo] = useState({});
  const [scrolled, setScrolled] = useState(false);
  const { pathname } = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [wlPlan, setWLPlan] = useState(undefined);

  useEffect(() => {
    if (vendor) {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
        (_userInfo) => {
          if (_userInfo.resCode === 0) {
            setuserInfo(_userInfo.response.wlUser);
            setTemplateID(_userInfo.response.templateId.toString());
            setPlanEndDate(_userInfo.response.wlUser.planEndDate);
            setWLPlan(_userInfo.response.wlUser.planId);
          }
        }
      );
      if (DEMO_VENDORS.includes(vendor) && localStorage.getItem(`menu-${vendor}`)) {
        const menuStorage = JSON.parse(localStorage.getItem(`menu-${vendor}`));
        setMenuData(menuStorage?.menuData?.sort((a, b) => +a.loginRequired - +b.loginRequired));
        setShowMenuPhone(menuStorage?.showPhone === '1');
        setIconPackage(menuStorage?.iconPackage || '1');
          
      } else {
        getMenuItems({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
          (_userInfo) => {
            if (_userInfo.resCode === 0) {
              setShowMenuPhone(_userInfo.response.showPhone === '1');
              setIconPackage(_userInfo.response.iconsPackage || '1');
              // seMenuItems(_userInfo.response.menuItems);
              setMenuData(_userInfo.response.menuItems.sort((a, b) => +a.loginRequired - +b.loginRequired));
            }
          }
        );
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, vendor, i18n.language]);

  if (token)
    onMessageListener()
      .then((payload) => {
        toast.dark(
          payload.notification.title + ": " + payload.notification.body
        );
      })
      .catch((err) => console.log("failed: ", err));

  const handleCloseNavMenu = () => {
    // setOpenDrawer(false);
  };
  

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) setScrolled(true);
    else setScrolled(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <AppBar position="fixed" className={`navbar-area ${[...DARK_TEMPLATES, ...CLASSIC_TEMPLATES].includes(+templateId) && pathname === "/" ? 'navbar-classic' : ''} ${scrolled ? 'scrolled' : ''}`} sx={{ bgcolor: [...DARK_TEMPLATES, ...CLASSIC_TEMPLATES].includes(+templateId) ? scrolled ? 'var(--main-color-two)' : 'transparent' : '#fff', boxShadow: scrolled ? '0px 4px 6px 0px rgba(12, 0, 46, 0.06)' : 'none', direction: 'rtl' }} >
        <Container maxWidth="xl" sx={{ padding: '0 !important' }}>
          <Toolbar disableGutters sx={{ width: '100%' }} className="nav-container">
            {!regaNavMode && <Box sx={{ display: { xs: 'flex', lg: CLASSIC_TEMPLATES.includes(+templateId) ? 'flex' : 'none' } }}>
              <IconButton
                size="large"
                aria-label="collapse menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setOpenDrawer(true)}
                sx={{ color: !window.location.href.includes("properties") && CLASSIC_TEMPLATES.includes(+templateId) && !scrolled ? '#fff' : 'var(--main-color-one)', padding: 0 }}
              >
                <MenuIcon sx={{ width: '1.25em', height: '1.25em' }} />
              </IconButton>
              <ThemeProvider
                theme={(outerTheme) => ({
                  ...outerTheme,
                  direction: "ltr",
                })}>
                <Drawer
                  anchor={"right"}
                  sx={{ zIndex: 999999 }}
                  className="nav-drawer"
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}>
                  <Close
                    onClick={() => setOpenDrawer(false)}
                    sx={{
                      zIndex: 99,
                      top: "26px",
                      position: "absolute",
                      left: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <div className="main-sidebar" >
                    <MenuItem key={'item-0'} onClick={handleCloseNavMenu} sx={{ direction: 'rtl', padding: 0 }}>
                      <LinkTo
                        linkTo="/"
                        activationLinks={["/"]}
                        icon={<WLHome packageId={iconPackage} templateId={templateId} />}
                        component
                        name={t("Home")}
                      />
                    </MenuItem>
                   
                    {MenuData && MenuData.length > 0 &&
                      MenuData.map(
                        (menuElement, idx) =>
                          menuElement.isVisible !== "0" && (
                            menuElement.key === "1" && (templateID === "1" || templateID === "3") ?
                              null
                              :
                              <MenuItem key={idx} onClick={handleCloseNavMenu} sx={{ direction: 'rtl', padding: 0 }}>
                                <LinkTo
                                  activationLinks={[menuElement.linkTo]}
                                  linkTo={menuElement.key === "2" ? menuElement.linkTo + (
                                    ['6', '7', '8', '9'].includes(wlPlan) ? '/3/all/24.716199523004914/46.671776478222675/createdAt/11/1' : ''
                                  ) : menuElement.linkTo}
                                  icon={
                                    menuElement.key === "1" ? <WLProjects packageId={iconPackage} templateId={templateId} /> :
                                      menuElement.key === "2" ? <WLProperties packageId={iconPackage} templateId={templateId} /> :
                                        menuElement.key === "3" ? <WLInfo packageId={iconPackage} templateId={templateId} /> :
                                          menuElement.key === "4" ? <WLCall packageId={iconPackage} templateId={templateId} /> :
                                            menuElement.key === "5" ? <WLAdministration packageId={iconPackage} templateId={templateId} /> :
                                              <WLBlog packageId={iconPackage} templateId={templateId} />}
                                  component
                                  name={menuElement.name}
                                />
                              </MenuItem>
                          )
                      )}
                    {/* <MenuItem key={'lang'} sx={{ padding: 0 }}
                        onClick={() => {
                          if (i18n.language === 'ar') {
                            i18next.changeLanguage('en');
                            i18n.language = 'en';
                            i18n.options.lng = 'en';
                            localStorage.setItem('i18nextLng', 'en');
                            document.documentElement.setAttribute('lang', 'en');
                          } else {
                            i18next.changeLanguage('ar');
                            i18n.language = 'ar';
                            i18n.options.lng = 'ar';
                            localStorage.setItem('i18nextLng', 'ar');
                            document.documentElement.setAttribute('lang', 'ar');
                          }
                          if (token)
                            setLanguageForUser({ language: i18n.language === 'ar' ? '0' : '1', userId: token });
                        }}>
                        <span
                          className="btn menu-item">
                          {!DARK_TEMPLATES.includes(+templateId) && <span className="right">
                            <WLLang packageId={iconPackage} templateId={templateId} />
                          </span>}
                          <span>
                            {i18n.language === "ar" ? 'English' : 'العربيّة'}
                          </span>
                        </span>
                      </MenuItem> */}
                    
                  </div>
              
                </Drawer>
              </ThemeProvider>
          
            </Box>}
            
            <div className="logo readeal-top wl">
              <Link to="/">
                <img src={(localStorage.getItem(`logo-${vendor}`) ? localStorage.getItem(`logo-${vendor}`) : logo)} alt="logo" className="preview-logo-img" />
              </Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
              {ShowMenuPhone !== undefined && ShowMenuPhone && (
                <a href={"tel:" + userInfo.phone} style={{ lineHeight: 0 }}>
                  <Tel />
                </a>
              )}
            </div>
            {!regaNavMode &&
              <Box sx={{ display: { xs: 'none', lg: CLASSIC_TEMPLATES.includes(+templateId) ? 'none' : 'flex' } }}>
                <Button
                  key={'item-0'}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  <LinkTo
                    linkTo="/"
                    activationLinks={["/"]}
                    icon={<WLHome packageId={iconPackage} templateId={templateId} />}
                    component
                    name={t("Home")}
                  />
                </Button>
          
                {MenuData && MenuData?.length > 0 &&
                  MenuData?.map(
                    (menuElement, idx) =>
                      menuElement.isVisible !== "0" && (
                        menuElement.key === "1" && (templateID === "1" || templateID === "3") ?
                          null
                          : <Button
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                            key={idx}>
                            <LinkTo
                              activationLinks={[menuElement.linkTo]}
                              linkTo={menuElement.key === "2" ? menuElement.linkTo + (
                                ['6', '7', '8', '9'].includes(wlPlan) ? '/3/all/24.716199523004914/46.671776478222675/createdAt/11/1' : ''
                              ) : menuElement.linkTo}
                              icon={
                                menuElement.key === "1" ? <WLProjects packageId={iconPackage} templateId={templateId} /> :
                                  menuElement.key === "2" ? <WLProperties packageId={iconPackage} templateId={templateId} /> :
                                    menuElement.key === "3" ? <WLInfo packageId={iconPackage} templateId={templateId} /> :
                                      menuElement.key === "4" ? <WLCall packageId={iconPackage} templateId={templateId} /> :
                                        menuElement.key === "5" ? <WLAdministration packageId={iconPackage} templateId={templateId} /> :
                                          <WLBlog packageId={iconPackage} templateId={templateId} />}
                              component
                              name={menuElement.name}
                            />
                          </Button>
                      )
                  )}
                {/* <Button
                    key={'item-0'}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                    onClick={() => {
                      if (i18n.language === 'ar') {
                        i18next.changeLanguage('en');
                        i18n.language = 'en';
                        i18n.options.lng = 'en';
                        localStorage.setItem('i18nextLng', 'en');
                        document.documentElement.setAttribute('lang', 'en');
                      } else {
                        i18next.changeLanguage('ar');
                        i18n.language = 'ar';
                        i18n.options.lng = 'ar';
                        localStorage.setItem('i18nextLng', 'ar');
                        document.documentElement.setAttribute('lang', 'ar');
                      }
                      if (token)
                        setLanguageForUser({ language: i18n.language === 'ar' ? '0' : '1', userId: token });
                      handleCloseNavMenu();
                    }}>
                    <span
                      className="btn menu-item">
                      {!DARK_TEMPLATES.includes(+templateId) && <span className="right">
                        <WLLang packageId={iconPackage} templateId={templateId} />
                      </span>}
                      <span>
                        {i18n.language === "ar" ? 'English' : 'العربيّة'}
                      </span>
                    </span>
                  </Button> */}
            
              </Box>
            }
            <div className={`nav-right-part nav-right-part-desktop readeal-top ${!window.location.href.includes("properties") && !scrolled && CLASSIC_TEMPLATES.includes(+templateId) ? 'white' : ''}`}>
              {ShowMenuPhone !== undefined && ShowMenuPhone && (<div className="textwidget tel-widget">
                <a href={"tel:" + userInfo.phone}>
                  <span style={{ direction: 'ltr' }}>{userInfo.phone}</span>
                  <Tel />
                </a>
              </div>)}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}