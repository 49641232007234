import React, { useCallback } from 'react'
import { Avatar, Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper'
import { TouchBackend } from 'react-dnd-touch-backend';
import { useTranslation } from 'react-i18next';

const style = {
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    cursor: 'move',
}
  
const SortableCard = ({ id, text, icon, index, moveCard }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'CARD',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveCard(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'CARD',
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div
            ref={ref} style={{
                ...style, opacity,
                background: '#fff',
                border: '1px dashed #ccc',
            }}
            data-handler-id={handlerId}
            className={`col-12 d-flex align-items-center mb-2 p-2 sort-row `}
        >
            <MenuIcon sx={{ width: 25, height: 25, color: 'var(--main-color-one)' }} />
            <Avatar variant='rounded' src={icon} sx={{ width: 96, height: 96, bgcolor: icon ? '#fff' : 'var(--main-color-one)' }} alt={text} />
            <p>{text}</p>
        </div>
    );
}

const SortComponent = ({ openModal, setOpenModal, title, description, onOK, data, setData }) => {
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const { t } = useTranslation();

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setData((prevData) =>
            update(prevData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevData[dragIndex]],
                ],
            }),
        )
    }, // eslint-disable-next-line
        []);

    const renderCard = useCallback((card, index) => {
        return (
            <SortableCard
                key={card.id}
                icon={card.icon}
                index={index}
                id={card.id}
                text={card.title}
                moveCard={moveCard}
            />
        )
    },
        // eslint-disable-next-line
        []);
    
    return (
        <Dialog
            maxWidth={"md"}
            open={openModal}
            className="custom-dialog model"
            onClose={() => setOpenModal(false)}>
            <DialogTitle style={{ width: '100%' }}>
                <div className="dialog-head p-0">
                    <div>
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                    <div className="dialog-actions">
                        <button className="px-3 primary-btn" onClick={() => {
                            onOK?.();
                            setOpenModal(false);
                        }
                        }>
                            {t("Save")}
                        </button>
                        <button className="px-3 outlined-btn" onClick={() => setOpenModal(false)}>
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <DndProvider backend={isLargeScreen ? HTML5Backend : TouchBackend}>
                    <div className='row justify-content-start'>
                        {data?.map((card, i) => renderCard(card, i))}
                    </div>
                </DndProvider>
            </DialogContent>
        </Dialog>
    );
}

export default SortComponent;