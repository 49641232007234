import React, { useEffect, useState } from "react";
import { getRelatedProperty } from "../../api/propertyApi";
import { useAuth } from "../../helpers/context";
import PropertyBox from "./property-box";
import { useTranslation } from "react-i18next";

const RecomandProperties = (props) => {
  const [properties, setProperties] = useState({});
  const { UserId } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getRelatedProperty(getProps(props.id, i18n.language === "ar" ? '0' : '1')).then((_DefLocation) => {
      if (_DefLocation?.resCode === 0) {
        setProperties(_DefLocation?.response?.propertyArray);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, i18n.language]);
  
  function getProps(propertyId, language) {
    if (UserId) {
      let userId = UserId;

      return {
        propertyId,
        language,
        userId,
      };
    } else
      return {
        propertyId,
        language,
      };
  }
  if (properties) {
    return (
      <div className={`recommended-area ${props.isMap ? '' : 'pd-bottom-70'}`}>
        {properties.length > 0 && <h4>{t("SimilarRealEstateADs")}</h4>}
        <div className='row' >
          {properties.length > 0 && (
            properties.map((item, i) => (
              <div
                key={i}
                data-aos={props.isMap ? null : 'flip-left'}
                className='col-xl-3 col-lg-4 col-md-4 col-sm-6'
                style={{ cursor: "pointer" }}>
                <PropertyBox
                  disableLink={props.isMap}
                  onItemSelected={props.isMap ? props.onItemSelected : null}
                  propertey={item} key={i} isHashed={true} />
              </div>
            ))
          )}
        </div>
      </div>
    );
  } else {
    return <div className='loader'>Loading...</div>;
  }
}

export default RecomandProperties;
