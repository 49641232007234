
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getPropertyDescription } from "../../api/propertyApi";
import LoadingData from "../../components/global-components/loading-data";
import AppLayout from "../../components/layouts/app-layout";
import Forbidden from "../shared/403";
import ImageGallery from "react-image-gallery";
import { getFontspace, renderFeatureIcon, renderLocationTitle, renderMeterIfNotExist, renderRoomIcon, resolveRerBorders, resolveUserTypeName } from "../../helpers";
import { CircularCheck, GreenCheck, ListArrow, Marker, RedClose } from "../../constants/icons";
import ImageModal from "../../components/global-components/image-modal";
import { Avatar, Divider, ImageList, ImageListItem } from "@mui/material";
import MapContainer from "../../components/googleMapComponent";
import { useAuth } from "../../helpers/context";
import QRCode from "react-qr-code";
import CampaignIcon from '@mui/icons-material/Campaign';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import SignpostIcon from '@mui/icons-material/Signpost';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import ApprovalIcon from '@mui/icons-material/Approval';
import Filter3Icon from '@mui/icons-material/Filter3';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import DescriptionIcon from '@mui/icons-material/Description';
import RoomIcon from '@mui/icons-material/Room';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const Comparison = () => {
  const location = useLocation();
  const [loadingData, setLoadingData] = useState(true);
  const { token, UserId } = useAuth();
  const [property1, setProperty1] = useState(undefined);
  const [property2, setProperty2] = useState(undefined);
  const [property3, setProperty3] = useState(undefined);
  const [images1, setImages1] = useState([]);
  const [images2, setImages2] = useState([]);
  const [images3, setImages3] = useState([]);
  const [plans1, setPlans1] = useState([]);
  const [plans2, setPlans2] = useState([]);
  const [plans3, setPlans3] = useState([]);
  const [expandedTheDesc1, setExpandedTheDesc1] = useState(false);
  const [expandedTheDesc2, setExpandedTheDesc2] = useState(false);
  const [expandedTheDesc3, setExpandedTheDesc3] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(undefined);
  const [imageModalOpened, setImageModalOpened] = useState(false);
  const { t, i18n } = useTranslation();
  // const [exploreNeighbourhood, setExploreNeighbourhood] = useState({
  //   hospital: [false,false,false],
  //   mosque: [false,false,false],
  //   school: [false,false,false],
  //   shopping_mall: [false,false,false],
  //   bus_station: [false,false,false],
  //   train_station: [false,false,false],
  // });
 
  useEffect(() => {
    if (location?.state?.property2) {
      getPropertyDescription({
        propertyId: location?.state?.property1,
        language: i18n.language === 'ar' ? '0' : '1',
        userId: UserId,
      }).then(res => {
        if (res.resCode === 0) {
          setProperty1(res.response);
          let temp1 = [];
          if (res.response.photos) {
            res.response.photos.split(",").map((element) =>
              temp1.push({
                original: element,
                thumbnail: element,
              }));
          } else {
            temp1.push(
              {
                original: publicUrl + "assets/img/defimgs/" + res.response.propertyType + ".svg",
                thumbnail: publicUrl + "assets/img/defimgs/" + res.response.propertyType + ".svg",
              }
            );
          }
          setImages1(temp1);
          if (res.response.floorPlans) setPlans1(res.response.floorPlans.split(","));

          getPropertyDescription({
            propertyId: location?.state?.property2,
            language: i18n.language === 'ar' ? '0' : '1',
            userId: UserId,
          }).then(res => {
            if (res.resCode === 0) {
              setProperty2(res.response);
              let temp2 = [];
              if (res.response.photos) {
                res.response.photos.split(",").map((element) =>
                  temp2.push({
                    original: element,
                    thumbnail: element,
                  }));
              } else {
                temp2.push({
                  original: publicUrl + "assets/img/defimgs/" + res.response.propertyType + ".svg",
                  thumbnail: publicUrl + "assets/img/defimgs/" + res.response.propertyType + ".svg",

                });
              }
              setImages2(temp2);
              if (res.response.floorPlans) setPlans2(res.response.floorPlans.split(","));

              if (location?.state?.property3) {
                getPropertyDescription({
                  propertyId: location?.state?.property3,
                  language: i18n.language === 'ar' ? '0' : '1',
                  userId: UserId,
                }).then(res => {
                  if (res.resCode === 0) {
                    setProperty3(res.response);
                    let temp3 = [];
                    if (res.response.photos) {
                      res.response.photos.split(",").map((element) =>
                        temp3.push({
                          original: element,
                          thumbnail: element,
                        }));
                    } else {
                      temp3.push(
                        {
                          original: publicUrl + "assets/img/defimgs/" + res.response.propertyType + ".svg",
                          thumbnail: publicUrl + "assets/img/defimgs/" + res.response.propertyType + ".svg",
                        });
                    }
                    setImages3(temp3);
                    if (res.response.floorPlans) setPlans3(res.response.floorPlans.split(","));

                    setLoadingData(false);
                  }
                });
              } else {
                setLoadingData(false);
              }
            }
          });
        }
      });
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, // eslint-disable-next-line
    [i18n.language]);
  
  useEffect(() => {
    if (!loadingData)
      setTimeout(() => {
        if (document.querySelector('#scroll-left'))
          document.querySelector('#scroll-left').style.display = 'none';
      }, 4000);
  }, // eslint-disable-next-line
    [loadingData]);
    
  const renderPropertyGallery = (images) => {
    return (
      <div className="w-460">
        <div className='col-12 property-details-slider p-0'>
          <ImageGallery items={images} showPlayButton={images.length > 1} showThumbnails={images.length > 1} />
        </div>
      </div>
    );
  }

  const renderPropertyHeader = (property) => {
    return (
      <div className="w-460 property-header">
        <h3>{`${property.propertyTypeName} ${property.listedFor === "2" ? t("ForSale") : t("ForRent")}`}</h3>
        {property?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
          property?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' && (
            <h4>
              <span>
                {Intl.NumberFormat("en").format(
                  property.defaultPrice
                )}{" "}
                {t('SAR')}
              </span>
              {property.listedFor === "1" && (
                <span style={{ fontSize: 18 }}>
                  &nbsp;({property.defaultPriceType.name})
                </span>
              )}
            </h4>
          )}
       
        <p className="d-flex align-items-end mt-3" style={{ gap: 8 }}>
          <RoomIcon sx={{ color: 'var(--main-color-one)' }} />
          <span>{property.address}</span>
        </p>
        {property.additionalInfo
          .filter((i) => i.key === '10')?.[0] && property.additionalInfo
            .filter((i) => i.key === '10')?.[0]?.valueText !== 'لا' && (
            <p className="d-flex align-items-end mt-3" style={{ gap: 8 }}>
              <DescriptionIcon sx={{ color: 'var(--main-color-one)' }} />
              <span>
                {property.additionalInfo
                  .filter((i) => i.key === '10')?.[0]?.name}
                :&nbsp;
                {property.additionalInfo
                  .filter((i) => i.key === '10')?.[0]?.valueText}
              </span>
            </p>)}
        {property.additionalInfo.filter((i) => i.key === '34')?.[0]?.valueText !== "لا" && (
          <p className="d-flex align-items-end mt-3" style={{ gap: 8 }}>
            <CampaignIcon sx={{ color: 'var(--main-color-one)' }} />
            <span>
              {property.additionalInfo.filter((i) => i.key === '34')?.[0]?.name + ': ' + property.additionalInfo.filter((i) => i.key === '34')?.[0]?.valueText}
            </span>
          </p>
        )}
        {property.additionalInfo.filter((i) => i.key === '18')?.[0]?.valueText !== "لا" && (
          <div className="d-flex flex-column justify-content-center mt-3" style={{ width: 'fit-content' }}>
            <div style={{
              height: "auto", maxWidth: 75, width: "100%",
              border: '4px solid #e9e8ea',
              borderRadius: '12px',
              padding: '8px 5px 0'
            }}>
              {!property.additionalInfo.filter((i) => i.key === '18')?.[0]?.valueText?.includes("amakkn") ?
                <a href={property.additionalInfo.filter((i) => i.key === '18')?.[0]?.valueText} target="_blank" rel="noreferrer">
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%"
                    }}
                    value={property.additionalInfo.filter((i) => i.key === '18')?.[0]?.valueText}
                    viewBox={`0 0 256 256`}
                  />
                </a>
                :
                <img src={property.additionalInfo.filter((i) => i.key === '18')?.[0]?.valueText}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%"
                  }}
                  alt={t("QRCode")} />
              }
            </div>
            <span className={`d-inline-block badge mt-2 ${['0', '3'].includes(property.status) ? 'warning' : 'success'}`}>
              {['0', '2', '3'].includes(property.status) ? t("InactiveAD") : t("ActiveAD")}
            </span>
          </div>
        )}
      </div>
    );
  }

  const renderPropertyDescription = (property, number) => {
    return (
      <div className="w-460">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("PropertyDescription")}
        </Divider>
        {property.description ? (
          <p
            className='text-more'
            style={{ whiteSpace: "pre-line" }}>
                        
            {(number === 1 && expandedTheDesc1) || (number === 2 && expandedTheDesc2) || (number === 3 && expandedTheDesc3)
              ? property.description
              : property.description.length > 120
                ? property.description.substr(0, 120) + "... "
                : property.description
            }
            {property.description.length > 120 && (
              <button
                onClick={() => {
                  if (number === 1) setExpandedTheDesc1(!expandedTheDesc1);
                  else if (number === 2) setExpandedTheDesc2(!expandedTheDesc2);
                  else if (number === 3) setExpandedTheDesc3(!expandedTheDesc3);
                }}>
                {(number === 1 && expandedTheDesc1) || (number === 2 && expandedTheDesc2) || (number === 3 && expandedTheDesc3) ? t("ShowLess") : t("ShowMore")}
              </button>
            )}
          </p>
        ) : <p>{t("DescriptionNotAvailable")}</p>}
      </div>
    );
  }

  const renderPropertyDetails = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("PropertyDetails")}
        </Divider>
        <div className='property-info'>
          <div className='row p-0 m-0'>
            {property.rooms.map((item) => (
              <div
                className='col-6 box'
                key={item.key}>
                <div className='icon'>
                  {renderRoomIcon(item.key)}
                </div>
                <div className='info'>
                  <h5>{item.name}</h5>
                  <span>{item.key === "11" ? (item.value === "0" ? t("GroundFloor") : item.value) : item.value}</span>
                </div>
              </div>
            ))}
            {property.features.map((item) => (
              <div
                className='col-6 box'
                key={item.key}>
                <div className='icon'>
                  {renderFeatureIcon(item.key)}
                </div>
                <div className='info'>
                  <h5>{item.name}</h5>
                  <span>
                    <i className='fa' />
                    {item.key === "7"
                      ? getFontspace(item.value)
                      : Intl.NumberFormat("en").format(item.value) + " " + item.unit}
                  </span>
                </div>
              </div>
            ))}
            <div className='col-6 box'>
              <div className='icon'><BubbleChartIcon /></div>
              <div className='info'>
                <h5>{property?.additionalInfo?.filter(i => i.key === '22')?.[0]?.name}</h5>
                <span>
                  {property?.additionalInfo?.filter(i => i.key === '22')?.[0]?.valueText}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
    
  const renderPropertyServices = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("RealEstateServices")}
        </Divider>
        <div className='row p-0  property-amenities'>
          {property && property.additionalInfo.filter((i) => ["5", "6", "7", '50', '51', '52', '15'].includes(i.key) && i.valueBoolean === "1")?.length > 0 ? (
            <div className='col-12 additional-info-v2'>
              <div className='row p-0 m-0'>
                {property.additionalInfo
                  .filter((i) => ["5", "6", "7", '50', '51', '52'].includes(i.key) && i.valueBoolean === "1")
                  .map((AIItem) => (
                    <div
                      className={`col-6 mb-4 info`}
                      key={AIItem.key}>
                      <span>
                        {AIItem.valueBoolean === "1" ? (
                          <GreenCheck green />
                        ) : (
                          <RedClose />
                        )}
                      </span>
                      <span>{AIItem.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          ) : <p>{t("NoServices")}</p>}
        </div>
      </div>
    );
  }

  const renderPropertyFeatures = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("Features")}
        </Divider>
        <div className='row p-0  property-amenities'>
          {property && property.amenities.length > 0 ? property.amenities.map((amItem) => (
            <div
              className='col-6 property-amenity'
              key={amItem.key}>
              <span className='icon'>
                <CircularCheck />
              </span>{" "}
              {amItem.name}
            </div>
          )) : <p>{t("NoFeatures")}</p>}
        </div>
      </div>
    );
  }
    
  const renderOwnerInfo = (property) => {
    return (<div className="w-460">
      
      {!property?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText ||
        property?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText === 'لا' ?
        <></> :
        <>
          <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
            {t("AdvertiserInfo")}
          </Divider>
          <div className=' mobile widget-owner-info'>
            <Link
              to={{
                pathname:
                  property.hostInfo.accountType === "1"
                    ? "/user-profile/" +
                    property.hostInfo.idToShare
                    : "/company-profile/" +
                    property.hostInfo.idToShare,
              }}
              state={{ userId: property.hostInfo.id }}
            >
              <div className='owner-info'>
                <div className='thumb'>
                  <Avatar
                    sx={{ width: 75, height: 75, bgcolor: '#fff' }}
                    src={
                      property.hostInfo.avatar
                        ? property.hostInfo.avatar
                        : publicUrl +
                        "assets/img/defimgs/" +
                        property.hostInfo.accountType +
                        "_account.svg"
                    }
                    alt='image1'
                  />
                </div>
                <div className='details'>
                  <h5>
                    {property.hostInfo.name}
                    {property.hostInfo.isUserVerified >= 2 && (
                      <img
                        src={
                          publicUrl +
                          "assets/img/icons/verfiy.svg"
                        }
                        alt='image2'
                      />
                    )}
                  </h5>
                  <span className='designation'>
                    {resolveUserTypeName(property.hostInfo.userType)}
                  </span>
                  {property.hostInfo?.advertiserNumber && (
                    <span className='designation d-block'>
                      {property.additionalInfo
                        .filter((i) => i.key === '21')?.[0]?.name}
                      :&nbsp;
                      {property.hostInfo?.advertiserNumber}
                    </span>
                  )}
                </div>
              </div>
            </Link>
          </div>
        </>
      }
     
    </div>);
  }
  
  const renderPropertyAdditionalInfo = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("ObligationsOnTheProperty")}
        </Divider>
        <div className='row'>
          {property.additionalInfo?.length > 0 ?
            property.additionalInfo
              .filter((i) => ['1', '11', '13', '14', '19', '2', '20', '3', '30', '31', '32', '35', '37', '54', '55', '16', '17', '53'].includes(i.key))
              .map((AIItem) => (
                <div
                  className='col-12 mb-4 info'
                  key={AIItem.key}>
                  <div className='question'>
                    <h5> <ListArrow style={i18n.language ==="en"? {transform:'rotate(180deg)'}:{}}/> &nbsp;{AIItem.name}</h5>
                    <p style={{ paddingRight: '25px', whiteSpace: "pre-line" }}>
                      {AIItem.valueText === "0" ? t("No") : AIItem.valueText === "1" ? t("Yes") : AIItem.valueText}
                    </p>
                  </div>
                </div>
              )) : (
              <p>{t("AdditionalInformationIsNotAvailable")}</p>
            )}
        </div>
      </div>
    );
  }
              
  const renderPropertyBorders = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.name}
        </Divider>
        <div className='row'>
          {property.additionalInfo?.length > 0 &&
            property.additionalInfo.filter((i) => ["38"].includes(i.key))?.length > 0 && property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText !== "0#0#0#0#0#0#0#0#0#0#0#0" ? (
            <div className='col-12 additional-info'>
              <div className='property-info'>
                <div className='row p-0 m-0'>
                  <div className='col-12 box'>
                    <div className='icon blue'>
                      <NavigationIcon />
                    </div>
                    <div className='info'>
                      <span>
                        <b>{t("North1")}: </b>
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[0] === "0" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[0] + " "}
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[1] === "North" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[1] + " "}
                        <b>{t("WithLength")} </b>
                        {renderMeterIfNotExist(property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[2])}
                      </span>
                    </div>
                  </div>
                  <div className='col-12 box'>
                    <div className='icon blue'>
                      <NavigationIcon sx={{ transform: 'rotate(90deg)' }} />
                    </div>
                    <div className='info'>
                      <span>
                        <b>{t("East1")}: </b>
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[3] === "0" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[3] + " "}
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[4] === "East" ? "" : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[4] + " "}
                        <b>{t("WithLength")} </b>
                        {renderMeterIfNotExist(property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[5])}
                      </span>
                    </div>
                  </div>
                  <div className='col-12 box'>
                    <div className='icon blue'>
                      <NavigationIcon sx={{ transform: 'rotate(180deg)' }} />
                    </div>
                    <div className='info'>
                      <span>
                        <b>{t("South1")}: </b>
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[9] === "0" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[9] + " "}
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[10] === "South" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[10] + " "}
                        <b>{t("WithLength")} </b>
                        {renderMeterIfNotExist(property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[11])}
                      </span>
                    </div>
                  </div>
                  <div className='col-12 box'>
                    <div className='icon blue'>
                      <NavigationIcon sx={{ transform: 'rotate(-90deg)' }} />
                    </div>
                    <div className='info'>
                      <span>
                        <b>{t("West1")}: </b>
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[6] === "0" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[6] + " "}
                        {property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[7] === "West" ? '' : property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[7] + " "}
                        <b>{t("WithLength")} </b>
                        {renderMeterIfNotExist(property.additionalInfo.filter((i) => ["38"].includes(i.key))?.[0]?.valueText?.split('#')?.[8])}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>{t("NotAvailable")}</p>
          )}
        </div>
      </div>
    );
  }
     
  const renderPropertyRerBorders = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {property.additionalInfo.filter((i) => ["56"].includes(i.key))?.[0]?.name}
        </Divider>
        <div className='row'>
          {property.additionalInfo?.length > 0 &&
            property.additionalInfo.filter((i) => ["56"].includes(i.key))?.length > 0 && property.additionalInfo.filter((i) => ["56"].includes(i.key))?.[0]?.valueText !== 'لا' ? (
            <div className='col-12 additional-info mb-4'>
              <div className='property-info'>
                <div className='row p-0 m-0'>
                  {property.additionalInfo.filter((i) => ["56"].includes(i.key))?.[0]?.valueText?.split('-')?.map((i, index) => resolveRerBorders(i, index))}
                </div>
              </div>
            </div>
          ) : (
            <p>{t("NotAvailable")}</p>
          )}
        </div>
      </div>
    );
  }
  
  const renderPropertyRentOptions = (property) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("PriceDetails")}
        </Divider>
        <div className='row property-rent-options p-0 m-0'>
          {property?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
            property?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' ? (
            <>
              {property.priceRent?.length > 0 ?
                property.priceRent.map((item, i) => (
                  <div key={i} className='col-12'>
                    <p>
                      <span>
                        {Intl.NumberFormat("en").format(
                          item.value
                        )} {t("SAR")}
                      </span>
                      &nbsp;<span>{item.name}</span>
                    </p>
                  </div>
                )) : (
                  <div className='col-12'>
                    <p>
                      <span>
                        {Intl.NumberFormat("en").format(
                          property.defaultPrice
                        )} {t("SAR")}
                      </span>
                      &nbsp;<span>{t("ForSale")}</span>
                    </p>
                  </div>
                )}
            </>
          ) : (
            <div className='col-12'>
              <p>{t("WaitingForTheADLicenseNumber")}</p>
            </div>)}
        </div>
      </div>
    );
  }

  const renderPropertyPlans = (plans) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("Plans")}
        </Divider>
        {plans.length > 0 ? (
          <div className='col-12'>
            <div className='attachments-wrapper'></div>
            <ImageList cols={4} gap={15}>
              {plans.map((item, i) => (
                <ImageListItem
                  key={i}
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    cursor: "pointer",
                    height: "160px",
                  }}
                  onClick={() => {
                    if (item?.includes(".pdf")) {
                      window.open(item, "_blank");
                    } else {
                      setSelectedAttachment(item);
                      setImageModalOpened(true);
                    }
                  }}>
                  {item?.includes(".pdf") ? (
                    <img
                      src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                      alt={`attachment-${i}`}
                      loading='lazy'
                      style={{
                        objectFit: "contain",
                        height: "100%",
                        padding: 10,
                      }}
                    />
                  ) : (
                    <img
                      src={`${item}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={`attachment-${i}`}
                      loading='lazy'
                      style={{
                        objectFit: "cover",
                        height: "100%",
                      }}
                    />
                  )}
                </ImageListItem>
              ))}
            </ImageList>
            <ImageModal
              src={selectedAttachment}
              open={imageModalOpened}
              handleClose={() => setImageModalOpened(false)}
            />
          </div>
        ) : <p>{t("NotAvailable")}</p>
        }
      </div>
    );
  }

  const renderPropertyLocation = (property, number) => {
    return (
      <div className="w-460 property-inner-details">
        <Divider textAlign="left" className="col-11 mt-4 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
          {t("PropertyAddress")}
        </Divider>
        {property?.additionalInfo?.filter((i) => i.key === '36')?.[0] &&
          property?.additionalInfo?.filter((i) => i.key === '36')?.[0]?.valueText !== 'لا' && (
            <p className="d-flex align-items-end mt-2 mb-3" style={{ gap: 8 }}>
              <FmdBadIcon sx={{ color: 'var(--main-color-one)' }} />
              <span>
                {property.additionalInfo.filter((i) => i.key === '36')?.[0]?.name}
                :&nbsp;
                {property.additionalInfo.filter((i) => i.key === '36')?.[0]?.valueText}
              </span>
            </p>)}
        <div className='col-12 p-0'>
          <div
            className='row'
            style={{
              height: "400px",
              margin: "0px",
              position: "relative"
            }}
          >
            <div className="map-container">
              {property && property?.latitude && (
                <MapContainer
                  lat={property?.latitude}
                  lng={property?.longitude}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-12 row mx-0 mt-4 property-info">
          {property.fullAddress.split(',').map((item, index) => (
            <div
              className='col-6 box'
              key={index}>
              <div className='icon'>
                {index === 0 ? <MapIcon /> : index === 1 ? <LocationCityIcon /> :
                  index === 2 ? <EmojiTransportationIcon /> :
                    index === 3 ? <Marker sx={{ color: 'var(--main-color-one)' }} /> :
                      index === 4 ? <ApprovalIcon /> :
                        index === 6 ? <Filter3Icon /> : <LocalConvenienceStoreIcon />}
              </div>
              <div className='info'>
                <h5>{renderLocationTitle(index)}</h5>
                <span>{item?.trim() || t("NotAvailable")}</span>
              </div>
            </div>
          ))}
                             
          {property.additionalInfo
            .filter((i) => ['12', '33'].includes(i.key))
            .map((AIItem) => (
              <div
                className='col-6 box'
                key={AIItem.key}>
                <div className='icon'><SignpostIcon /></div>
                <div className='info'>
                  <h5>{AIItem.name}</h5>
                  <span>{AIItem.valueText === "0" ? t("No") : AIItem.valueText === "1" ? t("Yes") : AIItem.valueText}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <AppLayout needAuth pageTitle={t("Comparison")}>
      {token && (
        <>
          {location?.state ? (
            <div className='property-details-area page-width pd-top-90 mg-bottom-100 comparison-area'>
              {loadingData ?
                <div className="pt-5">
                  <LoadingData />
                </div>
                :
                <div className="property-details-slider">
                  <div className='w-1400'>
                    {renderPropertyGallery(images1)}
                    {renderPropertyGallery(images2)}
                    {property3 && renderPropertyGallery(images3)}
                  </div>
                  <div className='w-1400 mt-3'>
                    {renderPropertyHeader(property1)}
                    {renderPropertyHeader(property2)}
                    {property3 && renderPropertyHeader(property3)}
                  </div>
                  <div className='w-1400'>
                    {renderPropertyDescription(property1, 1)}
                    {renderPropertyDescription(property2, 2)}
                    {property3 && renderPropertyDescription(property3, 3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto '>
                    {renderPropertyDetails(property1)}
                    {renderPropertyDetails(property2)}
                    {property3 && renderPropertyDetails(property3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto'>
                    {renderPropertyBorders(property1)}
                    {renderPropertyBorders(property2)}
                    {property3 && renderPropertyBorders(property3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto'>
                    {renderPropertyRerBorders(property1)}
                    {renderPropertyRerBorders(property2)}
                    {property3 && renderPropertyRerBorders(property3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto '>
                    {renderPropertyFeatures(property1)}
                    {renderPropertyFeatures(property2)}
                    {property3 && renderPropertyFeatures(property3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto '>
                    {renderPropertyServices(property1)}
                    {renderPropertyServices(property2)}
                    {property3 && renderPropertyServices(property3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto'>
                    {renderPropertyRentOptions(property1)}
                    {renderPropertyRentOptions(property2)}
                    {property3 && renderPropertyRentOptions(property3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto'>
                    {renderPropertyPlans(plans1)}
                    {renderPropertyPlans(plans2)}
                    {property3 && renderPropertyPlans(plans3)}
                  </div>
                  <div className='w-1400 property-details-slider-info m-auto'>
                    {renderPropertyLocation(property1, 1)}
                    {renderPropertyLocation(property2, 2)}
                    {property3 && renderPropertyLocation(property3, 3)}
                  </div>
                 
                  <div className='w-1400 property-details-slider-info m-auto'>
                    {renderPropertyAdditionalInfo(property1)}
                    {renderPropertyAdditionalInfo(property2)}
                    {property3 && renderPropertyAdditionalInfo(property3)}
                  </div>
                 
                  
                  <div className='w-1400 mt-3'>
                    {renderOwnerInfo(property1)}
                    {renderOwnerInfo(property2)}
                    {property3 && renderOwnerInfo(property3)}
                  </div>
                </div>
              }
              {!loadingData && (
                <div id="scroll-left">
                  <span className="arrow-left"></span>
                  <span id="scroll-title">{t("SwipeLeft")}</span>
                </div>
              )}
            </div>
          ) :
            <Forbidden btnLink="/comparison-list" btnText={t("ComparisonList")} desc={t("PleaseSelectTheRealEstateADsYouWantToCompareFirst")} />
          }
        </>
      )}
    </AppLayout>
  );
}

export default Comparison;